import React from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";

interface dataType {
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
}

const SubscriptionOrdersFilter: React.FC<dataType> = ({
  setSelectedStatus,
}) => {
  const { orders } = useSelector((state: any) => state);
  const { Option } = Select;

  return (
    <div className="px-2 py-1.5 rounded-md">
      <div className="flex items-center space-x-2">
        <Select
          size="large"
          showSearch
          style={{ width: 200, fontSize: "5px" }}
          allowClear
          placeholder="Filter By Status"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toString().includes(input)
          }
          loading={orders.isFetching}
          disabled={orders.isFetching}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toString()
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toString().toLowerCase())
          }
          onChange={(value: any) => {
            setSelectedStatus(value);
          }}
        >
          {["draft", "created"].map((status: any) => (
            <Option
              value={status}
              label={status}
              key={status}
              className="capitalize"
            >
              <span className="capitalize"> {status}</span>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default SubscriptionOrdersFilter;
