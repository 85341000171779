import { Paper, Stack, Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { searchFuction } from "../../utils/converter";
import {
  getAllChannelAction,

} from "../../store/channel/actions";

const ProductStoreFilter = (props: any) => {
  const { product, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
 
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [shopFilter, setShopFilter] = useState([]);


  const { Option } = Select;

  // Get unique status values with proper typing
  const uniqueStatuses: string[] = Array.from(
    new Set(
      product?.productStore?.map((item: { status: string }) => item?.status || '')
    )
  ).filter((status): status is string => Boolean(status));

  const resetFilter = () => {
    if (selectedFilters) {
      props?.setSelectedChannel("");
      props?.setselectedStatus("");
    }
  };



 

  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>
          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
             <Select
                  showSearch
                  onSearch={(value: any) =>
                    searchFuction(
                      value,
                      product?.productStore,
                      (option: any, val) =>
                        option?.status
                          ?.toLowerCase()
                          .includes(val.toLowerCase()),
                      setShopFilter
                    )
                  }
                  style={{ width: 200, height: 40 }}
                  allowClear
                  placeholder="Filter By Status"
                  optionFilterProp="children"
                  filterOption={false}
                  loading={channel.isFetching}
                  disabled={channel.isFetching}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && props?.setselectedStatus("");
                    value && props?.setselectedStatus(value);
                  }}
                >
                  {uniqueStatuses.map((status, index) => (
                    <Option key={index} value={status} className="capitalize">
                      {status}
                    </Option>
                  ))}
                </Select>
            </Stack>
          )}
          <h1 className="text-gray-400">
            Result{product?.productStore?.length > 0 && "s"}:{" "}
            {product.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              product?.productStore?.length
            )}
          </h1>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ProductStoreFilter;
