import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DetailsPage from "./DetailPage";
import {
  getOneCustomerAction,
} from "../../../store/customer/actions";
import CustomModal from "../../../components/Modals/CustomModal";
import App from "../../../components/forms/NewCustomer";

const CustomerDetails = () => {
  const { customerId } = useParams();
  const { auth, customer } = useSelector((state: any) => state);
  const [editVisible, setEditVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClickEdit = () => {
    setEditVisible(true);
  };
  const handleClickCancel = () => {
    setEditVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (customerId) {
        console.log("fetching....");
        auth?.token &&
          (await getOneCustomerAction(auth?.token, customerId)(dispatch));
      }
    };
    fetchOnProduct();
  }, [customerId, auth, dispatch]);

  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide">
      <DetailsPage
        pageItemId={customerId}
        pageName={"Customer"}
        title={customer?.selected?.name}
        simpleDetails={customer && customer.selected}
        handleClickEdit={handleClickEdit}
      />
      <CustomModal
        openModal={editVisible}
        cancelModal={handleClickCancel}
        component={
          <div className="py-5">
            <App dataToUpdate={customer?.selected} onCancel={() => handleClickCancel()} action="update" customerId={customer.selected?._id} />
          </div>
        }
        width={800}
      />
    </div>
  );
};
export default CustomerDetails;
