import React from "react";
import { Input } from "antd";

const SearchInput = (props: any) => {
  return (
    <Input.Search
      placeholder={`Search ${props?.placeholder || ""}`}
      onSearch={props?.onSearch}
      onChange={props?.onChange}
      enterButton
      size="large"
      className={`w-100 md:w-[450px]`}
    />
  );
};

export default SearchInput;
