import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";

export const DeliveryDropdownRoutes = [
  {
    caption: "Overview",
    index: "DeliveryOverview",
    path: "/overview/delivery",
  },
  {
    caption: "Orders",
    index: "Orders",
    path: "/deliveryOrder",
  },

  {
    caption: "Track",
    index: "Track",
    path: "/track",
    // skip: ["finance-manager"],
  },
];
export const pagesArray = [
  {
    caption: "Overview",
    path: "/overview/delivery",
  },
  {
    caption: "Delivery",
    path: "/deliveryOrder",
  },
  {
    caption: "Delivery",
    path: "#",
  },
  {
    caption: "DeliveryNote",
    path: "#",
  },
];

const DeliveryRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");

  const allowedRoles = ["admin", "finance-manager", "dev", "b2b-relation"];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/subscription",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Accounts",
      path: "/subscription/account",
      onClick: () => {
        console.log("sale:00");
      },
    },
    {
      caption: "Orders",
      path: "/subscription/order",
      onClick: () => {
        console.log("sale:00");
      },
    },
    {
      caption: "Pick Lists",
      path: "/subscription/PickList",
      onClick: () => {
        console.log("sale:00");
      },
    },
    {
      caption: "Delivery Notes",
      path: "/subscription/DeliveryNote",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];

  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) =>
                item.title.toLowerCase() === "Subscription".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={9}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default DeliveryRoutes;
