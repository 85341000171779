import React from "react";
import { Box, Stack } from "@mui/material";
import { Badge, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Badge.Ribbon
        text={
          <span
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight: 400,
              paddingTop: "1.5px",
            }}
          >
            {props.data?.status}
          </span>
        }
        color={searchValue(props.data?.status)}
        style={{ width: "5rem", padding: "3px 1px" }}
      >
        <div className="bg-white w-[360px] h-[165px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] text-[#030229] p-3 px-3  cursor-pointer">
          <div onClick={() => navigate(`/customers/${props.data._id}`)}>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
                <Stack direction={"row"} spacing={2}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "4rem",
                      marginRight: "4px",
                    }}
                  >
                    <ProfileAvatal picture={props?.data?.subscriptionInfo?.photoCustomerHoldingID ?? props?.data?.picture} />
                  </div>
                </Stack>
                <Stack spacing={0}>
                  <h1 className="font-normal text-14px">{props.data?.name?.toUpperCase()}</h1>
                  <p className="text-[13px]" style={{ color: "#605BFF" }}>
                    {(props.data?.nationalId ?? props.data?.nid) || 'N/A'}
                  </p>
                </Stack>
              </Stack>
              <Divider />
              <Box sx={{ width: "100%" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  marginLeft={"15px"}
                  alignItems="flex-end"
                  sx={{ width: "100%" }}
                >
                  <Stack spacing={.5}>
                    <div className="text-[13px] flex gap-2">
                      <p className="opacity-50">Email: </p>
                      {props.data?.email || 'N/A'}
                    </div>
                    <div className="text-[13px] flex gap-2">
                      <p className="opacity-50">Phone: </p> {props.data?.phone || 'N/A'}
                    </div>
                    <div className="text-[13px] flex gap-2">
                      <p className="opacity-50"> Address: </p>
                      {(props?.data?.subscriptionInfo?.address?.district ?? props.data?.address) || 'N/A'}
                    </div>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </div>
        </div>
      </Badge.Ribbon>
    </>
  );
};
export default Component;
