import React, { useState } from "react";
import { Button, Space } from "antd";
import { IoFilterOutline } from "react-icons/io5";
import { accountFilter } from "../../assets/data/types";

const GeneralCustomFilter: React.FC<accountFilter> = ({
  handleResetFields,
  component,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(false);

  return (
    <div className="lg:pl-4 pr-4 pt-2 lg:pb-2 text-[#030229] opacity-90">
      <div className="flex md:items-center lg:space-x-2">
        <Button
          className="font-medium"
          style={{
            color: selectedFilters ? "#605BFF" : "",
            borderColor: selectedFilters ? "#605BFF" : "",
            height: 38,
          }}
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            handleResetFields();
          }}
        >
          <Space>
            <IoFilterOutline size={20} /> <p>Filter</p>
          </Space>
        </Button>
        {selectedFilters && <div>{component}</div>}
      </div>
    </div>
  );
};
export default GeneralCustomFilter;
