import React, { useEffect } from "react";
import { Tag, Table, Divider, Space, Badge, Spin } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubdeliverynotesItemAction,
  getOnesubDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import UploadComponent from "../../../../components/forms/UploadFile";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import {
  HiOutlineUser,
  HiOutlineDevicePhoneMobile,
  HiOutlineEnvelope,
} from "react-icons/hi2";
import { TfiLocationPin } from "react-icons/tfi";
import { IoBusinessOutline } from "react-icons/io5";
import { searchValue } from "../../../../utils/setColor";

const PrintComponent = (props: any) => {
  const { deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const { auth, deliverynote, deliverynoteItem } = useSelector(
    (state: any) => state
  );
  const delivererDetails = deliverynote?.singleDeliveryNote?.deliverer;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  useEffect(() => {
    if (deliveryNoteId && auth?.token) {
      getOnesubDeliveryNoteAction(auth?.token, deliveryNoteId)(dispatch);
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${deliveryNoteId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, deliveryNoteId, dispatch, limit, page]);

  const subdeliverynote = deliverynote.all.data;
  const filterSubdeliveryNote: any = subdeliverynote.filter(
    (item: any) => item._id === deliveryNoteId
  );
  const dataToDisplay = deliverynote?.subDeliveryNoteItem?.data?.map(
    (el: any) => {
      return {
        type: el?.wareHouseItem?.quotationItem?.product?.type,
        model: el?.wareHouseItem?.quotationItem?.product?.model,
        brand: el?.wareHouseItem?.quotationItem?.product?.brand,
        imei: el?.wareHouseItem?.serialNumber,
        specs: el?.wareHouseItem?.quotationItem?.specification,
      };
    }
  );

  const columns = [
    {
      key: "brand",
      title: "Brand",
      dataIndex: "brand",
    },
    {
      key: "model",
      title: "Model",
      dataIndex: "model",
    },
    {
      key: "imei",
      title: "IMEI",
      dataIndex: "imei",
    },
    {
      key: "specs",
      title: "Specs",
      dataIndex: "specs",
      width: 200,
      render: (_text: any, record: any) => (
        <p>
          {record?.specs &&
            record?.specs
              ?.slice(2, 6)
              ?.map((item: any) => item[1] && `${item[0]}:${item[1]}`)
              ?.join(" ")}
        </p>
      ),
    },
  ];
  const addressData =
    deliverynote?.singleDeliveryNote?.account?.customerDetails?.address &&
    deliverynote?.singleDeliveryNote?.account?.customerDetails?.address?.split(
      ", "
    );

  return (
    <Badge.Ribbon
      text={
        <span
          style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontWeight: 400,
            paddingTop: "1.5px",
          }}
        >
          {deliverynote?.isFetching ? (
            <Spin size="small" />
          ) : (
            filterSubdeliveryNote[0]?.status
          )}
        </span>
      }
      color={
        deliverynote?.isFetching
          ? "#F5F6FA"
          : searchValue(filterSubdeliveryNote[0]?.status)
      }
      style={{
        width: "10rem",
        padding: "5px 1px",
      }}
    >
      <>
        <div className="text-[#030229] font-normal flex flex-wrap justify-between lg:p-8">
          <div className="pt-4">
            <h1 className="text-[16px] font-medium  mb-1">CUSTOMER DETAILS</h1>
            {deliverynote?.singleDeliveryNote?.account && (
              <div className="text-[#0F0F47] flex flex-col gap-1">
                <Space>
                  <HiOutlineUser className="opacity-60" size={16} />
                  <span className="capitalize  text-sm">
                    {deliverynote?.singleDeliveryNote?.account?.customerDetails?.name?.toUpperCase()}
                  </span>
                </Space>
                <Space>
                  <HiOutlineDevicePhoneMobile
                    className="opacity-60"
                    size={16}
                  />
                  <span className="capitalize text-sm">
                    {
                      deliverynote?.singleDeliveryNote?.account?.customerDetails
                        ?.tel
                    }
                  </span>
                </Space>
                <Space>
                  <HiOutlineEnvelope className="opacity-60" size={16} />
                  <span className="capitalize text-sm">
                    {
                      deliverynote?.singleDeliveryNote?.account?.customerDetails
                        ?.email
                    }
                  </span>
                </Space>
                <Space className="flex">
                  <TfiLocationPin className="opacity-60" size={16} />
                  <span className="capitalize pl-1">
                    {addressData?.map((line: any, index: number) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />} {line?.trim()}{" "}
                      </React.Fragment>
                    ))}
                  </span>
                </Space>
              </div>
            )}
          </div>
        </div>
        <div className="lg:px-6 pt-6 pb-5">
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={deliverynote?.subDeliveryNoteItem?.total}
            count={Math.ceil(deliverynote?.subDeliveryNoteItem?.total / limit)}
          >
            <div className="w-full overflow-x-auto">
              <Table
                columns={columns}
                dataSource={dataToDisplay}
                loading={
                  deliverynote?.deliveryIsFetching ||
                  deliverynoteItem?.deliveryIsFetching
                }
                pagination={false}
              />
            </div>
          </ScrollableFrame>
        </div>
        <h1 className="ml-4 pb-4 font-medium text-base opacity-80">
          DELIVERY NOTE DOCUMENTS
        </h1>
        {!deliverynote?.isFetching && (
          <>
            {deliverynote?.singleDeliveryNote?.status !== "delivered" ? (
              <div className="pb-10 ml-4">
                <UploadComponent
                  setPictures={props?.setPictures}
                  limit={10}
                  isCard="picture-card"
                />
              </div>
            ) : (
              <div className="pb-10 ml-4 space-y-2">
                {deliverynote?.singleDeliveryNote?.documents?.map(
                  (d: any, key: any) => {
                    return (
                      <div>
                        <a href={d} target="_blank" rel="noreferrer">
                          <p className="text-blue-400 hover:underline text-sm">
                            {`Documents ${key + 1}`}
                          </p>
                        </a>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </>
        )}
        <Divider />
        <div className="flex text-[#030229] flex-col lg:flex-row justify-between w-full lg:px-4 text-[16px]">
          <Space>
            <p className=" opacity-70"> Approved by : </p>
            <p>{deliverynote?.singleDeliveryNote?.createdBy?.names}</p>
          </Space>
          <div>
            Date:{" "}
            {moment(deliverynote?.singleDeliveryNote?.createdAt).format(
              "MMMM Do YYYY"
            )}
          </div>
        </div>
        <div className="pt-4 lg:ml-4">
          <h1 className="text-base font-medium mb-2">DELIVERER DETAILS</h1>
          {delivererDetails && (
            <div className="text-[#0F0F47] flex flex-col gap-1">
              <Space>
                <HiOutlineUser className="opacity-60" size={16} />
                <span className="capitalize text-sm">
                  {delivererDetails?.name}
                </span>
              </Space>
              <Space>
                <HiOutlineDevicePhoneMobile className="opacity-60" size={16} />
                <span className="capitalize text-sm">
                  {delivererDetails?.phone}
                </span>
              </Space>
              <Space>
                <HiOutlineEnvelope className="opacity-60" size={16} />
                <span className="capitalize text-sm">
                  {delivererDetails?.email}
                </span>
              </Space>
              <Space>
                <IoBusinessOutline className="opacity-60" size={16} />
                <span className="capitalize text-sm">
                  {delivererDetails?.company}
                </span>
              </Space>
            </div>
          )}
        </div>
      </>
    </Badge.Ribbon>
  );
};
export default PrintComponent;
