import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CustomerProfile from "./component/CustomerProfile";
import NodataPlacehoder from "../../../../assets/images/opps...png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubsorderItemAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import { handleNewCustomer } from "../../../../store/layout/actions";
import FormSkeleton from "../../../../components/skeleton/FormSkeleton";
import AddItem from "./AddItems";
import { Popconfirm, Steps, Table } from "antd";
import { IconButton, useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AssigneeUser from "./component/AssigneeUser";
import { getAllUsersAction } from "../../../../store/setting/actions";
import { getTotalPeriodFromStart } from "../../../../utils/converter";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const CreateNewDeal = () => {
  const { auth, orders, layout, company } = useSelector((state: any) => state);
  const { dealId } = useParams();
  const dispatch = useDispatch();

  const smallScreen = useMediaQuery("(max-width:1600px)");

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  const [channelId, setChannelId] = useState("");

  useEffect(() => {
    if (dealId && auth?.token) {
      getOneSubsOrderAction(auth?.token, dealId)(dispatch);
    }
  }, [auth.token, dispatch, dealId]);

  useEffect(() => {
    if (auth?.token) {
      getAllUsersAction(auth?.token, `?page=0&limit=100`)(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (orders?.selected?.account?.customerDetails) {
      handleNewCustomer(true)(dispatch);
    }
  }, [dispatch, orders?.selected?.account?.customerDetails]);

  const columns = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text: any, record: any) => (
        <p className="flex flex-col">
          <span>{text}</span>
          <span className="text-xs">{`(${record?.specification})`}</span>
        </p>
      ),
    },
    {
      title: "Plan Details",
      dataIndex: "allowence",
      key: "allowence",
      width: 200,
    },

    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: `Upfront (${getDefaultCurrencyCode(company)})`,
      dataIndex: "initialPayment",
      key: "initialPayment",
      render: (text: any, record: any) => <p>{text?.toLocaleString()}</p>,
    },
    {
      title: (
        <label className="capitalize">{`Price (${getDefaultCurrencyCode(
          company
        )}) / ${orders?.selected?.list?.at(0)?.planInfo?.planType} `}</label>
      ),
      dataIndex: "price",
      key: "price",
      render: (text: any, record: any) => <p>{text?.toLocaleString()}</p>,
    },
    {
      title: "",
      width: 30,
      render: (text: any, record: any) => {
        return (
          <Popconfirm
            placement="topRight"
            title={`Do you really want to remove`}
            description={`${record?.model} from the list?`}
            okText="Yes"
            cancelText="No"
            onConfirm={async () =>
              await deleteSubsorderItemAction(
                auth.token,
                record.key,
                orderId
              )(dispatch)
            }
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon style={{ color: "red" }} fontSize="inherit" />
            </IconButton>
          </Popconfirm>
        );
      },
    },
  ];

  const formData = orders?.selected?.list?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.product?.product?.model,
      price: el?.amount,
      allowence: el?.planInfo?.allowance,
      upfront: el?.planInfo?.initialPayment * el?.quantity,
      initialPayment: el?.planInfo?.initialPayment,
      duration: `${orders?.selected?.account?.duration ?? "N/A"} Months`,
      intallmentType: orders?.selected?.account?.type,
      intallmentAmount: el?.product?.subscriptionPlan
        ?.filter(
          (plan: any) => plan.duration === orders?.selected?.account?.duration
        )
        ?.map((el: any) => el?.monthlyInstalment),

      totalAmount: el?.amount * el?.quantity,
      specification: `${el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      quantity: el?.quantity,
      amount: el?.quantity * el?.amount,
      data: el,
    };
  });

  const totalUpfront = formData?.reduce(
    (curr: any, item: any) => curr + item?.upfront,
    0
  );

  const subTotal = formData?.reduce(
    (curr: any, item: any) => curr + item?.totalAmount,
    0
  );

  const duration = getTotalPeriodFromStart(
    orders?.selected?.list?.at(0)?.planInfo?.planType,
    orders?.selected?.account?.duration,
    orders?.selected?.account?.billingType
  );

  const totalAmount =
    orders?.selected?.account?.billingType === "net"
      ? subTotal + totalUpfront
      : subTotal * duration + totalUpfront;

  const steps = [
    {
      content: orders?.newOrderIsFetching ? (
        <FormSkeleton />
      ) : (
        <CustomerProfile />
      ),
    },
    {
      content: (
        <AddItem
          orderId={orderId}
          channelId={channelId}
          setChannelId={setChannelId}
        />
      ),
    },
  ];

  return (
    <>
      <div className=" text-[#0F0F47] mt-10 xl:mt-6 flex items-center justify-between pl-4">
        <DetailsHeaderActionBar
          pageName="Subscription"
          title="Deals / Create New Deal"
        />
      </div>
      <div className="h-[calc(100vh-150px)] w-full">
        <div className={`${smallScreen ? "flex-col" : "flex-row"} flex`}>
          <div
            className={`max-h-full bg-transparent p-3 rounded-lg ${
              smallScreen ? "w-full" : "w-1/2"
            }`}
          >
            <div className="flex items-center justify-between bg-white p-2 rounded-tr-lg rounded-tl-lg">
              <h1 className="font-semibold text-blue-400 text-lg">
                {`${
                  layout?.subscriptionDealSteps === 0
                    ? "Customer Profile"
                    : "Customer Quotation Order"
                }`}
              </h1>
              <p className="text-gray-400">
                {`${layout?.subscriptionDealSteps + 1} of 2`}{" "}
                <span className="text-gray-800"> Steps</span>
              </p>
            </div>

            <div className="h-[calc(100vh-200px)] overflow-y-auto bg-white p-2 mt-1 rounded-br-lg rounded-bl-lg">
              <Steps current={layout?.subscriptionDealSteps} />
              <div className="h-full">
                {steps[layout?.subscriptionDealSteps].content}
              </div>
            </div>
          </div>
          <div className="bg-white flex-1 h-[calc(100vh-150px)] overflow-y-auto mt-3 rounded-lg relative">
            <span className="absolute left-3 top-4 text-gray-600 font-poppins font-bold text-sm">
              Order Items
            </span>
            {formData?.length > 0 ? (
              <div className="pt-16 px-4 text-gray-900 h-full">
                <>
                  <div className="w-full px-1 overflow-x-auto">
                    <Table
                      columns={columns}
                      dataSource={formData}
                      loading={orders?.newOrderIsFetching}
                      pagination={false}
                    />
                  </div>
                  <div className="flex justify-end mt-6">
                    {!orders?.newOrderIsFetching && (
                      <div className=" bg-white border border-gray-200 rounded-lg shadow px-8 py-4 w-full md:w-1/2">
                        <div className="flex justify-between py-2 text-gray-700">
                          <span className="capitalize">
                            {`Subtotal ${
                              orders?.selected?.account?.billingType !== "net"
                                ? `(per ${
                                    orders?.selected?.list?.at(0)?.planInfo
                                      ?.planType
                                  })`
                                : ""
                            }`}
                          </span>
                          <span>{`${getDefaultCurrencyCode(
                            company
                          )} ${subTotal?.toLocaleString()}`}</span>
                        </div>

                        <div className="flex justify-between py-2 text-gray-700">
                          <span>Subtotal (Upfront)</span>
                          <span>{`${getDefaultCurrencyCode(
                            company
                          )} ${totalUpfront?.toLocaleString()}`}</span>
                        </div>
                        <div className="flex justify-between py-2 text-gray-700">
                          <span>Duration</span>
                          <span className="capitalize">{`${duration} ${
                            orders?.selected?.list?.at(0)?.planInfo?.planType
                          }s`}</span>
                        </div>
                        <div className="flex justify-between py-2 text-gray-700">
                          <span>Discount (0%)</span>
                          <span>{`${getDefaultCurrencyCode(
                            company
                          )} 0.00`}</span>
                        </div>
                        <div className="flex justify-between py-2 text-gray-700">
                          <span>Taxes</span>
                          <span>{`${getDefaultCurrencyCode(
                            company
                          )} 0.00`}</span>
                        </div>
                        <div className="flex justify-between py-2 text-lg text-gray-900 font-semibold">
                          <span className="capitalize">{`Total Amount (${duration} ${
                            orders?.selected?.list?.at(0)?.planInfo?.planType
                          }s)`}</span>
                          <span>{`${getDefaultCurrencyCode(
                            company
                          )} ${totalAmount?.toLocaleString()}`}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <AssigneeUser />
                </>
              </div>
            ) : (
              <div className="flex items-center justify-center text-gray-900 h-full">
                <div className="flex flex-col">
                  <img src={NodataPlacehoder} alt="noData" />
                  <span className="capitalize text-center font-bold text-[#655cf2] text-2xl">
                    No item added yet
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewDeal;
