import React from "react";
import { XAxis, Tooltip, ResponsiveContainer } from "recharts";
import "./styles.css";
import { LineChart, Line, YAxis, CartesianGrid } from "recharts";
import { useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import moment from "moment";
const OverviewChart = (props: any) => {
  const { company } = useSelector((state: any) => state);

  const data = props?.data?.map((el: any) => {
    if (props?.periodValue === "year") {
      return {
        name: el?.month,
        year1: el?.year1Amount,
        year2: el?.year2Amount,
      };
    }
    if (props?.periodValue === "weekly") {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
    if (props?.periodValue === "month") {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
    if (props?.periodValue === "daily") {
      return {
        hour: el?.hour,
        Yesterday: el?.day1Amount,
        Today: el?.day2Amount,
      };
    } else {
      return {
        date: moment(el?.date).format("MMM-DD"),
        Amount: el?.totalAmount,
      };
    }
  });
  const yearSum = props?.data?.reduce(
    (acc: any, item: any) => {
      acc.year1Sum += item?.year1Amount;
      acc.year2Sum += item?.year2Amount;
      return acc;
    },
    { year1Sum: 0, year2Sum: 0 }
  );
  const monthSum = props?.data?.reduce(
    (acc: any, item: any) => {
      acc.monthSum += item?.totalAmount;
      return acc;
    },
    { monthSum: 0 }
  );
  const weekSum = props?.data?.reduce(
    (acc: any, item: any) => {
      acc.weekSum += item?.totalAmount;
      return acc;
    },
    { weekSum: 0 }
  );
  const daySum = props?.data?.reduce(
    (acc: any, item: any) => {
      acc.day1Sum += item?.day1Amount;
      acc.day2Sum += item?.day2Amount;
      return acc;
    },
    { day1Sum: 0, day2Sum: 0 }
  );
  return (
    <div className="bg-white w-full h-full py-3 pr-8 rounded-md text-[#030229]">
      <div className="flex justify-between pl-8">
        <div className="flex flex-col gap-1">
          <h3>Total Revenue</h3>
          <div className="ml-1">
            <h1>
              {getDefaultCurrencyCode(company)}{" "}
              {props?.periodValue === "year"
                ? yearSum?.year1Sum?.toLocaleString()
                : props?.periodValue === "month"
                ? monthSum?.monthSum?.toLocaleString()
                : props?.periodValue === "weekly"
                ? weekSum?.weekSum?.toLocaleString()
                : props?.periodValue === "daily"
                ? daySum?.day2Sum?.toLocaleString()
                : monthSum?.monthSum?.toLocaleString()}
            </h1>
            <div className="text-[#030229]  opacity-60 font-normal text-[14px]">
              {props?.periodValue === "daily" && (
                <p>
                  {getDefaultCurrencyCode(company)}{" "}
                  {daySum?.day1Sum?.toLocaleString() ?? 0}
                </p>
              )}
              {props?.periodValue === "year" && (
                <p>
                  {getDefaultCurrencyCode(company)}{" "}
                  {yearSum?.year2Sum?.toLocaleString() ?? 0}
                </p>
              )}
            </div>
          </div>
        </div>
        {(props?.periodValue === "year" || props?.periodValue === "daily") && (
          <div className="flex gap-5">
            <div className="flex gap-2">
              <div className="w-4 h-4 rounded-sm bg-[#605BFF] mt-1"></div>{" "}
              <p className="text-[#030229]">
                {props?.periodValue === "year"
                  ? "This year"
                  : props?.periodValue === "daily"
                  ? "Today"
                  : ""}
              </p>
            </div>
            <div className="flex gap-2">
              <div className="w-4 h-4 rounded-sm bg-[#BDBDBD] mt-1"></div>{" "}
              <p className="text-[#030229]">
                {props?.periodValue === "year"
                  ? "Last year"
                  : props?.periodValue === "daily"
                  ? "Yesterday"
                  : ""}
              </p>
            </div>
          </div>
        )}

        <div></div>
      </div>
      <ResponsiveContainer width="100%" height={400} className="mt-6">
        <LineChart data={data}>
          <defs>
            <filter id="shadow" x="-50%" y="-50%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="6"
                floodColor="#605BFF"
                floodOpacity="0.6"
              />
            </filter>
          </defs>
          <defs>
            <filter id="shadow2" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="6"
                floodColor="#BDBDBD"
                floodOpacity="0.6"
              />
            </filter>
          </defs>
          <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
          <XAxis
            fontSize={12}
            strokeWidth={0.1}
            dataKey={
              props?.periodValue === "year"
                ? "name"
                : props?.periodValue === "month"
                ? "date"
                : props?.periodValue === "weekly"
                ? "date"
                : props?.periodValue === "daily"
                ? "hour"
                : props?.periodValue === "range"
                ? "date"
                : ""
            }
          />
          <YAxis fontSize={12} strokeWidth={0.1} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={
              props?.periodValue === "year"
                ? "year1"
                : props?.periodValue === "month"
                ? "Amount"
                : props?.periodValue === "weekly"
                ? "Amount"
                : props?.periodValue === "daily"
                ? "Today"
                : props?.periodValue === "range"
                ? "Amount"
                : ""
            }
            stroke="#605BFF"
            strokeWidth={1}
            dot={false}
            filter="url(#shadow)"
          />

          <Line
            type="monotone"
            dataKey={
              props?.periodValue === "year"
                ? "year2"
                : props?.periodValue === "daily"
                ? "Yesterday"
                : ""
            }
            stroke="#BDBDBD"
            strokeWidth={1}
            dot={false}
            filter="url(#shadow2)"
          />
        </LineChart>
      </ResponsiveContainer>
      {props?.periodValue === "daily" && (
        <p className="text-center opacity-80 font-normal text-[13px] mb-3">
          Hours
        </p>
      )}
    </div>
  );
};
export default OverviewChart;
