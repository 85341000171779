import Profile from "../../../../assets/images/profile.jpeg";
import { ReactComponent as Increase } from "../../../../icons/increaseIcon.svg";
import { ReactComponent as Earnings } from "../../../../icons/earning.svg";
import { ReactComponent as Tquantity } from "../../../../icons/quantity.svg";
import { ReactComponent as Tcustomer } from "../../../../icons/customers.svg";
import AgentChart from "./AgentChart";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import moment from "moment";
import AgentDateRange from "./AgentDateRange";
import { calculatePercentageChange } from "../../../../utils/converter";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getOneUserAction } from "../../../../store/setting/actions";
import { useParams } from "react-router-dom";
import { getAllAgentRevenueAction } from "../../../../store/kpi/actions";
import GraphSkeleton from "../../../../components/skeleton/GraphSkeleton";
import CustomSkeleton from "../../../../components/skeleton/CustomSkeleton";
import ProfileSkeleton from "../../../../components/skeleton/ProfileSkeleton";

const CustomerOverView = () => {
  const { auth, agentKPI, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = React.useState<any>(formattedDate);
  const [compareDate, setCompareDate] = React.useState<any>(
    formattedPreviousDate
  );

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const todayRevenue = agentKPI?.all?.data?.at(0)?.currentDateRevenue;
  const comparedRevenue = agentKPI?.all?.data?.at(0)?.comparedDateRevenue;

  const todayQuantity = agentKPI?.all?.data?.at(0)?.currentDateQuantity;
  const comparedQuantity = agentKPI?.all?.data?.at(0)?.comparedDateQuantity;

  const todayCustomer = agentKPI?.all?.data?.at(0)?.currentDateCustomer;
  const comparedCustomer = agentKPI?.all?.data?.at(0)?.comparedDateCustomer;

  const revenuePercentage = calculatePercentageChange(
    todayRevenue,
    comparedRevenue
  );

  const quantityPercentage = calculatePercentageChange(
    todayQuantity,
    comparedQuantity
  );

  const customerPercentage = calculatePercentageChange(
    todayCustomer,
    comparedCustomer
  );

  const cardData = [
    {
      title: "Earnings",
      amount: `Rwf ${todayRevenue ?? 0}`,
      yesterdayAmount: `Rwf ${comparedRevenue ?? 0}`,
      icon: <Earnings />,
      percent: (
        <div
          className={`flex px-1 ${
            revenuePercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : revenuePercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {revenuePercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : revenuePercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              revenuePercentage?.type === "increase"
                ? "text-[#0FA958]"
                : revenuePercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${revenuePercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Quantity",
      amount: `${todayQuantity ?? 0} Devices`,
      yesterdayAmount: `${comparedQuantity ?? 0} Devices`,
      icon: <Tquantity />,
      percent: (
        <div
          className={`flex px-1 ${
            quantityPercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : quantityPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {quantityPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : quantityPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              quantityPercentage?.type === "increase"
                ? "text-[#0FA958]"
                : quantityPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${quantityPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Customers",
      amount: `${todayCustomer ?? 0} People`,
      yesterdayAmount: `${comparedCustomer ?? 0} People`,
      icon: <Tcustomer />,
      percent: (
        <div
          className={`flex px-1 ${
            customerPercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : customerPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {customerPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : customerPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              customerPercentage?.type === "increase"
                ? "text-[#0FA958]"
                : customerPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${customerPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
  ];
  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (auth?.token) {
      getOneUserAction(id as string, auth?.token)(dispatch);
    }
  }, [id, auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&agentId=${id}`
      )(dispatch);
    }
  }, [auth?.token, compareDate, dispatch, enteredDate, id]);

  return (
    <div className="w-full xmin-h-screen text-[#030229] font-poppins my-6">
      <div className="flex flex-col md:flex-row justify-between w-[100%]">
        <div className="pl-1 md:pl-10 w-[100%] ">
          <DetailsHeaderActionBar
            pageName="Agent"
            title={setting?.selected?.names}
          />
        </div>
        <div className="pl-1  pt-2 flex flex-col md:flex-row justfy-between md:justify-end items-center w-[100%]">
          <div className="flex space-x-2 items-center">
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dayjs()}
              style={{ width: 120 }}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </div>
          <div>
            <p>Compared To</p>
          </div>
          <div className="flex space-x-2 items-center">
            <DatePicker
              onChange={onChangePreviousDate}
              defaultValue={dayjs().subtract(1, "day")}
              style={{ width: 120 }}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pl-0 md:pl-12 mt-6 gap-6 h-[auto]">
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] 2xl:h-96  rounded-[12px] mr-2 p-6  w-[100%] md:w-[47%] ">
          <h1 className="font-medium text-[20px] opacity-90 pl-4">
            Personal Details
          </h1>
          {setting?.isFetching ? (
            <ProfileSkeleton />
          ) : (
            <div className="h-full">
              <img
                className="w-[100px] h-[60px] rounded-full mr-14"
                src={setting?.selected?.picture}
                alt="Profile pic"
              />
              <div className="flex flex-col gap-4">
                <span>
                  <p className="opacity-50 text-[16px] font-medium">Name</p>
                  <p className="opacity-80 text-[18px] font-medium capitalize">
                    {setting?.selected?.names}
                  </p>
                </span>
                <span>
                  <p className="opacity-50 text-[16px] font-medium">Email</p>
                  <p className="opacity-80 text-[18px] font-medium">
                    {setting?.selected?.email}
                  </p>
                </span>
                <span>
                  <p className="opacity-50 text-[16px] font-medium">Phone</p>
                  <p className="opacity-80 text-[18px] font-medium">
                    {setting?.selected?.phone}
                  </p>
                </span>
                <span>
                  <p className="opacity-50 text-[16px] font-medium">Role</p>
                  <p className="opacity-80 text-[18px] font-medium capitalize">
                    {setting?.selected?.role?.split("-")?.join(" ")}
                  </p>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] 2xl:h-96 font-medium rounded-[12px] py-6 pl-5 w-full">
          <h1 className="font-medium text-[18px] opacity-90 mb-8">
            Agent Sales KPI
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {cardData?.map((data: any, index: number) => (
              <div
                key={index}
                className="py-2 rounded-[8px] w-full h-[8rem] shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)]"
              >
                {/* <div className="items-end">{data?.percent}</div> */}
                <div className="flex gap-6 mt-5 px-4">
                  <div className="mt-2"></div> {data?.icon}
                  <span className="flex flex-col gap-[6px]">
                    <p className="font-normal text-[16px] opacity-90">
                      {data?.title}
                    </p>
                    {agentKPI?.isAllFetching ? (
                      <CustomSkeleton />
                    ) : (
                      <>
                        <p className="font-semibold text-[18px] opacity-90">
                          {data?.amount?.toLocaleString()}
                        </p>
                        <p className="font-medium text-[18px] opacity-50">
                          {data?.yesterdayAmount?.toLocaleString()}
                        </p>
                      </>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 pl-0 md:pl-10 mt-5">
        <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-[100%] md:w-[65%] mr-0 md:mr-2 bg-white rounded-[12px]  px-0 md:px-10 py-1 h-[full]">
          <h1 className="font-medium text-[18px] opacity-90 my-4 ml-4">
            Earnings Analytic
          </h1>
          {agentKPI?.isFetching ? <GraphSkeleton /> : <AgentChart />}
        </div>
        <div className="hidden md:block shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-[30rem] md:w-[30%] bg-white rounded-[12px] h-[full]">
          <AgentDateRange />
        </div>
      </div>
    </div>
  );
};

export default CustomerOverView;
