import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOneSubsOrderAction,
  submitSubOrdersAction,
} from "../../../../store/subscription/order/actions";
import { Button } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getTotalPeriodFromStart } from "../../../../utils/converter";
import "./table.css";
import FormSkeleton from "../../../../components/skeleton/FormSkeleton";
import ForceLogo from "../../../../assets/icons/dashboard/Samphone_Logo.svg";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const SubscriptionDetails = (props: any) => {
  const { auth, orders, company } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const customerData = orders?.selected?.account?.customerDetails;

  const handleRefresh = () => {
    if (orderId && auth?.token) {
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const formData = orders?.selected?.list?.flatMap((el: any) => {
    return {
      type: el?.product?.product?.type,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      qnty: el?.quantity,
      allowence: el?.planInfo?.allowance,
      specification: `${el?.product?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.amount,
      upfront: el?.planInfo?.initialPayment * el?.quantity,
      initialPayment: el?.planInfo?.initialPayment,
      totalAmount: el?.amount * el?.quantity,
      data: el,
      duration: `${orders?.selected?.account?.duration ?? "N/A"} Months`,
      intallmentType: orders?.selected?.account?.type,
      intallmentAmount: el?.product?.subscriptionPlan
        ?.filter(
          (plan: any) => plan.duration === orders?.selected?.account?.duration
        )
        ?.map((el: any) => el?.monthlyInstalment),
    };
  });

  const totalUpfront = formData?.reduce(
    (curr: any, item: any) => curr + item?.upfront,
    0
  );

  const subTotal = formData?.reduce(
    (curr: any, item: any) => curr + item?.totalAmount,
    0
  );

  const handleRequestApproval = async () => {
    if (!auth?.token) return;
    const isFinanceRoute = props?.route === "sales";
    const status = isFinanceRoute ? "pick-list" : "finance-review";
    const navigatePath = isFinanceRoute
      ? "/sales/quotation"
      : "/subscription/order";

    const res = await submitSubOrdersAction(
      orderId as string,
      { status },
      auth.token
    )(dispatch);

    if (res) {
      navigate(navigatePath);
    }
  };

  const duration = getTotalPeriodFromStart(
    orders?.selected?.list?.at(0)?.planInfo?.planType,
    orders?.selected?.account?.duration,
    orders?.selected?.account?.billingType
  );

  const totalAmount =
    orders?.selected?.account?.billingType === "net"
      ? subTotal + totalUpfront
      : subTotal * duration + totalUpfront;

  return (
    <div>
      <div className="flex items-center justify-between gap-x-5 mt-10 xl:mt-6 text-gray-900">
        <DetailsHeaderActionBar
          pageName={props?.route}
          title="Quotation"
          isLoading={orders?.newOrderIsFetching}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="max-w-6xl mx-auto mt-10 p-8 bg-white rounded-md text-gray-900">
        <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
          <div className="text-3xl font-semibold font-poppins">
            {" "}
            <img
              src={ForceLogo}
              className="cursor-pointer duration-500 w-40 mt-2"
              alt=""
            />
          </div>
          <div className="text-2xl font-medium font-poppins">QUOTATION</div>
        </div>
        {orders?.newOrderIsFetching ? (
          <FormSkeleton />
        ) : (
          <>
            <div className="grid md:grid-cols-2 gap-x-40 mt-8">
              <div></div>
              <div className="grid grid-cols-2">
                <p className="text-sm">Quotation Date: </p>{" "}
                <span className="text-right">
                  {orders?.selected?.createdAt?.slice(0, 10)}
                </span>
                <p className="text-sm">Quotation #: </p>
                <span className="text-right">
                  {orders?.selected?.account?.accountID}
                </span>
                <p className="text-sm">Customer ID: </p>
                <span className="text-right">
                  {orders?.selected?.account?.customer?.customerId || "N/A"}
                </span>
                <p className="text-sm">Assigned To: </p>
                <span className="text-right">
                  {orders?.selected?.account?.assignees?.at(0)?.names}
                </span>
                <p className="text-sm">Billing Type: </p>
                <span className="text-right">
                  {orders?.selected?.account?.billingType}
                </span>
                <p className="text-sm">Installment Type: </p>
                <span className="text-right capitalize">
                  {`${orders?.selected?.list?.at(0)?.planInfo?.planType}ly`}
                </span>
                <p className="text-sm">Installment Duration: </p>
                <span className="text-right capitalize">
                  {`${duration} ${
                    orders?.selected?.list?.at(0)?.planInfo?.planType
                  }s`}
                </span>
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-40 mt-8">
              <div>
                <div className="font-bold text-sm rounded bg-[#2f2f3d] text-white p-2">
                  Vendor
                </div>
                <div className="py-4">
                  <p className="font-semibold">
                    {company.selected?.name || ""}
                  </p>
                  {company.selected?.address?.country ? (
                    <p>{`${company.selected?.address?.country} / ${company.selected?.address?.city}`}</p>
                  ) : (
                    ""
                  )}
                  <p>Tel: {company.selected?.phone}</p>
                  <p>Email: {company.selected?.email}</p>
                </div>
              </div>

              <div>
                <div className="font-bold text-sm rounded bg-[#2f2f3d] text-white p-2">
                  Customer
                </div>
                <div className="py-4">
                  <p className="font-semibold">{customerData?.name}</p>
                  <p>{customerData?.address}</p>
                  <p>Tel: {customerData?.tel}</p>
                  <p>Email: {customerData?.email}</p>
                </div>
              </div>
            </div>

            <div className="mt-8 w-full overflow-x-auto">
              <table className="min-w-full xbg-red-300">
                <thead>
                  <tr className="bg-[#2f2f3d]">
                    <th className="py-2 px-4 text-white text-left">Model</th>
                    <th className="py-2 px-4 text-white text-left">
                      Plan Details
                    </th>
                    <th className="py-2 px-4 text-white text-left">Quantity</th>
                    <th className="py-2 px-4 text-white text-left">
                      {`Upfront (${getDefaultCurrencyCode(company)})`}
                    </th>
                    <th className="py-2 px-4 text-white text-left capitalize">
                      {`Price (${getDefaultCurrencyCode(company)}) / ${
                        orders?.selected?.list?.at(0)?.planInfo?.planType
                      }`}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.map((el: any, index: number) => (
                    <tr key={index}>
                      <td className="border px-4 py-2 flex flex-col">
                        <span>{el?.model}</span>
                        <span className="text-xs">
                          {`(${el?.specification})`}
                        </span>
                      </td>
                      <td className="border px-4 py-2">{el?.allowence}</td>
                      <td className="border px-4 py-2 text-center">
                        {el?.qnty}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        {el?.initialPayment?.toLocaleString()}
                      </td>
                      <td className="border px-4 py-2 text-right">
                        {el?.amount?.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-8 text-right">
              <div className="flex justify-end">
                <div className="md:w-1/2">
                  <div className="flex justify-between py-2">
                    <span className="capitalize">
                      {`Subtotal ${
                        orders?.selected?.account?.billingType !== "net"
                          ? `(per ${
                              orders?.selected?.list?.at(0)?.planInfo?.planType
                            })`
                          : ""
                      }`}
                    </span>
                    <span>{`(${getDefaultCurrencyCode(
                      company
                    )}) ${subTotal?.toLocaleString()}`}</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span>Subtotal (Upfront)</span>
                    <span>{`(${getDefaultCurrencyCode(
                      company
                    )}) ${totalUpfront?.toLocaleString()}`}</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span>Duration</span>
                    <span className="capitalize">{`${duration} ${
                      orders?.selected?.list?.at(0)?.planInfo?.planType
                    }s`}</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span>Discount (0%)</span>
                    <span>{`(${getDefaultCurrencyCode(company)}) 0.00`}</span>
                  </div>
                  <div className="flex justify-between py-2">
                    <span>Taxes</span>
                    <span>{`(${getDefaultCurrencyCode(company)}) 0.00`}</span>
                  </div>
                  <div className="flex justify-between py-2 text-lg font-bold">
                    <span className="capitalize">{`Total Amount (${duration} ${
                      orders?.selected?.list?.at(0)?.planInfo?.planType
                    }s)`}</span>
                    <span>{`(${getDefaultCurrencyCode(
                      company
                    )}) ${totalAmount?.toLocaleString()}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex items-end justify-end gap-x-5 mt-4 max-w-6xl mx-auto">
        <Button type="primary" className="px-6 py-5" disabled>
          Print
        </Button>
        {["created", "finance-review"]?.includes(orders?.selected?.status) && (
          <Button
            className={`${
              orders?.createOderIsFetching ? "bg-gray-600" : "bg-green-600"
            } text-white px-6 py-5`}
            onClick={handleRequestApproval}
            loading={orders?.createOderIsFetching}
            disabled={
              (props?.route !== "sales" &&
                orders?.selected?.status === "finance-review") ||
              orders?.newOrderIsFetching
            }
          >
            {props?.route === "sales" ? "Approve" : "Request Approval"}
          </Button>
        )}
      </div>
    </div>
  );
};
export default SubscriptionDetails;
