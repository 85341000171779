import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import CustomerCard from "../../../components/cards/customerCard";
import ContactInfoCard from "../../../components/cards/ContactInfoCard";
import CreditReportCard from "../../../components/cards/CreditReportCar";
import { Card, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomerDevices from "./Devices/CustomerDevices";
import {
  getCustomerDevicesAction,
  getCustomerMetricsAction,
  getCustomerNoSaleAction,
  getCustomerPurchaseAction,
} from "../../../store/customer/actions";
import {
  getAccountStatisticsAction,
  getAllAccountAction,
  getAllNotesAction,
} from "../../../store/account/actions";
import { getOneCompanyAction } from "../../../store/setting/company/actions";
import File from "./files/File";
import CustomerRepairTable from "../../../components/tables/CustomerRepairTable";
import NotesCard from "../subscription/Accounts/components/NotesCard";
import CustomerNoSaleTable from "../../../components/tables/CustomerNoSaleTable";
import { COMPANY_PROFILE_ID } from "../../../utils/constants";
import CashSaleTable from "./CashSaleTable";
import CustomerSubscription from "./SubscriptionSale";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { useParams } from "react-router-dom";

const DetailsPage = (props: any) => {
  const { auth, customer, account, company, layout } = useSelector(
    (state: any) => state
  );
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = account?.all?.data?.at(0)?._id;
  const { customaId } = useParams();
  const selectedCustomerId = customer?.selected?._id;
  const customerId = customaId || selectedCustomerId;

  const goBack = () => {
    navigate(-1);
  };
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };

  useEffect(() => {
    auth?.token &&
      getAllNotesAction(auth?.token, `?modelId=${accountId}`)(dispatch);
  }, [accountId, auth?.token, dispatch]);

  useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerNoSaleAction(
        auth?.token,
        `?customerId=${customerId}`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  useEffect(() => {
    auth?.token &&
      customerId &&
      getAllAccountAction(auth?.token, `?customer=${customerId}`)(dispatch);
    getAccountStatisticsAction(
      auth?.token,
      `?accountId=${accountId}`
    )(dispatch);
  }, [accountId, auth?.token, customerId, dispatch]);

  useEffect(() => {
    if (customerId && auth?.token) {
      getCustomerDevicesAction(
        auth?.token,
        `?customerId=${customerId}`
      )(dispatch);
    }
  }, [auth?.token, customerId, dispatch]);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    auth?.token &&
      getOneCompanyAction(companyId as string, auth?.token)(dispatch);
  }, [auth?.token, dispatch, companyId]);

  useEffect(() => {
    if (auth?.token && customerId) {
      getCustomerMetricsAction(customerId, auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch, customerId]);

  useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customerId}&isSubscription=${"pos"}`
      )(dispatch);
  }, [auth?.token, dispatch, customerId]);

  const tabsComponent = [
    {
      title: <h4>Cash Sale ({customer?.purchased?.length || 0})</h4>,
      components: <CashSaleTable />,
    },
    {
      title: <h4>Subscription ({account?.all?.total || 0})</h4>,
      components: <CustomerSubscription />,
    },
    {
      title: <h4>Devices ({customer?.customerDevices?.total ?? "0"})</h4>,
      components: <CustomerDevices />,
    },
    {
      title: <h4>Files</h4>,
      components: <File />,
    },
    {
      title: <h4>Notes ({account?.note?.total ?? 0})</h4>,
      components: (
        <div>
          <NotesCard notes={customer?.customerNotes?.data} />
        </div>
      ),
    },
    {
      title: <h4>Repairs ({customer?.customerRepairs?.total ?? 0})</h4>,
      components: (
        <div>
          <CustomerRepairTable />
        </div>
      ),
    },
    {
      title: <h4>{`No Sales (${customer?.customerNoSale?.total ?? 0})`}</h4>,
      components: (
        <div>
          <CustomerNoSaleTable data={customer?.customerNoSale?.data} />
        </div>
      ),
    },
  ];
  const items = tabsComponent.map((el: any, i) => {
    const id = String(i + 1);
    return {
      label: el?.title,
      key: id,
      children: el?.components,
    };
  });
  const metrics = [
    {
      label: "Total Invoice Amount",
      value: ` ${getDefaultCurrencyCode(company)} ${
        customer?.customerMetrics?.totalAmountPaid?.toLocaleString() ?? 0
      }`,
    },
    {
      label: "Total Bill Amount",
      value: `${getDefaultCurrencyCode(company)} ${
        customer?.customerMetrics?.totalPendingLateAmount?.toLocaleString() ?? 0
      }`,
    },

    {
      label: "Upcoming Payment",
      value: `${getDefaultCurrencyCode(company)} ${
        customer?.customerMetrics?.nextBillingAmount?.toLocaleString() ?? 0
      }`,
    },
    {
      label: "Total Devices",
      value: customer?.customerMetrics?.totalDevices?.toLocaleString() ?? 0,
    },
    {
      label: "No Sales Amount",
      value:
        customer?.customerMetrics?.totalNoSaleAmount?.toLocaleString() ?? 0,
    },
    {
      label: "Next Payment Due",
      value: customer?.customerMetrics?.nextBillingDate?.slice(0, 10) ?? "N/A",
    },
  ];

  return (
    <div className="text-[#030229] p-1 space-y-2">
      <DetailsHeaderActionBar
        goBack={goBack}
        pageName={props?.pageName}
        title={props?.title}
      />
      <div>
        <div className=" flex lg:flex-row flex-col gap-10 mt-5 mb-10">
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 500, minHeight: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <CustomerCard
              data={customer?.selected}
              onEdit={props?.handleClickEdit}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 600 }}>
              <Card.Meta />
            </Card>
          ) : (
            <div
              style={styles.customShadow}
              className={`${
                layout?.isSideNavOpen ? "lg:w-[540px]" : "lg:w-[580px]"
              }  w-full bg-white rounded-md p-5`}
            >
              <div className="border-b px-4">
                <h1 className="text-[#030229] text-base font-medium my-2">
                  Current Summary
                </h1>
              </div>
              <div className="bg-white w-[100%]  rounded-md py-2">
                <div className="grid grid-cols-2 gap-6 leading-3 pt-2">
                  {metrics?.map((el: any, index: number) => (
                    <div
                      key={index}
                      className="flex flex-col items-center justify-center  rounded-md text-base leading-6  min-h-16 border border-[#EBEFF2]"
                    >
                      <div className="text-[#030229] font-medium font-poppins text-base text-center">
                        {el?.value}
                      </div>
                      <div className="text-[#03022980] font-normal text-[14px] text-center">
                        {el?.label}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 450 }}>
              <Card.Meta />
            </Card>
          ) : (
            <div
              style={styles.customShadow}
              className="bg-white p-5 px-7 lg:w-[450px] w-full rounded-md mt-5 lg:mt-0"
            >
              <div className="flex justify-between">
                <h1 className="text-[#030229] text-base font-medium">
                  {` Other Contact (${customer?.selected?.subscriptionInfo?.witnessInfo?.length})`}
                </h1>
              </div>
              <div className="mt-3">
                <ContactInfoCard data={customer?.selected} />
              </div>
            </div>
          )}
        </div>
        {/*  CREDIT SCORING WILL BE APPLIED LATER IN THE UPCOMMING FEATURES
       <div className="mt-5 w-full">
          <CreditReportCard />
        </div>  */}
        {/* </div>
      <div className="mt-5 py-5"> */}
      </div>
      <Tabs items={items} />
    </div>
  );
};
export default DetailsPage;
