import React, { useEffect } from "react";
import { Table, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import { getCustomerPurchaseAction } from "../../../store/customer/actions";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const CashSaleTable = (props: any) => {
  const { auth, customer, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "paidOn",
      key: "paidOn",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p>{record?.data?.shop?.name ?? "N/A"}</p>
          <p className="font-light italic">
            {record?.data?.shop?.channel?.name ?? "N/A"}
          </p>
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      render: (text, record) => (
        <Tooltip title={`${record?.data}`}>
          <p>{text}</p>
        </Tooltip>
      ),
    },
    {
      title: "IMEI",
      dataIndex: "barCode",
      key: "barCode",
    },
    {
      title: "Data Activated",
      dataIndex: "dataActivated",
      key: "dataActivated",
      render: (text: any, record: any) => (
        <p>{`${record?.dataActivated ? "Yes" : " No"}`}</p>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => <p>{record?.amount?.toLocaleString()} </p>,
    },
    {
      title: "Disc",
      dataIndex: "data",
      key: "data",
      render: (_text, record) => {
        return <p>{record?.data?.discount?.discountPerc} %</p>;
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "data",
      key: "data",
      render: (_text, record) => (
        <Tag
          className="font-bold"
          color={
            record?.data?.payment?.amount +
              (record?.data?.payment?.amount *
                record?.data?.payment?.extendedWarranty) /
              100 -
              (record?.data?.payment?.amount *
                record?.data?.cart?.discount?.discountPerc) /
              100 ===
              record?.data?.cart?.payment?.reduce(
                (sum: number, a: any) => (sum = sum + a?.amount),
                0
              )
              ? "success"
              : record?.data?.payment?.amount +
                (record?.data?.payment?.amount *
                  record?.data?.payment?.extendedWarranty) /
                100 -
                (record?.data?.payment?.amount *
                  record?.data?.discount?.discountPerc) /
                100 >
                record?.data?.payment?.reduce(
                  (sum: number, a: any) => (sum = sum + a?.amount),
                  0
                )
                ? "error"
                : "cyan-inverse"
          }
        >
          {record?.data?.payment?.length > 0
            ? record?.data?.payment
              ?.reduce((sum: number, a: any) => (sum = sum + a?.amount), 0)
              ?.toLocaleString()
            : record?.data?.payment?.toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment",
      render: (_text: any, record: any) => (
        <p>
          {record?.data?.payment?.map((d: any) =>
            d?.mode?.split("_")?.join(" ")
          )}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_text, record) => (
        <span className="flex items-center">
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
        </span>
      ),
    },
  ];

  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customer?.selected?._id}&isSubscription=${"pos"}`
      )(dispatch);
  }, [auth.token, dispatch, customer?.selected?._id]);

  const value = customer?.purchased;

  const dataToDisplay = value?.map((el: any) => {
    return {
      key: el?._id,
      paidOn: el?.paidOn?.slice(0, 10),
      agent: el?.createdBy?.names,
      model: el?.list?.at(0)?.warehouseProduct?.product?.model,
      specification: `${el?.list
        ?.at(0)
        ?.warehouseProduct?.product?.specs?.map(
          (d: any) => d[1] && `${d[0]}: ${d[1]}`
        )
        ?.join(", ")}`,
      amount: el?.payment?.at(0)?.amount,
      status: el?.status,
      barCode: el?.list?.at(0)?.warehouseProduct?.serialNumber,
      dataActivated: el?.list?.at(0)?.warehouseProduct?.dataActivated,
      data: el,
    };
  });

  return (
    <>
      <div className="relative text-[#030229] mt-4">
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          pagination={false}
          loading={customer?.isFetching}
        />
      </div>
    </>
  );
};
export default CashSaleTable;
