import React from "react";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Stack, useMediaQuery } from "@mui/material";
import MetricsCard from "../../../../components/cards/purchase/MetricsCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import OverviewPOCard from "../../../../components/cards/purchase/OverviewPOCard";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../../../store/purchase/actions";
import ChannelChart from "../../../../components/graphy/ShopChart";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";

const SubscriptionOverview = (props: any) => {
  const { purchase, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };
  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className="text-[#030229] py-6">
      <div className="flex flex-col md:flex-row justify-between my-3">
        <DetailsHeaderActionBar
          goBack={goBack}
          pageName="Channel"
          title={channel?.selected?.name}
        />
        <div>
          <DatePicker onChange={onChange} className={"w-48"} />
        </div>
      </div>
      <Stack spacing={3} className="mt-5">
        <div
          className={`grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-2 text-white`}
        >
          {/* Overview card */}
          <MetricsCard
            title={"Total Purchase Orders"}
            number={70}
            icon={<LocalAtmIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Total Products Received"}
            number={100}
            icon={<DoneAllIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"RFQs Received"}
            number={200}
            icon={<AutoGraphOutlinedIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Active Suppliers"}
            number={10}
            icon={<NotificationsOutlinedIcon style={{ color: "white" }} />}
          />
        </div>
        {/* <div className="w-full bg-white p-3 rounded-lg space-y-4">
          <div className="flex gap-x-10">
            <p className="text-xl font-semibold">Recent Activities</p>
          </div>
          <div className="flex flex-wrap gap-4"></div>
          <ChannelChart/>
        </div> */}
      </Stack>
    </div>
  );
};

export default SubscriptionOverview;
