import React from "react";
import { searchValue } from "../../../utils/setColor";
import { Tag } from "antd";
import ThreeInvoiceDotDropdown from "../../../pages/dashboard/subscription/Accounts/billingAction/ThreeInvoiceDotDropdown";

interface MyComponentProps {
  onOpenViewModel: (value: unknown) => void;
  invoiceData: any;
}

const InvoiceCard: React.FC<MyComponentProps> = ({
  invoiceData,
  onOpenViewModel,
}) => {
  return (
    <div className="lg:hidden w-full h-full grid md:grid-cols-2 gap-2 text-[#030229]">
      {invoiceData?.map((items: any) => (
        <div
          key={items?._id}
          className="min-h-[210px] bg-white rounded-[10px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] px-3 pt-2.5 pb-3 relative"
        >
          <div className="flex flex-col border-b pb-2">
            <div className="flex items-center justify-between mb-2">
              <h1 className="text-[14px] font-semibold sm:w-[14rem]">
                {items?.bill}
              </h1>
              <div className="flex items-center">
                <Tag
                  style={{ padding: "4px 10px" }}
                  color={searchValue(items?.status)}
                  className="min-w-20"
                >
                  <span className="text-[12px] flex justify-center items-center font-normal capitalize">
                    {items?.status}
                  </span>
                </Tag>
                <ThreeInvoiceDotDropdown
                  onOpenViewModel={() => onOpenViewModel(items)}
                  record={items}
                  status={items?.status}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 w-full gap-1.5">
              <div className="border border-[#EBEFF2] px-3 py-0.5 rounded-md space-y-2">
                <div className="text-gray-500 font-medium">
                  Amount to be paid
                </div>
                <div className="text-[#030229] font-poppins font-semibold text-14 leading-21">
                  {items?.amountTobePaid}
                </div>
              </div>
              <div className="border border-[#EBEFF2] px-3 py-0.5 rounded-md space-y-2">
                <div className="text-gray-500 font-medium">Amount paid</div>
                <div className="text-[#030229] font-poppins font-semibold text-14 leading-21">
                  {items?.amountPaid}
                </div>
              </div>
              <div className="border border-[#EBEFF2] px-3 py-0.5 rounded-md space-y-2">
                <div className="text-gray-500 font-medium">Starting Date</div>
                <div className="text-[#030229] font-poppins font-semibold text-14 leading-21">
                  {items?.month}
                </div>
              </div>
              <div className="border border-[#EBEFF2] px-3 py-0.5 rounded-md space-y-2">
                <div className="text-gray-500 font-medium">Due Date</div>
                <div className="text-[#030229] font-poppins font-semibold text-14 leading-21">
                  {items?.date}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center mt-1 space-x-2 ">
            <p className="text-gray-500 font-medium">Paid On</p>
            <p className="text-[#030229] font-poppins font-semibold text-14 leading-21">
              {items?.paidon}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceCard;
