import React from "react";
import { Avatar } from "antd";
import { useSelector } from "react-redux";

const Component = (props: any) => {
  const { account } = useSelector((stat: any) => stat);
  return (
    <div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: "auto",
        padding: "0 16px",
      }}
    >
      <ul className=" ml-6">
        {(account?.note?.data || props?.notes)?.map((el: any, index: any) => {
          return (
            <>
              <li className="flex">
                <div className=" mt-1  h-[auto]">
                  <div>
                    <Avatar src={el?.createdBy?.picture} />
                  </div>
                  {index !== account?.note?.data?.length - 1 && (
                    <div className="h-[80%] w-[1px] bg-[#0f0f4711] mt-[0.6px] ml-3.5 mr-3"></div>
                  )}
                </div>
                <div className={` mb-3 px-6 py-2 w-full h-full rounded-md`}>
                  <div className="w-[90%]">
                    <div className=" mb-2 font- normal">
                      <p className="text-[#030229] flex gap-2 font-normal text-sm">
                        {el.title}
                      </p>
                      <p
                        className="text-[#03022995] font-normal text-[13px] pt-2"
                        dangerouslySetInnerHTML={{ __html: el.description }}
                      />
                    </div>
                    <div className=" mt-2">
                      <p className="text-[#03022960] text-[12px] pt-2">
                        {el?.createdAt?.split(".")[0].split("T").join(" ")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};
export default Component;
