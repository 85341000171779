import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../../components/details/supplier/DetailsPage";
import SupplierForm from "../../../../components/forms/NewSupplierForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import QuotationsTable from "./tables/QuotationTable";
import PurchaseOrdersTable from "./tables/PurchaseOrderTables";
import {
  getOneSupplierAction,
  deleteSupplierActions,
  getAllQuotationsSuppliersAction,
  getAllPOsSuppliersAction,
} from "../../../../store/supplier/actions";

type TAB = {
  title: string;
  component: any;
};

const SupplierDetails = (props: any) => {
  const { id } = useParams();
  const { auth, supplier } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const tabs: TAB[] = [
    {
      title: "OVERVIEW",
      component: <div>SIMPLE DASHBOARD SHOWS ALL POs and graphs</div>,
    },
    {
      title: "Quotations",
      component: <QuotationsTable data={supplier?.quotations} />,
    },
    {
      title: "Purchase Orders",
      component: <PurchaseOrdersTable data={supplier?.purchaseOrders} />,
    },
    {
      title: "IBM Invoices",
      component: <div>List of The suppliers who can suply This Product</div>,
    },
  ];

  const deleteSupplier = async () => {
    auth?.token &&
      (await deleteSupplierActions(id as string, auth?.token)(dispatch));
    navigate("/purchase/suppliers");
  };

  const handleClickEdit = () => {
    console.log("editing a supplier....");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    
    auth?.token && getOneSupplierAction(id as string, auth?.token)(dispatch);
    auth?.token &&
      getAllQuotationsSuppliersAction(
        auth?.token,
        `?supplier=${id as string}`
      )(dispatch);
    auth?.token &&
      getAllPOsSuppliersAction(
        auth?.token,
        `?supplier=${id as string}`
      )(dispatch);
  }, [id, auth?.token, dispatch]);
  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide">
      <DetailsPage
        UpdateForm={SupplierForm}
        pageItemId={id}
        specsData={supplier.selected && supplier.selected?.specs}
        tabs={tabs}
        pageName={"Suppliers"}
        title={supplier?.selected?.name}
        simpleDetails={supplier && supplier.selected}
        handleClickEdit={handleClickEdit}
        handleClickDelete={handleClickDelete}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteSupplier}
        onCancel={handleCancels}
        isLoading={supplier.isFetching}
        itemName="Supplier"
      />
    </div>
  );
};

export default SupplierDetails;
