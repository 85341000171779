import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../components/HeaderChannel";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddItem from "./priceList/AddItems";

import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../components/grids/priceList/ProductGrid";
import { getAllProductsAction } from "../../../store/product/actions";
import {
  getPricelistSearchAction,
  getCommissionBychannelAction,
  getAllShopAction,
  getCommissionlistSearchAction
} from "../../../store/channel/actions";
import PriceListFilter from "../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";

import CommissionListGrid from "../../../components/grids/priceList/commissionListGrid";
import CommissionListFilter from "../../../components/filters/CommisisionListFiltering";


const PageView = (props: any) => {
  const { auth, channel } = useSelector(
    (state: any) => state
  );

  const [searchKey, setSearchKey] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    if (searchKey === "") {
      auth?.token && getCommissionBychannelAction(auth?.token, `channel=${channel?.selected?._id}&limit=${limit}&page=${page - 1}`)(dispatch);
    }

  }, [auth?.token, channel?.selected?._id, dispatch, page, limit, searchKey]);


  const handleSearch = () => {
    searchKey &&
      auth?.token && getCommissionlistSearchAction(
        auth?.token,
        `?searchQuery=${searchKey}`
      )(dispatch);

  };
  useEffect(() => {
    if (searchKey === "" && auth?.token) {

      getCommissionlistSearchAction(
        auth?.token,
        `?type=Smartphone&brand=MTN&limit=${page}&page=${page - 1}`
      )(dispatch);

    }

  }, [searchKey, auth?.token, dispatch])



  useEffect(() => {
    auth?.token && getAllShopAction(auth?.token, `channel=${channel?.selected?._id}`)(dispatch);
    auth?.token &&
      getCommissionlistSearchAction(
        auth?.token,
        `?type=Smartphone&brand=MTN&limit=${page}&page=${page - 1}`
      )(dispatch);
  }, [auth?.token, dispatch])


  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Stack spacing={1}>
        <div className="text-black items-center justify-between mt-10 my-auto flex ml-5 mr-20">
          <DetailsHeaderActionBar
            pageName="Commission List"
            goBack={goBack}
            title=" "
          />
          <div className="mt-5">
            <SearchInput
              onSearch={handleSearch}
              onChange={(e: any) => {
                e.preventDefault();
                setSearchKey(e.target.value);
              }}
            /></div>
        </div>
        <ScrollableFrame
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={channel?.commissionBychannel?.total}
          count={Math.ceil(channel?.commissionBychannel?.total / limit)}
        >
          {channel?.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start">
              {Array.from({ length: 15 }).map((d: any) => (
                <div className="w-80">
                  <Skeleton animation="wave" />
                  <Skeleton variant="rectangular" height={150} />
                </div>
              ))}
            </div>
          )}
          {!channel.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
              <CommissionListGrid data={channel?.commissionBychannel?.data} />
            </div>
          )}
        </ScrollableFrame>
      </Stack>
    </>
  );
};

export default PageView;
