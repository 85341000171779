import React, { useEffect, useState } from "react";
import { getNameByCode } from "../../../utils/converter";
import { Button, Dropdown, Modal, notification, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { BsThreeDots } from "react-icons/bs";
import DropDown from "../../dropDown/dropDown";
import type { MenuProps } from "antd";
import { createMergePurchaseAction, getEbmSelectOptionAction, getEbmWaitingImportActions, updateEbmImportAction } from "../../../store/ebm/actions";
import { updateOneDeliveryNoteAction } from "../../../store/wareHouse/actions";
import { getAllEbmItemsAction } from "../../../store/product/actions";

const EbmImportWaitingForApprovalTable = (props:any) => {
  const { ebm, auth, wareHouse } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setCancelIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const dispatch=useDispatch();
  const showModal = (data: any) => {
    setIsModalOpen(true);
    setSelectedRecord(data);
  };
  const showCancelModal = (data: any) => {
    setCancelIsModalOpen(true);
    setSelectedRecord(data);
  };
  const deliveryNote = wareHouse?.selectedDeliveryNote?.data;
 useEffect(()=>{
  auth?.token && getEbmSelectOptionAction(auth?.token, "")(dispatch)
 },[dispatch])
 
 useEffect(()=>{
 ( auth?.token && deliveryNote?.listOfItems[0]?.ebmItemCode )&& getAllEbmItemsAction(auth?.token, `?itemCode=${deliveryNote?.listOfItems[0]?.ebmItemCode}`)(dispatch)
 },[])

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelModalCancel = () => {
    setCancelIsModalOpen(false);
  };
  console.log(deliveryNote?.listOfItems[0]?.ebmItemCode, "deliveryNote?.listOfItems[0]");
  const createMenuItems = (record: any): MenuProps["items"] => [
    {
      label: (
        <div className="flex gap-2">
          <span className="text-sm pt-1">Approve Request</span>
        </div>
      ),
      key: "0",
      onClick: () => showModal(record),
    },
    {
      label: (
        <div className="flex gap-3">
          <span className="text-sm pt-1">Cancel Request</span>
        </div>
      ),
      key: "1",
      onClick:()=>showCancelModal(record)
    },
  ];
  const columns = [
    {
      title: "Declared Date",
      dataIndex: "date",
    },
    {
      title: "Item Code",
      // dataIndex: "itemCode",
      rendr:(text:any)=>{
        return(
          <>
          <p>{deliveryNote?.listOfItems[0]?.ebmItemCode}</p>
          </>
        )
      }
    },
    {
      title: "Item Sequence",
      dataIndex: "itemSeq",
    },
    {
      title: "Item Name",
      dataIndex: "item",
    },
    {
      title: "Origin",
      dataIndex: "orgin",
      render: (text: any) => (
        <>
          <p>
            {text && ebm?.ebmSelectOption?.Cuntry && getNameByCode(ebm?.ebmSelectOption?.Cuntry, text)}
          </p>
        </>
      ),
    },
    {
      title: "Export Country",
      dataIndex: "exportCountry",
      render: (text: any) => {
        console.log("text,", text);
        return (
          <>
            <p>
              {text && ebm?.ebmSelectOption?.Cuntry && getNameByCode(ebm?.ebmSelectOption?.Cuntry, text)}
            </p>
          </>
        );
      },
    },
    {
      title: "Pack Unit",
      dataIndex: "unit",
      render: (text: any) => {
        console.log("text,", text);
        return (
          <>
            <p>
              {(text && ebm?.ebmSelectOption["Packing Unit"]) && getNameByCode(ebm?.ebmSelectOption["Packing Unit"], text)}
            </p>
          </>
        );}
    },
    {
      title: "Qty Unit",
      dataIndex: "qtyUnitCd",
      //   render: (_, record: any) => <p>{record?.data?.qtyUnitCd}</p>,
    },
    {
      title: "Net Quantity",
      dataIndex: "netWt",
    },
    {
      title: "Sale Price",
      dataIndex: "sale",
    },
    // {
    //   title: "Begining Stock",
    //   dataIndex: "stock",
    //   render: (_: any, record: any) => <p>0</p>,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any) => {
        return (
          <>
           {(text && ebm?.ebmSelectOption) && <p className="w-[5rem] py-1 text-center text-[#030229B2] border border-[#03022940] bg-[#0302290D] rounded-md">
              {(text && ebm?.ebmSelectOption["Import Item Status"])&& getNameByCode(ebm?.ebmSelectOption["Import Item Status"], text)}
            </p>}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "data",
      key: "data",
      render: (record: any) => (
        <>
          <DropDown items={createMenuItems(record)}/>
        </>
      ),
    },
  ];

  const data = ebm?.ebmWaitingImport?.data?.map((el: any, index: any) => {
    let year = el?.dclDe.substring(0, 4);
    let month = el?.dclDe.substring(4, 6) - 1;
    let day = el?.dclDe.substring(6, 8);
    let date = new Date(year, month, day);
    let formattedDate = date.toISOString().split("T")[0];
    return {
      key: index,
      item: el?.itemNm,
      country: el?.exptNatCd,
      orgin: el?.orgnNatCd,
      exportCountry: el?.exptNatCd,
      net: el?.netWt,
      date: formattedDate,
      qauntity: el?.qty,
      qtyUnitCd: el?.qtyUnitCd,
      gross: el?.totWt,
      itemSeq: el?.itemSeq,
      netWt: el?.netWt,
      unit: el?.pkgUnitCd,
      status: el?.imptItemsttsCd,
      data: el,
    };
  })||[];
   const ApproveEbmImport = async () => {
    if (auth?.token) {
    const res=  await updateEbmImportAction(auth?.token, {
        taskCode: selectedRecord?.taskCd,
        declarationDate: selectedRecord?.dclDe,
        hsCode: selectedRecord?.hsCd,
        itemClassCode: "4319150800",
        itemCode: deliveryNote?.listOfItems[0]?.ebmItemCode,
        importItemStatusCode: "3",
        itemSeq: selectedRecord?.itemSeq,
        qty: deliveryNote?.listOfItems[0]?.qtyStored,
        prc: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit,
        splyAmt: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit,
        totAmt: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit * deliveryNote?.listOfItems[0]?.qtyStored
      })(dispatch);
      if(res){
       await updateOneDeliveryNoteAction(
          auth?.token,
          deliveryNote?._id as string,
          {
            ebmRegistered: true,
            ebmImportStatus:"4",
            // ebmPurchaseStatus:"04"
          }
        )(dispatch);
       await createMergePurchaseAction(auth?.token, {
        ebmMergedData: selectedRecord,
        status:"Approved"
        })(dispatch);
        getEbmWaitingImportActions(auth?.token, `?date=${props?.selectedDate}`)(dispatch)
        notification.success({
          message: "EBM Import Saved Successfully!",
        });
        setIsModalOpen(false);
      } 
       else {
        notification.error({
          message: "EBM Import Failed To Be Saved!",
        });
      }
    }}
   const CancelEbmImport = async () => {
    if (auth?.token) {
    const res=  await updateEbmImportAction(auth?.token, {
        taskCode: selectedRecord?.taskCd,
        declarationDate: selectedRecord?.dclDe,
        hsCode: selectedRecord?.hsCd,
        itemClassCode: "4319150800",
        itemCode: deliveryNote?.listOfItems[0]?.ebmItemCode,
        importItemStatusCode: "4",
        itemSeq: selectedRecord?.itemSeq,
        qty: deliveryNote?.listOfItems[0]?.qtyStored,
        prc: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit,
        splyAmt: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit,
        totAmt: deliveryNote?.listOfItems[0]?.purchaseItem?.priceUnit * deliveryNote?.listOfItems[0]?.qtyStored
      })(dispatch);
      if(res){
        notification.success({
          message: "EBM Import Successfully Cancelled!",
        });
       await updateOneDeliveryNoteAction(
          auth?.token,
          deliveryNote?._id as string,
          {
            // ebmRegistered: true,
            ebmImportStatus:"4",
            // ebmPurchaseStatus:"04"
          }
        )(dispatch);
       await createMergePurchaseAction(auth?.token, {
        ebmMergedData: selectedRecord,
        status:"Cancelled"
        })(dispatch);
        getEbmWaitingImportActions(auth?.token, `?date=${props?.selectedDate}`)(dispatch)
        
        setCancelIsModalOpen(false);
      } 
       else {
        notification.error({
          message: "EBM Import Failed To Be Canceled!",
        });
      }
    }}
  console.log("ebm?.ebmWaitingImport?.data",ebm?.ebmWaitingImport?.data)
  return (
    <>
      <Modal
        title={
          <h1 className="text-[#030229B2] text-lg py-5">Approve Request</h1>
        }
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        // closable={false}
      >
        {/* {totalQtyStored !== totalEbmQuantity ? (
          <>
            <p className="text-[#030229B2] text-lg py-5">
              You Are going to approve purchase request
            </p>
            <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={ApprovePurchase}
            >
            {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Confirm Purchase"}
            </Button>
          </>
        ) : ( */}
        <>
          <p className="text-[#030229B2] text-lg py-5 text-center">
            Approve Ebm Import Request
          </p>
          <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={ApproveEbmImport}
            >
             {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Confirm Import"}
          </Button>
        </>
        {/* )} */}
      </Modal>
      <Modal
        title={
          <h1 className="text-[#030229B2] text-lg py-5">Cancel Request</h1>
        }
        open={isCancelModalOpen}
        footer={null}
        onCancel={handleCancelModalCancel}
        // closable={false}
      >
        {/* {totalQtyStored !== totalEbmQuantity ? (
          <>
            <p className="text-[#030229B2] text-lg py-5">
              You Are going to approve purchase request
            </p>
            <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={ApprovePurchase}
            >
            {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Confirm Purchase"}
            </Button>
          </>
        ) : ( */}
        <>
          <p className="text-[#030229B2] text-lg py-5 text-center">
            Cancel Ebm Import Request
          </p>
          <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={CancelEbmImport}
            >
             {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Cancel Import"}
          </Button>
        </>
        {/* )} */}
      </Modal>
      <Table columns={columns} dataSource={data? data:[]} loading={ebm?.isFetching}   scroll={{ x: "max-content" }} 
      />
    </>
  );
};
export default EbmImportWaitingForApprovalTable;
