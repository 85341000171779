import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";
export const reportDropdownRoutes = [
  {
    caption: "Sales",
    index: "Sales",
    path: "/report/sales",
  },
  // {
  //   caption: "Subscription",
  //   path: "/report/mahama",
  //   skip: ["partner", "sales-manager", "sales-supervisor", "inventory"],
  // },
  {
    caption: "Inventory",
    index:  "Inventory",
    path: "/report/inventory",
    skip: ["partner", "sales-supervisor"],
  },
  {
    caption: "Loan Book",
    index: "Loan Book",
    path: "/report/transaction",
    skip: ["partner", "sales-supervisor", "inventory"],
  },
];

const SalesRepRoutes = () => {
  const { auth } = useSelector((state: any) => state);

  const pagesArray = [
    {
      caption: "Sales",
      path: "/report/sales",
    },
    {
      caption: "Save The Childrenmmm",
      path: "/report/mahama",
      skip: !["inventory", "sales-manager"].includes(
        auth?.user?.role?.toLowerCase()
      ),
    },
  ];
  const token = store.get("authToken");
  return auth.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages?.find(
            (item) => item.title.toLowerCase() === "SalesRep".toLowerCase()
          )}
          pages={pagesArray}
        />
      }
      selectedNav={0}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default SalesRepRoutes;
