import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber } from "antd";
import AddIcon from "@mui/icons-material/Add";
import ProductFilter from "../../../../components/filters/ProductFiltering";
import { addItemToSelectedQuotationAction } from "../../../../store/quotation/actions";
import { getOneProductAction } from "../../../../store/product/actions";
import { myProductActions } from "../../../../store/product";

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, quotation } = useSelector((state: any) => state);
  const [selectedModelId, setSelectedModelId] = useState("");
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const val = {
      ...values,
      specification: [
        ["Type", product?.selected?.type],
        ["Brand", product?.selected?.brand],
        ...Object.entries(values.specification),
      ],
    };
    auth?.token &&
      (await addItemToSelectedQuotationAction(
        quotation?.selected?._id,
        val,
        auth.token
      )(dispatch));
    setSelectedModelId("");
    form.resetFields();
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
  }, [selectedModelId, quotation.selected, dispatch, auth?.token]);

  return (
    <div>
      <ProductFilter />
      <div className="mt-4 ml-5">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          style={{ maxWidth: "100%" }}
          scrollToFirstError
        >
          <div className="flex justify-between">
            <div className="h-[10rem] overflow-y-auto w-[70%]">
              <Form.Item
                name="product"
                label={
                  <h1 className="text-sm text-[#030229]">Product Model</h1>
                }
                rules={[
                  {
                    required: true,
                    message: "Model is required!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  style={{ width: 400 }}
                  value={selectedModelId === "" ? null : selectedModelId}
                  placeholder="Select By Model"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.all?.data?.map((pro: any) => ({
                    label: `${pro?.model} ~  ${pro?.type}`,
                    value: pro._id,
                  }))}
                  onChange={(value: any) => {
                    !value && setSelectedModelId("");
                    value && setSelectedModelId(value);
                  }}
                />
              </Form.Item>
              <div className=" flex flex-wrap gap-3 ml-[10.8rem]">
                {selectedModelId &&
                  product?.selected?.specs.map(
                    (spec: any) =>
                      spec.value.length >= 1 && (
                        <Form.Item name={["specification", spec?.label]}>
                          <Select
                            showSearch
                            allowClear
                            loading={product.isFetching}
                            disabled={product.isFetching}
                            style={{ width: 195 }}
                            placeholder={`Select  ${spec?.label}`}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").toString().includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toString()
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "")
                                    .toString()
                                    .toLowerCase()
                                )
                            }
                            options={spec?.value?.map((pro: any) => ({
                              label: pro,
                              value: pro,
                            }))}
                            onChange={(value: any) => {
                              console.log("::", value);
                            }}
                          />
                        </Form.Item>
                      )
                  )}
              </div>
            </div>
            <div>
              <Form.Item
                name="quantity"
                label="Quantity"
                tooltip="Please enter number of quantity of the product you selected!"
                rules={[
                  {
                    required: true,
                    message: "Quantity is required!",
                  },
                ]}
              >
                <InputNumber min={1} max={100000} style={{ width: "80%" }} />
              </Form.Item>

              <LoadingButton
                type="submit"
                variant="contained"
                onClick={() => {
                  props?.onClickAdd();
                }}
                startIcon={<AddIcon />}
                sx={{ width: "89%", bgcolor: "#605BFF" }}
                loading={quotation?.addItemIsFeching}
              >
                Add Product
              </LoadingButton>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default PageView;
