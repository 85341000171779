import React, { useState } from "react";
import EBMItemsTable from "../../../components/tables/EBM/EBMItemsTable";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
const EBMItems = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2">
        <DetailsHeaderActionBar
          pageName="Finance"
          title="EBM \ Manage Items"
          goBack={goBack}
        />
      </div>
      {/* <HeaderComponent
        item="Item"
        modelTitle="Add New Item"
        isNotCollapse={true}
        filterCard={<EBMFilter />}
        ModelComponent={<EBMManageItemForm onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
      /> */}
      <ScrollableFrame hidePagination={true}>
        <EBMItemsTable />
      </ScrollableFrame>
    </Stack>
  );
};

export default EBMItems;
