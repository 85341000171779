import { myFinanceActions } from ".";
import { getAlltransactionRangesService } from "./services";


export const getAllPurchaseOrdersAction = (token: string, query?: any) => {
  return async (dispatch: any) => {
    try {

      dispatch(myFinanceActions.setIsFetching(true));
      const res = await getAlltransactionRangesService(token, query);
      if (res?.status === 200) {
        dispatch(myFinanceActions.setAll(res));
        dispatch(myFinanceActions.setIsFetching(false));
      }
      dispatch(myFinanceActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
