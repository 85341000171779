import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Form,
  Input,
  notification,
  Select,
  Popconfirm,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Stack } from "@mui/material";
import { Spin, Tag, Modal } from "antd";
import {
  getAllWarehouseOutItemsAction,
  confirmReturnedDevicesAction,
} from "../../../../store/wareHouseOut/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getOneReturnedAction } from "../../../../store/channel/actions";

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ReturnedStockConfirm = (props: any) => {
  const { auth, wareHouseOut, tracking, company, channel } = useSelector(
    (state: any) => state
  );

  const [selectedProduct, setSelectedProduct] = useState<any>("");
  const [serialNoString, setSerialNoString] = useState<string>("");
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [verifiedItems, setVerifiedItems] = useState<string[]>([]);
  const [localQtyReceived, setLocalQtyReceived] = useState<{
    [key: string]: number;
  }>({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    auth?.token && getOneReturnedAction(`${id}`, auth?.token)(dispatch);
  }, [auth?.token, dispatch, id]);

  useEffect(() => {
    if (channel?.oneReturned?.data?.list) {
      const initialQtyReceived: { [key: string]: number } = {};
      channel.oneReturned.data.list.forEach((item: any) => {
        initialQtyReceived[item._id] = item.qtyRecieved || 0;
      });
      setLocalQtyReceived(initialQtyReceived);
    }
  }, [channel?.oneReturned?.data?.list]);

  const handleSetSelected = (product_id: string) => {
    setSelectedProductId(product_id);
    setSelectedProduct(
      channel?.oneReturned?.data?.list?.filter(
        (item: any) => item?._id === product_id
      )[0]
    );
    if (auth?.token && product_id) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${product_id}&limit=2000`
      )(dispatch);
    }
    setSerialNoString("");
  };

  const getSerialNumbers = (): string[] => {
    return serialNoString
      .split(/[\s\n]+/)
      .filter((imei) => imei.trim().length > 0);
  };

  const handleVerify = async () => {
    const serialNumbers = getSerialNumbers();
    if (auth?.token && selectedProductId && serialNumbers.length > 0) {
      const res = await confirmReturnedDevicesAction(
        {
          requestId: id,
          serialNumbers: serialNumbers,
        },
        auth.token
      )(dispatch);

      if (res) {
        // Update local state immediately
        setLocalQtyReceived((prev) => ({
          ...prev,
          [selectedProductId]:
            (prev[selectedProductId] || 0) + serialNumbers.length,
        }));

        setVerifiedItems([...verifiedItems, selectedProductId]);
        setSerialNoString("");

        // Update selected product with new quantity
        if (selectedProduct) {
          setSelectedProduct({
            ...selectedProduct,
            qtyRecieved:
              (localQtyReceived[selectedProductId] || 0) + serialNumbers.length,
          });
        }
        auth?.token && getOneReturnedAction(`${id}`, auth?.token)(dispatch);
      }
    } else {
      notification.warning({
        message: "Required Fields",
        description: "Please select a model and enter IMEI numbers",
      });
    }
  };

  const columns = [
    {
      title: "Type",
      dataIndex: ["product", "product", "type"],
      key: "type",
    },
    {
      title: "Quantity",
      dataIndex: "qtyRequested",
      key: "quantity",
    },
    {
      title: "Description",
      key: "description",
      render: (_: any, record: any) => (
        <span>
          {record?.product?.specification
            ?.map((spec: any[]) => `${spec[0]}: ${spec[1]}`)
            .join(", ")}
        </span>
      ),
    },
    {
      title: "Model",
      dataIndex: ["product", "product", "model"],
      key: "model",
    },
    {
      title: "Brand",
      dataIndex: ["product", "product", "brand"],
      key: "brand",
    },
  ];

  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <DetailsHeaderActionBar
        pageName={props?.pageName}
        title={"Returned Stock"}
      />
      <div className="p-5 bg-white rounded-lg space-y-6">
        <div className="flex relative gap-20 w-full justify-between items-end">
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"space-between"}
            className="w-full"
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              className="w-full"
            >
              <div className="mb-5">
                <p className="text-xsx text-gray-400">
                  {`${channel?.oneReturned?.data?.shopId?.name}`}
                </p>
                <p className="text-xsx text-gray-400">
                  {channel?.oneReturned?.data?.shopId?.channel?.name}
                </p>
                <p className="text-xsx text-gray-400 border border-[#FFC727] py-0.5 px-4 rounded-md">
                  {channel?.oneReturned?.data?.status}
                </p>
              </div>
            </Stack>
          </Stack>
        </div>

        <div className="flex flex-col  ">
          <div className="flex flex-col md:flex-row items-end gap-4">
            <div className="flex-1">
              <p className="mb-2 font-semibold">Select Model</p>
              <Select
                placeholder="Choose Product Model"
                value={selectedProductId || undefined}
                onChange={handleSetSelected}
                options={channel?.oneReturned?.data?.list?.map(
                  (item: any, _index: number) => ({
                    label: `${verifiedItems.includes(item?._id) ? "✔️ " : ""}${
                      item?.product?.product?.model
                    }`,
                    value: item?._id,
                  })
                )}
              />
            </div>

            {selectedProduct && (
              <>
                <div className="flex-1">
                  <p className="mb-2 font-semibold">Enter IMEI Numbers</p>
                  <Input
                    placeholder="Enter emie numbers"
                    value={serialNoString}
                    onChange={(e) => setSerialNoString(e.target.value)}
                  />
                </div>
                <Popconfirm
                  title="Verify Devices"
                  description={`Are you sure you want to verify ${
                    getSerialNumbers().length
                  } device(s)?`}
                  onConfirm={handleVerify}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{ style: { backgroundColor: "blue" } }}
                >
                  <Button
                    disabled={channel?.isFetching || !serialNoString.trim()}
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      height: "40px",
                    }}
                  >
                    Verify
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>

          {selectedProduct && (
            <div className="grid grid-cols-2 min-w-[288px] bg-slate-100  rounded-md p-3 mt-4 ">
              {selectedProduct?.product?.specification?.map(
                (item: any, index: number) =>
                  item[1] && (
                    <React.Fragment key={index}>
                      <span className="font-semibold">{item[0]}</span>
                      <span>{item[1]}</span>
                    </React.Fragment>
                  )
              )}
              <span className="font-semibold">verified count</span>
              <span>{`${localQtyReceived[selectedProductId] || 0}/${
                selectedProduct?.qtyRequested
              }`}</span>
            </div>
          )}
        </div>

        <div className="mt-6">
          <Table
            columns={columns}
            dataSource={channel?.oneReturned?.data?.list || []}
            loading={channel?.isFetching}
            rowKey="_id"
            pagination={false}
            rowClassName={(record: any) =>
              record._id === selectedProductId ? "bg-blue-50" : ""
            }
            scroll={{ x: "max-content" }} 
          />
        </div>
      </div>
    </div>
  );
};

export default ReturnedStockConfirm;
