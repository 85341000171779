import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { payWithMoMoAction } from "../../store/invoice/actions";
import momoLogo from "../../assets/images/MTNMomo-Logo.png";
import { useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, invoice, account } = useSelector((state: any) => state);
  const isTablet = useMediaQuery("(max-width: 1500px)");
  const { accountId } = useParams();

  const amountToPay =
    props?.data?.data?.amountToBePaid - props?.data?.data?.amountPaid;

  const onFinish = async (values: any) => {
    const phone = values?.phone?.replace(/\s/g, "");
    if (auth.token && amountToPay > 0) {
      await payWithMoMoAction(auth.token, {
        phone,
        accountId: accountId,
        amountToCollect: amountToPay,
      })(dispatch);
    }
  };

  return (
    <Form
      form={form}
      name="momopay"
      onFinish={onFinish}
      initialValues={{}}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
      className="flex justify-center flex-col antd-custom-style"
    >
      <div className="flex items-center justify-center mx-auto">
        <img src={momoLogo} alt="mtnLogo" className="w-80 " />
      </div>
      <div className="flex flex-col justify-start gap-x-5 h-full space-y-4">
        <div className={`${isTablet ? "ml-6" : "ml-20"}`}>
          <p className="lg:text-xl md:text-sm font-bold">Payment Details</p>
          <p className="text-lg">
            You will pay{" "}
            <span className="font-bold text-lg">
              {" "}
              {`${props?.data?.amountTobePaid?.toLocaleString()} RWF`}
            </span>{" "}
            with MoMo Pay
          </p>
        </div>
        <div
          className={`${
            isTablet ? "ml-4" : "ml-20"
          } flex-1 xbg-gray-300 p-2 pb-5 rounded-lg space-y-1`}
        >
          <p className="lg:text-lg md:text-sm font-semibold ">
            Momo number to pay from
          </p>
          <Form.Item
            name="phone"
            initialValue={account?.selected?.customer?.phone}
            rules={[
              {
                required: true,
                message: "Phone Number is required!",
                max: 18,
              },
            ]}
            hasFeedback
          >
            <PhoneInput
              country={"rw"}
              inputStyle={{
                width: isTablet ? "90%" : "60%",
                height: "36px",
              }}
              containerClass="phone-container"
              placeholder="+250 780 000 000"
            />
          </Form.Item>
        </div>
      </div>
      <div
        className={`${
          isTablet ? "ml-2" : "ml-20"
        } flex justify-start gap-x-4 pb-8 mt-1 !h-4 box-border`}
      >
        <Form.Item>
          <LoadingButton
            variant="outlined"
            onClick={props?.onCancel}
            sx={{ width: 100 }}
          >
            Back
          </LoadingButton>
        </Form.Item>
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={invoice?.isFetching}
            sx={{ width: 150 }}
          >
            Send
          </LoadingButton>
        </Form.Item>
      </div>
    </Form>
  );
};

export default App;
