import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import {
  checkRepaymentStatusAction,
  getAllAccountInvoiceAction,
  resetPaymentRelatedStates,
} from "../../store/invoice/actions";
import LoadingAnimation from "../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../assets/images/successfully-done.gif";
import { myInvoices } from "../../store/invoice";
import { MdError } from "react-icons/md";
import { getDefaultCurrencyCode } from "../../utils/helper";
import { useParams } from "react-router-dom";
import { handleRequestCompleted } from "../../store/layout/actions";
import { LoadingButton } from "@mui/lab";

const App = (props: any) => {
  const { invoice, auth, company, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();

  const paymentStatus = async () => {
    if (invoice?.repaymentReferenceId) {
      await checkRepaymentStatusAction(
        auth?.token,
        `?referenceId=${invoice?.repaymentReferenceId}&accountId=${accountId}`
      )(dispatch);
      // await handleRequestCompleted(false)(dispatch);
    }
  };

  const currentRepaymentStatus =
    invoice?.paymentStatusResult?.data?.status?.toLowerCase();

  useEffect(() => {
    if (!invoice.paymentInitiationStatus) {
      dispatch(myInvoices.setStatusResult(null));
      return;
    }

    let timerId: any = null;
    if (currentRepaymentStatus === "failed") {
      dispatch(myInvoices.setStatusResult(null));
      dispatch(myInvoices.setPaymentInitiationStatus(false));
      props?.onCancel();
    } else {
      timerId = setInterval(async () => {
        if (!layout?.isRequestDone) {
          await paymentStatus();
        }
      }, 500);
    }

    if (currentRepaymentStatus === "successful") {
      clearInterval(timerId);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [currentRepaymentStatus, layout?.isRequestDone]);

  const handleDone = async () => {
    await getAllAccountInvoiceAction(
      auth?.token,
      `?account=${accountId}&limit=15&page=0`
    )(dispatch);
    await resetPaymentRelatedStates()(dispatch);
    await props?.onCancel();
    await handleRequestCompleted(false)(dispatch);
  };

  return (
    <div className="flex justify-center items-center flex-col h-full max-w-[100%]">
      {currentRepaymentStatus === "failed" ? (
        <div className="flex justify-center items-center flex-col w-fit">
          <MdError className="h-60 w-60 text-red-500" />
          <div className="flex flex-col text-center">
            <p>This operation failed</p>
            <p>Make sure the customer have sufficient funds</p>
          </div>
        </div>
      ) : (
        <>
          {currentRepaymentStatus !== "successful" ? (
            <>
              <p className="lg:text-lg md:text-sm font-semibold">
                Complete Payment of RWF{" "}
                {props?.data?.amountTobePaid?.toLocaleString()}
              </p>
              <p>
                If there is no popup on your phone press *182*7*1# and confirm
                payment
              </p>
            </>
          ) : (
            <div className="text-center">
              <p className="lg:text-lg md:text-sm ">
                You have successfuly completed payment of
              </p>
              <p className="font-semibold">
                {" "}
                {`${getDefaultCurrencyCode(
                  company
                )} ${props?.data?.amountTobePaid?.toLocaleString()}`}
              </p>
            </div>
          )}
          {currentRepaymentStatus === "successful" ? (
            <>
              <img
                width="20%"
                height="20%"
                src={SuccessAnimation}
                alt="Successful"
              />
              <LoadingButton
                onClick={handleDone}
                sx={{ minWidth: 250 }}
                className="mt-4 mb-2"
                loading={invoice?.isFetching}
              >
                Finish
              </LoadingButton>
            </>
          ) : (
            <img
              width="20%"
              height="20%"
              src={LoadingAnimation}
              alt="loading"
            />
          )}
        </>
      )}
    </div>
  );
};

export default App;
