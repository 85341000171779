import React from "react";

const CurstomeCardSkeleton = () => {
  return (
    <div
      role="status"
      className="w-full mr-4 mb-6 p-4 border border-white bg-white rounded shadow animate-pulse md:p-6"
    >
      <div className="h-2.5 bg-gray-200 rounded-full mb-4"></div>
      <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full mb-2.5"></div>
      <div className="h-2 bg-gray-200 rounded-full"></div>
      <div className="flex items-center mt-6">
        <div>
          <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2"></div>
          <div className="w-48 h-2 bg-gray-200 rounded-full"></div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default CurstomeCardSkeleton;
