import { Form, Switch } from "antd";
import React, { useState } from "react";
import CustomButton from "../buttons/CustomButton";
import {
  createNoteAction,
  getAllNotesAction,
} from "../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../inputs/InputText";
import RichTextEditor from "../inputs/RichTextEditor";
import CreateCaseForm from "./CreateCaseForm";
import PickDate from "../inputs/PickDate";

const AddNoteForm = (props: any) => {
  const { auth, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formattedDate = new Date().toISOString();
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [textValue, setTextValue] = useState("");
  const [isCase, setIsCase] = useState("note");

  const onFinish = async (values: any) => {
    const currentUrl = window.location.href;
    const res = await createNoteAction(auth?.token, {
      ...values,
      description: textValue,
      reminder: [{ scheduledTime: selectedDate }],
      modelId: props?.sourceId,
      type: isCase,
      noteUrl: currentUrl,
    })(dispatch);
    if (res) {
      await getAllNotesAction(
        auth.token,
        `?modelId=${props?.sourceId}`
      )(dispatch);
      form.resetFields();
      props.onCancel();
    }
  };
  const onChange = (checked: boolean) => {
    setIsCase(checked ? "case" : "note");
  };
  return (
    <div className="w-full h-full text-[#030229] p-3">
      {isCase === "case" ? (
        <CreateCaseForm />
      ) : (
        <>
          <h1 className="pt-3 pb-6 opacity-[#03022980] font-medium mb-2">
            ADD NOTE
          </h1>
          <Form
            form={form}
            onFinish={onFinish}
            style={{ maxWidth: "100%" }}
            layout="horizontal"
          >
            <div className="flex flex-col">
              <div className="mll-2">
                <InputText
                  name="title"
                  label={
                    <p className="font-[450] text-sm text-[#030229] mr-[15px]">
                      Add title
                    </p>
                  }
                  width={"[100%]"}
                  height={10}
                  required={true}
                  requiredMessage="Title is required"
                />
              </div>
              <div className="flex flex-wrap gap-4">
                <p className="font-[450] text-sm text-[#030229]">Add Notes :</p>
                <RichTextEditor
                  textStyle={{
                    fontSize: "14px",
                    color: "#030229CC",
                    width: "80px",
                    fontWeight: 500,
                  }}
                  label="Add Notes : "
                  value={textValue}
                  setValue={setTextValue}
                  style={{
                    height: "250px",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className=" flex flex-col  w-full mt-6">
                <PickDate
                  label={
                    <p className="font-[450] text-sm text-[#030229] mr-4">
                      Set Reminder
                    </p>
                  }
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  className="pt-5"
                  textSize="14px"
                  style={{ width: "30%", height: 38 }}
                />
                <Form.Item name="isReminder">
                  <div className=" flex items-center  gap-2">
                    <p className="text-sm text-[#030229] font-[450]">
                      This is a case ? :{" "}
                    </p>
                    <div className="border border-[#030229] border-opacity-10 px-4 py-[6px] flex items-center rounded-md text-sm font-medium w-[15%]">
                      <p className="pr-2 font-normal">
                        {isCase === "case" ? "On" : "Off"}
                      </p>
                      <Switch onChange={onChange} />
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>
            <div className=" flex gap-5 w-full flex-wrap justify-end">
              <Form.Item>
                <CustomButton
                  btnName={
                    <h1 className="px-6 py-0.5 text-[#605BFF] font-normal">
                      Discard
                    </h1>
                  }
                  borderColor={"[#605BFF]"}
                />
              </Form.Item>
              <Form.Item>
                <CustomButton
                  btnName={
                    <h1 className="px-6 py-0.6 text-white font-normal">
                      Save Note
                    </h1>
                  }
                  loading={account?.isFetching}
                  btnBgColor={"[#605BFF]"}
                  loadingColor={"white"}
                  textColor={"white"}
                />
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};
export default AddNoteForm;
