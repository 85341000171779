import React from "react";
import { Progress } from "antd";
import type { ProgressProps } from "antd";
interface levelProps {
  name?: any;
  width?: any;
  amount?: any;
}
const FinancingLevel = ({ name, width, amount }: levelProps) => {
  const twoColors: ProgressProps["strokeColor"] = {
    "0%": "#efefff",
    "10%": "#b9b7ff",
    "35%": "#8481ff",
    "75%": "#7571ff",
    "100%": "#615dff",
  };

  return (
    <div className="flex gap-2 xl:gap-5 my-3 w-full">
      <span className="text-sm font-medium text-[#030229B2] w-full 2xl:w-[30%]">
        {name}
      </span>
      <Progress
        percent={width}
        strokeColor={twoColors}
        showInfo={false}
        size={["100%", 15]}
      />
      <span className="text-sm font-medium text-[#030229B2] 2xl:w-[15%] min-w-12 pt-1">
        {amount}
      </span>
    </div>
  );
};

export default FinancingLevel;
