import { Editor } from "primereact/editor";
interface editorProps {
  value: string;
  setValue: (e: string) => void;
  style?: any;
  textStyle?: any;
  label?: string;
}
function RichTextEditor({
  value,
  setValue,
  style,
  label,
  textStyle,
}: editorProps) {
  return (
    <Editor
      value={value}
      onTextChange={(e: any) => setValue(e.htmlValue)}
      style={style}
    />
  );
}
export default RichTextEditor;
