import { Avatar, Button, Divider, Space, Tag, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import RichTextEditor from "../../../components/inputs/RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneCaseAction,
  updateOneCaseAction,
} from "../../../store/account/actions";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CreateCaseForm from "../../../components/forms/CreateCaseForm";
import { FaCaretDown } from "react-icons/fa6";
import type { MenuProps } from "antd";
interface Option {
  key: any;
  label: any;
}

const options: Option[] = [
  { key: "Solved", label: "Mark As Solved" },
  { key: "Escalated", label: "Mark As Escalated" },
  { key: "Cancelled", label: "Mark As Cancelled" },
];

const ViewOneCase = (props: any) => {
  const { auth, account } = useSelector((state: any) => state);
  const [textValue, setTextValue] = useState("");
  const dispatch = useDispatch();
  const { caseId } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string>(options[0].label);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    if (auth?.token) {
      getOneCaseAction(auth?.token, caseId as string)(dispatch);
    }
  }, [auth?.token, caseId, dispatch]);

  // Function to update status
  const updateStatus = async (status: string) => {
    if (auth?.token && caseId) {
      await updateOneCaseAction(auth.token, caseId, { status })(dispatch);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = async ({ key }) => {
    const newSelection = options.find((option) => option.key === key);
    if (newSelection) {
      setSelected(newSelection.label);
      setSelectedOption(newSelection);
      await updateStatus(key);
    }
  };
  useEffect(() => {
    if (selectedOption) {
      console.log("selected status >>>>>", selectedOption, selected);
    }
  }, [selectedOption, selected]);
  const menu = (
    <Menu onClick={handleMenuClick}>
      {options.map((option) => (
        <Menu.Item key={option.key}>{option.label}</Menu.Item>
      ))}
    </Menu>
  );

  const goBack = () => {
    navigate(-1);
  };

  const caseData = account?.selectedCase;
  const statusColor =
    caseData?.status === "In-Progress"
      ? "#3930AB"
      : caseData?.status === "Cancelled"
        ? "#DE2C2F"
        : caseData?.status === "Escalated"
          ? "#F19D00"
          : caseData?.status === "Solved"
            ? "#12B76A"
            : "blue"; 
  return (
    <>
      <div className="py-6 text-[#030229]">
        <DetailsHeaderActionBar
          goBack={goBack}
          pageName={"Force-Support"}
          title={`CASE#${caseData?.ticketID}`}
        />
        <div className="flex flex-wrap gap-6 justify-between mt-2">
          <div className="2xl:w-[65%] w-full  rounded-[7px] bg-white shadow-[4px_1px_52px_0px_rgba(15,15,71,0.1)] p-6 py-4">
            <div className="flex flex-wrap justify-between gap-4 pt-2">
              <div className="font-medium text-[20px] 2xl:text-[18px] capitalize">
                {caseData?.categories} &nbsp; #{caseData?.ticketID}
              </div>
              <div>
                <Tag
                  className="flex items-center rounded-[2rem] px-6 py-1 text-[12px] font-normal"
                  color={statusColor}
                >
                  {caseData?.status}
                </Tag>
              </div>
            </div>
            <Divider />
            <div className="flex flex-wrap gap-4 justify-between">
              <div className="text-sm">
                <Space style={{ color: "#030229" }} wrap>
                  <Avatar
                    src={caseData?.assignedTo?.user?.at(0)?.picture}
                  ></Avatar>
                  <h1 className="text-[#030229] capitalize text-[18px] lg:text-[16px]">
                    {caseData?.assignedTo?.user?.at(0)?.names}
                  </h1>
                  <p className="text-[#605BFF] text-[16px] lg:text-[14px]">
                    {caseData?.assignedTo?.user?.at(0)?.role}
                  </p>
                  <p className="text-[16px] lg:text-[12px]">
                    {caseData?.createdAt?.slice(0, 10)}
                  </p>
                </Space>
              </div>
            </div>
            <div className="mx-[5px] flex gap-5 py-8">
              <div className="flex flex-col items-center py-[6px]">
                <div className="h-full w-[1px] bg-[#605BFF]"></div>
                <div className="rounded-full w-4 h-4 bg-[#605BFF]"></div>
              </div>
              <div>
                <h1 className="text-[20px] 2xl:text-[16px] mb-8 font-medium 2xl:mb-4 text-[#030229]">
                  {caseData?.noteId?.title}
                </h1>
                <p
                  className="w-full xl:w-[78%] sh-[15rem] overflow-y-auto font-normal text-[17px] 2xl:text-sm opacity-80 leading-8 2xl:leading-8 mb-10"
                  dangerouslySetInnerHTML={{
                    __html: caseData?.noteId?.description,
                  }}
                />
                <Space style={{ fontSize: "14px", marginTop: "5px" }}>
                  Updated on{" "}
                  <p className="font-medium ">{`${caseData?.updatedAt?.slice(
                    0,
                    10
                  )} ${caseData?.updatedAt?.slice(11, 16)}`}</p>{" "}
                </Space>
              </div>
            </div>
            <div className="mt-8 w-[75%] flex gap-3">
              <div>
                <Avatar
                  src={caseData?.assignedTo?.user?.at(0)?.picture}
                ></Avatar>
              </div>
              <div>
                <span>
                  <div className="flex mt-[5px]">
                    {" "}
                    <h1 className="text-[#030229] capitalize text-[18px] lg:text-[16px]">
                      {caseData?.assignedTo?.user?.at(0)?.names}
                    </h1>
                    <p className="opacity-80 text-[16px] lg:text-sm ml-8">
                      Add comment
                    </p>
                  </div>
                  <div className="my-4 ">
                    <RichTextEditor
                      textStyle={{
                        fontSize: "14px",
                        color: "#030229CC",
                        width: "80px",
                        fontWeight: 500,
                      }}
                      label=""
                      value={textValue}
                      setValue={setTextValue}
                      style={{
                        height: "120px",
                        borderRadius: "4px",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className=" flex flex-row justify-between gap-5  float-right mt-4">
                    <Button
                      // onClick={handleAdjust}
                      className="flex items-center justify-center bg-transparent hover:!bg-[#605BFF] border border-[#605BFF] text-[#605BFF] hover:!text-white transition-all duration-300 float-right"
                    >
                      <Space className="text-[12px] font-medium">Comment</Space>
                    </Button>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Button className="flex items-center justify-center bg-[#605BFF] text-white px-4 py-2 rounded-md transition-all">
                        <p className="text-[12px] font-medium">{selected}</p>
                        <FaCaretDown size={18} className="ml-2" />
                      </Button>
                    </Dropdown>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full 2xl:w-[33%] rounded-[7px] shadow-[4px_1px_52px_0px_rgba(15,15,71,0.1)] p-3">
            <CreateCaseForm dataToUpdate={caseData} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewOneCase;
