import { Stack } from "@mui/material";
import { Select, Button, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { IoFilterOutline } from "react-icons/io5";

const ProductFilter = (props: any) => {
  const { product, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");

  dispatch(
    myProductActions.setQuery(
      `?${type && `type=${type}&${brand && `brand=${brand}&`}`}`
    )
  );
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
    }
  };

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(auth?.token, product?.query || "?")(dispatch);
    auth?.token &&
      getAllAttributesAction(auth?.token, product?.query || "?")(dispatch);
  }, [auth.token, dispatch, product?.query]);

  return (
    <div className="px-8 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            style={{
              backgroundColor: selectedFilters ? "#F5F6FA" : " ",
              color: selectedFilters ? "#605BFF" : " ",
            }}
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            <Space>
              <IoFilterOutline
                style={{
                  color: selectedFilters ? "#605BFF" : " ",
                }}
                size={20}
                className="text-[#030229] font-medium opacity-90"
              />
              <p
                style={{
                  color: selectedFilters ? "#605BFF" : " ",
                }}
                className="text-[.8rem] text-[#030229] font-medium opacity-90"
              >
                {" "}
                Filter
              </p>
            </Space>
          </Button>
          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter by Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={product.isFetching}
                disabled={product.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  value && setType(value);
                }}
              />
              {props?.type && (
                <Select
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  showSearch
                  allowClear
                  style={{ width: 200 }}
                  placeholder="Filter by Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.attributes?.brands}
                  onChange={(value: any) => {
                    !value && setBrand("");
                    value && setBrand(value);
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
export default ProductFilter;
