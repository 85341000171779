import { ReactComponent as DashIcon } from "../icons/dashboard/sideNav/ri_dashboard-3-line.svg";
import { ReactComponent as InventoryIcons } from "../icons/dashboard/sideNav/mingcute_inventory-line.svg";
import { ReactComponent as SupportIcons } from "../icons/dashboard/sideNav/basil_headset-outline.svg";
import { ReactComponent as PurchaseIcons } from "../icons/dashboard/sideNav/ph_shopping-cart.svg";
import { ReactComponent as POSIcon } from "../icons/dashboard/pos.svg";
import { ReactComponent as B2BIcon } from "../icons/mdi_bank-transfer.svg";
import { ReactComponent as CustomersIcon } from "../icons/dashboard/sideNav/Vectors.svg";
import { ReactComponent as SalesIcon } from "../icons/dashboard/sideNav/icon-park-outline_sales-report.svg";
import { ReactComponent as ChannelIcon } from "../icons/dashboard/sideNav/globe.svg";
import { ReactComponent as FinanceIcon } from "../icons/dashboard/sideNav/material-symbols-light_finance-rounded.svg";
import { ReactComponent as ReportIcon } from "../icons/dashboard/sideNav/carbon_report.svg";
import { ReactComponent as Repair } from "../icons/dashboard/sideNav/ph_toolbox-light.svg";
import { ReactComponent as Subscription } from "../icons/dashboard/sideNav/eos-icons_activate-subscriptions-outlined.svg";
import { ReactComponent as EbmIcons } from "../icons/dashboard/sideNav/fluent_text-box-settings-24-regular.svg";
import { ReactComponent as Settings } from "../icons/dashboard/sideNav/setting.svg";
import { ReactComponent as VaultIcon } from "../icons/dashboard/sideNav/lock.svg";
import { ReactComponent as DeleiveryIcon } from "../icons/dashboard/sideNav/delivery-group.svg";
import { subscriptionDropdownRoutes as subscriptionRoutes } from "../../routes/SubscriptionRoutes";
import { DeliveryDropdownRoutes as DeliveryRoutes } from "../../routes/DeliveryRoutes";
import { purchaseDropDownRoutes as purchaseRoutes } from "../../routes/PurchasesRoutes";
import { repairDropDownRoutes as repairRoutes } from "../../routes/RepairsRoutes";
import { financeDropDownRoutes as financeRoutes } from "../../routes/FinanceRoutes";
import { reportDropdownRoutes as reportRoutes } from "../../routes/SalesRepRoutes";
import {
  customerDropdownRoutes,
} from "../../routes/CustomerRoutes";
import { inventoryDropdownRoutes as inventoryRoutes } from "../../routes/InventoryRoutes";
import { salesDropDownRoutes } from "../../routes/SalesRoutes";
import { supportDropDownRoutes } from "../../routes/SupportRoutes";

export const listManages = [
  {
    id: 1,
    title: "Dashboard",
    index: "Dashboard",
    path: "/dashboard",
    disabled: false,
    icon: <DashIcon className="SamIcon" />,
    roles: [
      "admin",
      "dev",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-supervisor",
      "partner",
      "franchisee",
      "b2b-relation",
    ],
  },
  {
    id: 2,
    title: "Inventory",
    path: "/inventory",
    icon: <InventoryIcons className="SamIcon" />,
    dropDown: inventoryRoutes,
    roles: [
      "admin",
      "dev",
      "inventory",
      "finance",
      "finance-manager",
      "sales-manager",
      "b2b-relation",
    ],
  },
  {
    id: 3,
    title: "Purchase",
    path: "/purchase",
    icon: <PurchaseIcons className="SamIcon" />,
    dropDown: purchaseRoutes,
    roles: ["admin", "dev", "finance", "finance-manager"],
  },
  {
    id: 4,
    title: "Sales",
    path: "/sales",
    icon: <SalesIcon className="SamIcon" />,
    dropDown: salesDropDownRoutes,
    roles: ["admin", "dev", "finance", "finance-manager"],
  },
  {
    id: 5,
    title: "POS",
    path: "/pos",
    icon: <POSIcon className="SamIcon" />,
    // roles: ["sales-agent", "sales-manager", "sales-supervisor", "admin", "dev"],
  },
  {
    id: 6,
    title: "Customers",
    path: "/customers/overview",
    icon: <CustomersIcon className="SamIcon" />,
    dropDown: customerDropdownRoutes,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-supervisor",
      "hr",
      "dev",
      "b2b-relation",
    ],
  },
  {
    id: 7,
    title: "Repairs",
    path: "/repairs/overview",
    icon: <Repair className="SamIcon" />,
    dropDown: repairRoutes,
    roles: [
      "admin",
      "technician",
      "dev",
      "sales-manager",
      "sales-supervisor",
      "b2b-relation",
      "finance",
      "finance-manager",
      "inventory",
      // dropDown: channelRoutes,
    ],
  },
  {
    id: 8,
    title: "Channel",
    path: "/channel",
    icon: <ChannelIcon className="SamIcon" />,
    // dropDown: channelRoutes,
    roles: [
      "admin",
      "dev",
      "sales-manager",
      "sales-supervisor",
      "inventory",
      "finance",
      "finance-manager",
      "b2b-relation",
    ],
  },
  {
    id: 9,
    title: "B2B",
    path: "/b2b",
    icon: <B2BIcon className="SamIcon" />,
    // roles: ["admin", "inventory", "sales-manager", "dev"],
  },
  {
    id: 10,
    title: "Finance",
    path: "/finance",
    icon: <FinanceIcon className="SamIcon" />,
    dropDown: financeRoutes,
    roles: ["admin", "finance-manager", "dev"],
  },
  {
    id: 11,
    title: "Subscription",
    path: "/subscription",
    icon: <Subscription className="SamIcon" />,
    dropDown: subscriptionRoutes,
    roles: ["admin", "finance-manager", "dev", "b2b-relation", "sales-supervisor"],
  },
  {
    id: 12,
    title: "Delivery",
    path: "/delivery",
    icon: <DeleiveryIcon className="SamIcon" />,
    dropDown: DeliveryRoutes,

    roles: ["dev", "admin"],
  },
  {
    id: 13,
    title: "Report",
    path: "/report",
    icon: <ReportIcon className="SamIcon" />,
    dropDown: reportRoutes,
    roles: [
      "partner",
      "admin",
      "dev",
      "inventory",
      "sales-manager",
      "sales-supervisor",
      "finance",
      "finance-manager",
      "b2b-relation",
    ],
  },
  {
    id: 14,
    title: "Vaults",
    path: "/vaults",
    icon: <VaultIcon className="SamIcon" />,
    roles: ["admin", "dev"],
  },
  {
    id: 15,
    title: "EBM",
    path: "/ebm",
    icon: <EbmIcons className="SamIcon" />,
    roles: ["dev"],
  },
  {
    id: 16,
    title: "Settings",
    path: "/setting",
    icon: <Settings className="SamIcon" />,
    // dropDown: settingRoutes,
    roles: ["admin", "hr", "dev"],
  },
  {
    id: 17,
    title: "Support",
    path: "/support/overview",
    icon: <SupportIcons className="SamIcon" />,
    dropDown: supportDropDownRoutes,
    roles: [
      "partner",
      "admin",
      "dev",
      "inventory",
      "sales-manager",
      "sales-supervisor",
      "finance",
      "finance-manager",
      "b2b-relation",
    ],
  },
];
