import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { notification, Modal } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import {
  getOneSubsOrderAction,
  submitSubOrdersAction,
  updateSubsorderStatusAction,
} from "../../../../store/subscription/order/actions";
import ComponentToPrint from "./ComponentToPrint ";
import { LoadingButton } from "@mui/lab";
import ReactToPrint from "react-to-print";
import {
  getOnesubDeliveryNoteAction,
  updateSubsDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import TableSkeleton from "../../../../components/skeleton/TableSkeleton";
import VerifyOtpForm from "../../inventory/warehouseOut/verifyOtpForm";
import { createShippingDetailsAction } from "../../../../store/wareHouseOut/actions";
import {
  createDeliveryActivityAction,
  updateParkingAction,
} from "../../../../store/delivery/actions";

const SubscriptionPicklistDetails = (props: any) => {
  const componentRef = useRef(null);
  const { orderId } = useParams();
  const { auth, orders, deliverynote, company, tracking, wareHouseOut } =
    useSelector((state: any) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [delivererDetailed, setDelivererDetails] = useState<any>(null);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

  const deliveryNoteId = orders?.selected?.account?.deliveryNote?._id;
  const trackingId = orders?.selected?.account?.deliveryNote?.trackingId;
  const delivererDetails = orders?.selected?.account?.deliveryNote?.deliverer;

  const showOtpModal = () => {
    setIsOtpModalOpen(true);
  };

  const handleOtpCancel = () => {
    setIsOtpModalOpen(false);
  };

  const handleRefresh = () => {
    if (orderId && auth?.token) {
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    setDelivererDetails(deliverynote?.singleDeliveryNote?.deliverer);
  }, [deliverynote?.singleDeliveryNote]);

  const goBack = () => {
    navigate(-1);
  };

  const handleConfirm = async (nextStatus: string) => {
    if (!delivererDetails) {
      return notification.error({
        message: "Please Add Deliverer Details",
      });
    }

    const updateOrderAndDeliveryNote = async (trackingId?: string) => {
      if (orderId && deliveryNoteId && auth?.token) {
        await updateSubsorderStatusAction(
          orderId,
          { status: nextStatus },
          auth.token
        )(dispatch);

        await updateSubsDeliveryNoteAction(
          deliveryNoteId,
          { status: nextStatus, trackingId },
          auth.token
        )(dispatch);

        await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
        await getOnesubDeliveryNoteAction(
          auth?.token,
          deliveryNoteId as string
        )(dispatch);

        if (nextStatus === "in-transit") {
          navigate("/subscription/DeliveryNote");
        }
      }
    };

    try {
      if (nextStatus === "ready-for-pickup") {
        const shipping = await createShippingDetailsAction(auth?.token, {
          deliver: delivererDetails?.delivererId,
          type: "To customer",
          deliveryNote: {
            id: deliveryNoteId,
            getLink: `/subsDeliveryNote/one/${deliveryNoteId}`,
          },
          locationFrom: {
            name: company?.selected?.name,
            coordinate: {
              lat: company?.selected?.warehouse[0]?.shopLocation?.coordinate
                ?.lat,
              lng: company?.selected?.warehouse[0]?.shopLocation?.coordinate
                ?.lng,
            },
          },
        })(dispatch);

        if (shipping?.type) {
          await createDeliveryActivityAction(auth?.token, {
            name: "Initialization",
            trackingStatus: "Ready For Pickup",
            details: "Devices are ready for Pickup",
            trackId: shipping?.data?._id,
          })(dispatch);

          await updateOrderAndDeliveryNote(shipping?.data?._id);
        }
      } else {
        await updateParkingAction(
          auth?.token,
          {
            name: "The devices have been picked up",
            trackingStatus: "In-Transit",
            details: "The devices are In-Transit",
          },
          trackingId
        )(dispatch);
        await updateOrderAndDeliveryNote();
      }
    } catch (error) {
      notification.error({ message: `${error}` });
    }
  };

  const handleCancel = async () => {
    if (auth?.token) {
      const response = await submitSubOrdersAction(
        orderId as string,
        { status: "created" },
        auth?.token
      )(dispatch);
      if (response) {
        navigate(`/subscription/order/${orderId}`);
      }
    }
  };

  const renderActionButtons = () => {
    const isFetching =
      orders?.isFetching || tracking?.isFetching || wareHouseOut?.isFetching;

    return (
      <div className="flex items-center space-x-2 px-6">
        <ReactToPrint
          trigger={() => (
            <LoadingButton
              variant="contained"
              sx={{
                bgcolor: isFetching ? "#E5E5E5" : "#4C39D4",
                width: "112px",
                color: "#fff",
                textTransform: "none",
              }}
              disabled
            >
              Print
            </LoadingButton>
          )}
          content={() => componentRef.current}
          copyStyles={true}
        />
        {orders?.selected?.status === "pick-list" && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={isFetching}
            onClick={() => handleConfirm("ready-for-pickup")}
            sx={{
              bgcolor: isFetching ? "#E5E5E5" : "#0FA958",
              width: "112px",
              color: "#fff",
              textTransform: "none",
            }}
            disabled={
              orders?.selected?.list?.at(0)?.deliveryNoteItem?.length === 0
            }
          >
            Accept
          </LoadingButton>
        )}
        {orders?.selected?.status === "ready-for-pickup" && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={isFetching}
            onClick={showOtpModal}
            sx={{
              bgcolor: isFetching ? "#E5E5E5" : "#0FA958",
              width: "112px",
              color: "#fff",
              textTransform: "none",
            }}
          >
            Pick Up
          </LoadingButton>
        )}
        {orders?.selected?.status === "pickup" && (
          <LoadingButton
            variant="contained"
            color="success"
            loading={isFetching}
            onClick={() => handleConfirm("in-transit")}
            sx={{
              bgcolor: isFetching ? "#E5E5E5" : "#0FA958",
              width: "112px",
              color: "#fff",
              textTransform: "none",
            }}
          >
            Confirm
          </LoadingButton>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="text-black py-6 relative">
        <div className="flex flex-row items-center text-[#0F0F47] pb-4">
          <DetailsHeaderActionBar
            pageName={"pick list"}
            goBack={goBack}
            title={orders?.selected?.account?.deliveryNote?.SDNID}
            isLoading={orders?.newOrderIsFetching}
            handleRefresh={handleRefresh}
          />
        </div>
        <Stack
          spacing={3}
          className="flex-grow flex flex-col justify-between bg-white scrollbar-hide "
        >
          {orders?.newOrderIsFetching ? (
            <TableSkeleton />
          ) : (
            <>
              <ComponentToPrint ref={componentRef} hasAccount={false} />
              <div className="text-black flex items-center justify-between px-4 pb-2">
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={orders?.createOderIsFetching}
                  disabled={orders?.selected?.status === "pickup"}
                  sx={{
                    bgcolor: orders?.isFetching ? "#E5E5E5" : "#F1595C",
                    width: "112px",
                    color: "#fff",
                    textTransform: "none",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </LoadingButton>
                {renderActionButtons()}
              </div>
            </>
          )}
        </Stack>
      </div>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Confirm With Otp</h1>
        }
        width={800}
        open={isOtpModalOpen}
        onCancel={handleOtpCancel}
        footer={null}
      >
        <VerifyOtpForm
          onOk={handleOtpCancel}
          selectedP={""}
          phone={""}
          from={"subscription"}
        />
      </Modal>
    </>
  );
};
export default SubscriptionPicklistDetails;
