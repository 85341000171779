import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { FiPlus } from "react-icons/fi";

interface btnProps {
  btnName?: any;
  btnBgColor?: any;
  borderColor?: any;
  textColor?: any;
  loading?: boolean;
  loadingColor?: string;
  minWidth?: string;
  icon?: any;
  onClick?: () => void;
}
const CustomButton = ({
  btnName,
  btnBgColor,
  borderColor,
  textColor,
  loading,
  loadingColor = "[#605BFF]",
  minWidth = "100px",
  icon,
  onClick,
}: btnProps) => {
  return (
    <button
      type="submit"
      className={`text-${textColor}  font-medium text-center border border-${borderColor} py-1 px-2 rounded-md bg-${btnBgColor} min-w-${minWidth}`}
      onClick={onClick}
    >
      {loading ? (
        <Spin
          indicator={<LoadingOutlined spin />}
          className={`text-${loadingColor}`}
        />
      ) : (
        <div className="flex gap-2">
          {icon && icon} <p> {btnName}</p>
        </div>
      )}
    </button>
  );
};

export default CustomButton;
