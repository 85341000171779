import { useEffect, useState } from "react";
import { Stack, Paper, Button, Divider } from "@mui/material";
import { Modal, Collapse } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import SummaryCard from "./dashboard/SummaryCard";
import SettingButton from "../pages/dashboard/setting/SettingButton";
import * as bsIcon from "react-icons/bs";

const { Panel } = Collapse;
const Component = (props: any) => {
  const navigate = useNavigate();
  const path = useLocation();
  const showModal = () => {
    !props.goForward && props?.setIsModalOpen(true);
    props?.setDataToUpdate && props?.setDataToUpdate(null);
    props.goForward && navigate(props.goForward);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  useEffect(() => {
    // props?.isGridView && props?.isGridView = isGridView
  }, []);

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "5px", width: "100%" }}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={0}
          // divider={<Divider orientation="vertical" flexItem />}
          >
            <h1>{props?.title}</h1>
            {props?.filterCard}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {!props?.isNotAddButton &&
              (path?.pathname === "/setting" ? (
                <div className="mt-2">
                  <SettingButton
                    btnName="New Channel"
                    btnBgColor="[#605BFF]"
                    textColor="white"
                    onClick={showModal}
                    icon={<bsIcon.BsPlusLg />}
                  />
                </div>
              ) : (
                <Button
                  variant="contained"
                  onClick={showModal}
                  sx={{ minWidth: 150 }}
                >
                  Add New {props?.item}
                </Button>
              ))}
            {props?.isNotAddButton && props.OtherButton}
          </Stack>
        </Stack>
        {/* <Divider /> */}
        {!props?.isNotCollapse && (
          <Collapse defaultActiveKey={["1"]} onChange={onChange}>
            <Panel header={props?.overview || "Summary Statistics"} key="1">
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"center"}
                spacing={4}
              >
                {props?.listSummary?.map((summary: any) => (
                  <Paper elevation={3}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <SummaryCard
                        title={summary.title}
                        value={summary.value}
                        bgColor={summary?.bgColor}
                      />
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Panel>
          </Collapse>
        )}
      </Stack>
      <Modal
        title={props?.modelTitle}
        open={props?.isModalOpen}
        onCancel={props?.handleCancel}
        footer={null}
        className="w-full max-w-[90vw] md:max-w-[80vw] max-h-[80vh] overflow-auto"
      >
        <div className="w-full">
          {props?.ModelComponent}
        </div>
      </Modal>
    </>
  );
};

export default Component;
