import { SetStateAction, useEffect, useState } from "react";
import { Menu, MenuItem, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Button,
  Carousel,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tag,
} from "antd";

import {
 
  getCommissionBychannelAction,
  updateCommissionAction,

} from "../../../store/channel/actions";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingOutlined } from "@ant-design/icons";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const Component = (props: any) => {
  const { auth, channel, company } = useSelector((state: any) => state);
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  

  const [selectedItems, setSelectedItems] = useState<any>([]);
  const shopItems = channel?.allShop?.data?.map((shop: any) => ({
    value: shop?._id,
    label: shop?.name,
  }));


  useEffect(() => {
    if (visible && props?.data?.shops) {
      setSelectedItems(props?.data?.shops?.map((item: any) => item?._id) || []);
    }
  }, [visible, props?.data?.shops]);



  const location = useLocation();
  const isOnCommissionRoute = /\/pos\/commissions$/.test(location.pathname);
  const [result, setResult] = useState<any>(null);

  const showModal = () => {
    setVisible(true);
  };

  const handleEditdevice = (data: any) => {
    setVisible(true);
    const result = channel?.commissionBychannel?.data?.filter(
      (item: any) => item?._id === data
    );
    setResult(result[0]);
  };
  const [isApplyCommission, setIsApplyCommission] = useState(
    props?.data?.isAppliedToAll
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const handleChange = (key: string, value: string) => {
    setResult({ ...result, [key]: value });
  };

  const onChange = (checked: boolean) => {
    setIsApplyCommission(checked);
  };

  const updatedData: any = {
    isAppliedToAll: isApplyCommission,
    isActive: true,
    price: result?.price,
    shops: selectedItems 
  };


  const onFinish = async (values: any) => {
    try {

      if (auth?.token) {
        const res: any = await updateCommissionAction(
          props?.data?._id,
          updatedData,
          auth.token
        )(dispatch);

        if (res) {
          await getCommissionBychannelAction(
            auth?.token,
            `?channel=${channel?.selected?._id}`
          )(dispatch);
        }
      }
    } catch (error) {
      console.error("Failed to update device:", error);
    }

    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon
          color="green"
          text={`${props?.data?.price} ${getDefaultCurrencyCode(company)}`}
        >
          <div className="relative cursor-pointer w-[100%] md:w-[27rem] h-52 p-2 pt-10 bg-white text-black rounded-md">
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.product?.product.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge className="site-badge-count-10 text-sm p-2 h-1">
                <div className="flex flex-col pl-2 gap-1">
                  <h1 className="font-bold text-lg">
                    {props?.data?.product?.product?.model}
                  </h1>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.product?.specification?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <Stack>
                              <p className="text-xs">
                                <b>{data[0]}:</b>
                                {` ${data[1]}`}
                              </p>
                            </Stack>
                          )
                      )}
                  </div>
                </div>
              </Badge>
            </div>
          </div>
          <div className="absolute bottom-0  py-2 flex flex-row justify-between w-full px-3">
            {isOnCommissionRoute ? (
              <div
                className="text-black rounded-md px-2 py-1 text-xs cursor-pointer"
                onClick={() => handleEditdevice(props?.data?._id)}
              >
                <EditIcon fontSize="medium" />
              </div>
            ) : (
              <div></div>
            )}
            <div className="bg-green-500 rounded-md px-2 py-1 text-xs ">
              {props?.data?.isAppliedToAll === true
                ? `Applied to All`
                : `${props?.data?.shops?.length} shop`}
            </div>
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title="Edit Commission"
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="basic"
          style={{ maxWidth: "100%" }}
          layout="vertical"
          initialValues={updatedData || {}}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name="isAppliedToAll">
            <div className="flex gap-5">
              <h1 className="text-[#030229] pl-1 text-sm font-normal">
                Apply To All Shops
              </h1>
              <Switch
                defaultChecked={props?.data?.isAppliedToAll}
                checked={isApplyCommission}
                onChange={onChange}
              />
            </div>
          </Form.Item>

          {!isApplyCommission && (
            <Form.Item
              name="Shops"
              label={
                <span className="text-[#030229] pl-1 text-sm font-normal">
                  Shops
                </span>
              }
              className="w-full"
            >
              <Select
                mode="multiple"
                placeholder="Select Shop(s)"
                defaultValue={props?.data?.shops?.map((item: any) => item?._id) || []}
                onChange={(values) => setSelectedItems(values)}
                style={{ width: "100%" }}
                options={shopItems}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Commission Price"
            name="price"
            rules={[{ required: true, message: "Please input your password!" }]}
          // values={result?.price}
          >
            <Input
              type="number"
              onChange={(e) => handleChange("price", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <button
              type="submit"
              className={` border w-full bg-[#605BFF] py-2 text-white text-center font-medium text-base  rounded-md `}
            >
              {channel.isFetching ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "white" }}
                      color="white"
                      spin
                    />
                  }
                />
              ) : (
                "Update"
              )}
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Component;
