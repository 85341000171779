import React, { useEffect, useState } from "react";
import { Select, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import moment from "moment";
import { getShopStatsActions } from "../../../../store/dashboard/actions";
import PlanOverviewPieChart from "./planOverviewPieChart";

interface DataType {
  key: any;
  shop: any;
  soldyesterday: any;
  soldtoday: any;
  earningyesterday: any;
  earningtoday: any;
}

export const SoldDevices = () => {
  let todayDate = moment();
  
  const { auth, dashboard } = useSelector((state: any) => state);
  
  const columns: ColumnsType<DataType> = [
    {
      title: "Shops",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "current Revenue",
      dataIndex: "currentDateRevenue",
      key: "currentDateRevenue",
    },
    {
      title: "current Quantity",
      dataIndex: "currentDateQuantity",
      key: "currentDateQuantity",
    },
    {
      title: "Earnings Yesterday)",
      dataIndex: "comparedDateRevenue",
      key: "earningcomparedDateRevenueyesterday",
    },
    {
      title: "Earnings (Today)",
      dataIndex: "comparedDateQuantity",
      key: "comparedDateQuantity",
    },
  ];


  const tableData = dashboard?.shopStats?.data.map(
    (shop: any,index:any) => {
    

      return {
        key:index,
        shopName: shop?.shopName,
        currentDateRevenue: shop?.currentDateRevenue?.toLocaleString(),
        currentDateQuantity:shop?.currentDateQuantity,
        comparedDateRevenue:shop?.comparedDateRevenue?.toLocaleString(),
        comparedDateQuantity:shop?.comparedDateQuantity,
      };
    }
  );


  return (
    <div className="w-full bg-[#fff] rounded-md h-[60vh] overflow-y-auto p-8">
      <div className="text-[#030229] text-lg flex justify-between">
        <div>
          <p className="text-[#030229] text-lg mr-4">Devices Sold</p>
        </div>
      
      </div>
      <div className="flex gap-10 my-3 text-sm">
        <p className="text-[#03022980] font-light text-base pt-3">
          SC:Service Center
        </p>

        <p className="text-[#03022980] font-light text-base pt-3">
          CS:Connect Shop
        </p>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
      />
    </div>
  );
};

export const PlansOverview = () => {
   const {overView} = useSelector(
     (state: any) => state
   );


   function transformStatsData(
    statsData: any
  ): { name: any; value: any }[] {
    // Ensure statsData is an array
    if (!Array.isArray(statsData)) {
      console.warn("Expected an array but got:", statsData);
      return [];
    }

    // Transform the data
    return statsData.map((item:any) => ({
      name: item.duration || 0,
      value: item.Applications || 0, 
    }));
  }

  // Example usage
  const statsData = overView?.statsPlan?.data;

  const transformedPlanData = transformStatsData(statsData || []);



  const COLORS = ["#605BFF", "#BFBDFF", " #5F00FFB2"];
  return (
    <div className="w-full bg-[#fff] h-[f28rem] overflowg-y-auto p-5 mt-5 rounded-md">
      <div className="text-[#030229] text-lg flex justify-between">
        <p>Plans Overview</p>
      
      </div>
      <div className="mb-4">
        <PlanOverviewPieChart
          data={transformedPlanData}
          colors={COLORS}
          totalLabel={<h1 className="text-[12px]">Total Plans</h1>}
          innerRad={43}
          height={"180px"}
          contentWidth={"w-full"}
          top={"50%"}
          left={"37%"}
          outerRad={60}
          totalValue={overView?.statsPlan?.data?.length}
          pieHeight={200}
          pieWidth={300}
        />
      </div>
    </div>
  );
};

