import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Table, MenuProps, Space, Dropdown } from "antd";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Divider } from "@mui/material";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import FilterButton from "./components/FilterButton";
import {
  deleteOneAccountAction,
  getAllAccountAction,
} from "../../../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import UpdateAccountForm from "../../../../components/forms/UpdateAccountForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import GeneralCustomFilter from "../../../../components/filters/generalCustomFilter";
import { TbGridDots } from "react-icons/tb";
import { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../../utils/setColor";
import { myAccounts } from "../../../../store/account";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { useParams } from "react-router-dom";
import { handleClearAllState } from "../../../../utils/converter";
import { myInvoices } from "../../../../store/invoice";
import { handlerSelectTabs } from "../../../../store/layout/actions";
import { accountIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { B2C_Account_Headers } from "../../../../components/csvHeaders/Headers";
import { getAllB2CAccount_CSVAction } from "../../../../store/csvDownload/actions";
import { PiDotsThreeLight } from "react-icons/pi";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditeSvg } from "../../../../assets/icons/editicon.svg";
import { getAllChannelAction } from "../../../../store/channel/actions";
import AccountFilter from "../../../../components/filters/filterForms/AccountFilter";
import AccountCard from "../../../../components/cards/account/AccountCard";

const DetailsPage = (props: any) => {
  const navigate = useNavigate();
  const { auth, account, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedChannel, setSelectedChannel] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);

  const clearActions = [mySubscritionActions.setSelected, myInvoices?.setAll];

  useEffect(() => {
    if (auth?.token) {
      getAllAccountAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }${selectedChannel ? `&channel=${selectedChannel}` : ""}${
          layout?.accountFilterType ? `&type=${layout?.accountFilterType}` : ""
        }&field=${accountIncludeItem}${
          layout?.searchResult !== "" && layout?.searchFrom === "account"
            ? `&sk=${layout?.searchResult}`
            : ""
        }`
      )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    layout?.accountFilterType,
    selectedChannel,
    limit,
    page,
    selectedStatus,
    layout?.searchResult,
    layout?.searchFrom,
  ]);

  useEffect(() => {
    if (!accountId) {
      dispatch(myAccounts.setSelected(null));
      dispatch(mySubscritionActions.setSelected(null));
    }
  }, [accountId, dispatch]);

  const openUpdateModel = (value: any) => {
    setUpdateData(value);
    setIsUpdateModalOpen(true);
  };

  const onCancelUpdateModel = () => {
    setUpdateData("");
    setIsUpdateModalOpen(false);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  const deleteaccount = async () => {
    await deleteOneAccountAction(auth?.token, deleteDataId, {
      isDeleted: true,
    })(dispatch);
    onCancelDeleteModel();
  };
  const [activeAccount, setActiveAccount] = useState("accounts as grid");

  interface DataType {
    key: React.Key;
    name: string;
    accountid: string;
    type: string;
    age: number;
    address: string;
  }
  const isDisabled = ["sales-supervisor"]?.includes(auth?.user?.role);
  const columns: ColumnsType<DataType> = [
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "accountid",
      title: "Account Id",
      dataIndex: "accountid",
      render: (text) => {
        return <p className="text-[#605BFF]">{text}</p>;
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "tel",
      title: "  Telephone",
      dataIndex: "tel",
    },
    {
      key: "device",
      title: "devices",
      dataIndex: "device",
    },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              style={{ background: searchValue(status) }}
              className={`h-[1.5rem] w-[4rem] rounded-md `}
            >
              <p className="font-normal text-md text-white">{status}</p>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "disable-navigation",
      render: (_, record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                onClick={() => !isDisabled && openUpdateModel(record?.data)}
                className="flex gap-3 text-[.83rem]"
              >
                <EditeSvg /> Edit
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                onClick={(e) => !isDisabled && openDeleteModel(record?._id)}
                className="flex gap-3 text-[.83rem]"
              >
                <DeleteSvg /> Delete
              </label>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-2">
              <Space direction="vertical">
                <Dropdown menu={{ items }} placement="bottom">
                  <PiDotsThreeLight size={30} />
                </Dropdown>
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const formData = account?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      name: el?.customer?.name || el?.customerDetails?.name,
      accountid: el?.accountID,
      type: el?.type?.toUpperCase(),
      tel: el?.customer?.phone || el?.customerDetails?.tel,
      device: el?.deliveryNote?.list?.length,
      date: el?.createdAt?.slice(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
      action: {
        openUpdateModel,
        openDeleteModel,
      },
    };
  });
  const handleIconClick = (iconType: string) => {
    setActiveAccount(iconType);
  };

  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
    handlerSelectTabs(0)(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllB2CAccount_CSVAction(
        auth?.token,
        `?page=0&limit=${account?.all?.total}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }${selectedStatus ? `&channel=${selectedStatus}` : ""}${
          layout?.accountFilterType ? `&type=${layout?.accountFilterType}` : ""
        }&field=${accountIncludeItem}`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map(
    (item: any, index: number) => {
      return {
        no: index + 1,
        date: item?.createdAt?.slice(0, 10),
        name: item?.customer?.name || item?.customerDetails?.name,
        type: item?.type,
        tel: item?.customer?.phone || item?.customerDetails?.tel,
        status: item?.status,
      };
    }
  );

  const resetFields = () => {
    setSelectedStatus("");
    setSelectedChannel("");
  };

  const handleRefresh = () => {
    if (auth?.token) {
      getAllAccountAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}${
          selectedStatus ? `&status=${selectedStatus}` : ""
        }${selectedChannel ? `&channel=${selectedChannel}` : ""}${
          layout?.accountFilterType ? `&type=${layout?.accountFilterType}` : ""
        }&field=${accountIncludeItem}${
          layout?.searchResult !== "" && layout?.searchFrom === "account"
            ? `&sk=${layout?.searchResult}`
            : ""
        }`
      )(dispatch);
    }
  };

  return (
    <>
      <div className="my-3 text-[#030229] opacity-70 flex justify-between mt-5">
        <DetailsHeaderActionBar
          pageName="Subscription"
          title="Accounts"
          isLoading={account?.isFetching}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="h-full w-full">
        <div className="flex flex-row justify-between">
          <div className="flex gap-2 flex-col lg:flex-row lg:justify-between">
            <GeneralCustomFilter
              handleResetFields={resetFields}
              component={
                <AccountFilter
                  setSelectedStatus={setSelectedStatus}
                  setSelectedChannel={setSelectedChannel}
                />
              }
            />
            <div className="mt-2">
              <FilterButton />
            </div>
          </div>
          <div className="hidden lg:flex flex-row items-center gap-4">
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color:
                    activeAccount === "accounts as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("accounts as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("accounts as table")}
                className={`cursor-pointer ${
                  activeAccount === "accounts as table"
                    ? "fill-blue-500"
                    : "fill-gray-500"
                }`}
              />
              {layout?.selectedAccountType === 2 && (
                <CSVExportButton
                  csvHeaders={B2C_Account_Headers}
                  csvData={csvData}
                  filename={`B2C user_files.csv`}
                />
              )}
            </div>
          </div>
        </div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        {activeAccount === "accounts as grid" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            {account?.isFetching ? (
              <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
                {Array.from({ length: 8 })?.map((el: any) => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <AccountCard
                openUpdateModel={openUpdateModel}
                openDeleteModel={openDeleteModel}
              />
            )}
          </ScrollableFrame>
        )}
        {activeAccount === "accounts as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: (event: any) => {
                    const isDisabledColumn = event.target.closest(
                      ".disable-navigation"
                    );
                    const isDisabledlabel =
                      event.target.closest(".ant-dropdown-menu");
                    if (!isDisabledColumn && !isDisabledlabel) {
                      navigate(`/subscription/account/${record?.key}`);
                    }
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={account?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>

      <Modal
        style={{ maxWidth: "100%" }}
        title="Update Account"
        open={isUpdateModalOpen}
        onCancel={onCancelUpdateModel}
        footer={null}
      >
        <UpdateAccountForm
          dataToUpdate={updateData}
          onCancel={onCancelUpdateModel}
        />
      </Modal>

      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteaccount}
        onCancel={onCancelDeleteModel}
        itemName={"Account"}
        isLoading={account?.isFetching}
      />
    </>
  );
};
export default DetailsPage;
