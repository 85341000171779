import React, { useState } from "react";
import { Button, Dropdown, Modal, notification, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { BsThreeDots } from "react-icons/bs";
import {
  createMergePurchaseAction,
  createPurchaseItemsAction,
  getEbmWaitingPurchaseActions,
} from "../../../store/ebm/actions";
import DropDown from "../../dropDown/dropDown";
import type { MenuProps } from "antd";

const App = (props:any) => {
  const { ebm, auth, wareHouse } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const showModal = (data: any) => {
    setIsModalOpen(true);
    setSelectedRecord(data);
  };
  const [isCancelModalOpen, setCancelIsModalOpen] = useState(false);
  const showCancelModal = (data: any) => {
    setCancelIsModalOpen(true);
    setSelectedRecord(data);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelModal = () => {
    setCancelIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const expandData = ebm?.ebmWaitingPurchase?.data?.flatMap(
    (el: any, index: any) =>
      el?.itemList?.map((d: any) => ({
        item: d?.itemNm,
        total: d?.totAmt,
        tax: d?.taxAmt,
        quantity: d?.qty,
        price: d?.prc,
        data: el,
      }))
  );
  const expandedRowRender = () => {
    const columns = [
      { title: "Item Name", dataIndex: "item", key: "item" },
      { title: "Quantity", dataIndex: "quantity", key: "quantity" },
      { title: "Unit Price", dataIndex: "price", key: "price" },
      { title: "Tax Amount", dataIndex: "tax", key: "tax" },
      { title: "Total Amount", dataIndex: "total", key: "total" },
    ];

    return (
      <Table
        columns={columns}
        dataSource={expandData}
        pagination={false}
        loading={ebm?.isFetching}
      />
    );
  };
  const totalEbmQuantity = expandData?.reduce(
    (sum: number, item: any) => sum + item.quantity,
    0
  );
  const columns = [
    { title: "Purchase Date", dataIndex: "date", key: "date" },
    { title: "Supplier Name", dataIndex: "name", key: "name" },
    { title: "TIN", dataIndex: "tin", key: "tin" },
    { title: "Invoice number", dataIndex: "invoice", key: "invoice" },
    { title: "Number of Items", dataIndex: "number", key: "number" },
    { title: "Tax", dataIndex: "tax", key: "tax" },
    { title: "Total Amount", dataIndex: "amount", key: "amount" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      dataIndex: "",
      key: "data",
      render: (record: any) => (
        <>
          <DropDown items={createMenuItems(record)} />
        </>
      ),
    },
  ];
  const deliveryNote = wareHouse?.selectedDeliveryNote?.data;
  const totalQtyStored = deliveryNote?.listOfItems?.reduce(
    (sum: number, item: any) => sum + item.qtyStored,
    0
  );
  const data = ebm?.ebmWaitingPurchase?.data?.map((el: any, index: any) => {
    return {
      key: index,
      item: el?.itemList?.map((el: any) => el?.itemNm),
      name: el?.spplrNm,
      tin: el?.spplrTin,
      tax: el?.taxAmtA,
      amount: el?.totAmt,
      number: el?.totItemCnt,
      date: el?.cfmDt?.substring(0, 10),
      invoice: el?.spplrInvcNo,
      status: el?.imptItemsttsCd,
      data: el,
    };
  })||[];
  const createMenuItems = (record: any): MenuProps["items"] => [
    {
      label: (
        <div className="flex gap-2">
          <span className="text-sm pt-1">Approve Request</span>
        </div>
      ),
      key: "0",
      onClick: () => showModal(record),
    },
    {
      label: (
        <div className="flex gap-3">
          <span className="text-sm pt-1">Cancel Request</span>
        </div>
      ),
      key: "1",
    },
  ];
  const ApprovePurchase = async () => {
    if (auth?.token) {
      const res = await createPurchaseItemsAction(auth?.token, {
        deliveryNoteId: deliveryNote?._id,
        supplierInvNo: selectedRecord?.data?.spplrInvcNo,
        registrationTypeCode: "A",
        purchaseTypeCode: "N",
        receiptTypeCode: "P",
        paymentTypeCode: "01",
        purchaseStatusCode: "02",
        // ValidatedDate: selectedRecord?.data?.cfmDt?.substring(0, 10),
      })(dispatch);
      if (res) {
       await createMergePurchaseAction(auth?.token, {
          ebmMergedPurchaseData: selectedRecord?.data,
          status:"Approved"
        })(dispatch);
       await getEbmWaitingPurchaseActions(
          auth?.token,
          `?date=${props?.selectedDate}&supplierTin=${
            deliveryNote?.pOrderID?.supplier?.tin
          }`
        )(dispatch);
        notification.success({
          message: "EBM Purchase Saved Successfully!",
        });
        setIsModalOpen(false);
      } else {
        notification.error({
          message: "EBM Purchase Failed To Be Saved!",
        });
      }
    }
  };
  const CancelEbmPurchase = async () => {
    if (auth?.token) {
      const res = await createPurchaseItemsAction(auth?.token, {
        deliveryNoteId: deliveryNote?._id,
        supplierInvNo: selectedRecord?.data?.spplrInvcNo,
        registrationTypeCode: "A",
        purchaseTypeCode: "N",
        receiptTypeCode: "P",
        paymentTypeCode: "01",
        purchaseStatusCode: "04",
        // ValidatedDate: selectedRecord?.data?.cfmDt?.substring(0, 10),
      })(dispatch);
      if (res) {
       await createMergePurchaseAction(auth?.token, {
          ebmMergedPurchaseData: selectedRecord?.data,
          status:"Canceled"
        })(dispatch);
       await getEbmWaitingPurchaseActions(
          auth?.token,
          `?date=${props?.selectedDate}&supplierTin=${
            deliveryNote?.pOrderID?.supplier?.tin
          }`
        )(dispatch);
        notification.success({
          message: "EBM Purchase Saved Successfully!",
        });
        setIsModalOpen(false);
      } else {
        notification.error({
          message: "EBM Purchase Failed To Be Saved!",
        });
      }
    }
  };

  return (
    <>
      <Modal
        title={
          <h1 className="text-[#030229B2] text-lg py-5">Approve Request</h1>
        }
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        // closable={false}
      >
        {totalQtyStored !== totalEbmQuantity ? (
          <>
            <p className="text-[#030229B2] text-lg py-5">
              You Are going to approve purchase request
            </p>
            <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={ApprovePurchase}
            >
            {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Confirm Purchase"}
            </Button>
          </>
        ) : (
          <>
            <p className="text-[#030229B2] text-lg py-5">
              Make sure total stored quantities are equal to total Ebm Quantity
            </p>
            <Button
              className="flex gap-2 text-[#030229B2] w-32 m-auto text-lg border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={handleCancel}
            >
              Close
            </Button>
          </>
        )}
      </Modal>
      
      <Modal
        title={
          <h1 className="text-[#030229B2] text-lg py-5">Cancel Request</h1>
        }
        open={isCancelModalOpen}
        footer={null}
        onCancel={handleCancelModal}
        // closable={false}
      >
        {/* {totalQtyStored !== totalEbmQuantity ? (
          <>
            <p className="text-[#030229B2] text-lg py-5">
              You Are going to approve purchase request
            </p>
            <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={ApprovePurchase}
            >
            {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Confirm Purchase"}
            </Button>
          </>
        ) : ( */}
        <>
          <p className="text-[#030229B2] text-lg py-5 text-center">
            Cancel Ebm Purchase Request
          </p>
          <Button
              className="flex gap-2 text-[#030229B2] text-lg w-64 m-auto border border-[#605BFF] py-2 px-3 rounded-md my-2 mb-5"
              onClick={CancelEbmPurchase}
            >
             {ebm?.isFetching? <Spin indicator={<LoadingOutlined spin />} size="small" />:  "Cancel Import"}
          </Button>
        </>
        {/* )} */}
      </Modal>
      <Table
        columns={columns}
        expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
        dataSource={data? data:[]}
        loading={ebm?.isFetching}
        scroll={{ x: "max-content" }} // Enables horizontal scrolling if needed

      />
    </>
  );
};

export default App;
