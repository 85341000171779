import React from "react";
import { useSelector } from "react-redux";
import { Skeleton } from "antd";
import Mtn2 from "../../../../../assets/download3.png";
import BillingCard from "./BillingCard";
import {
  getDefaultCurrencyCode,
  getSpecificationValue,
} from "../../../../../utils/helper";

const HeadersComponent = () => {
  const { account, invoice, company } = useSelector((state: any) => state);
  const statistics = account?.accountStatistics;
  const planDetails = account?.selected?.productPlan;

  const capacity = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["capacity", "capacity (rom)", "rom", "capacity(rom)"]
  );
  const memory = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["memory", "memory (ram)", "ram", "memory(ram)"]
  );

  const planSummaryData = [
    { label: "Plan Type", value: account?.selected?.businessProduct?.name },
    { label: "Installment", value: account?.selected?.planType },
    {
      label: `amount (${getDefaultCurrencyCode(company)})`,
      value: planDetails?.monthlyInstalment?.toLocaleString(),
    },
    {
      label: `Upfront (${getDefaultCurrencyCode(company)})`,
      value: statistics?.initialPayment?.toLocaleString(),
    },
    { label: "Model", value: planDetails?.pricelistItem?.product?.model },
    { label: "specifications", value: `${capacity || ""}-${memory || ""}` },
  ];

  return (
    <>
      {account?.statIsFetching ? (
        <Skeleton />
      ) : (
        <div className="mb-4 mt-4 py-3 rounded-md text-gray-900 capitalize font-poppins">
          <div className="flex flex-col xl:flex-row w-full gap-4 2xl:gap-0">
            <div className="bg-white rounded-md border border-[#EBEFF2] pt-2 md:pt-auto w-full 2xl:w-[35%]">
              <div className="border-b px-4">
                <div className="flex flex-wrap items-center justify-between font-poppins font-medium text-base leading-6 ml-2 pt-1 pb-2">
                  <p className="py-1.5"> Current Plan Summary </p>
                </div>
              </div>
              <div className="bg-white w-[100%] min-h-[100px] rounded-md px-4 py-2">
                <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-2 font-poppins font-medium text-base leading-6 pt-2">
                  {planSummaryData?.map((el: any, index: number) => (
                    <div
                      key={index}
                      className="flex flex-col font-poppins rounded-md font-medium text-base leading-6 pl-4 pt-2 min-h-16 border border-[#EBEFF2]"
                    >
                      <div className="text-gray-500 font-poppins">
                        {el?.label}
                      </div>
                      <div className="text-[#030229] font-poppins text-[13.5px]">
                        {el?.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden 2xl:block bg-white border border-[#EBEFF2] rounded-md 2xl:mx-4 pt-1 md:pt-auto flex-1">
              <div className="border-b pb-[1.35rem] px-4">
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <p> Payment Method </p>
                  <p></p>
                </div>
              </div>
              <div className="bg-white w-[100%] h-[100px] rounded-md py-4 px-4 space-y-2 flex-1">
                <div className="flex flex-row justify-between font-poppins rounded-md font-medium text-base leading-6 pt-2 h-16 border border-[#EBEFF2]">
                  <div className="p-2 flex flex-row gap-2">
                    <img src={Mtn2} alt="Mtn2" />
                    <p>
                      {invoice?.all?.data[0]?.payment?.mode
                        ?.split("_")
                        ?.join(" ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 w-full 2xl:w-[35%]">
              <BillingCard
                value={statistics?.startDate?.slice(0, 10)}
                name={"start date"}
              />
              <BillingCard
                value={statistics?.endDate?.slice(0, 10)}
                name={"end date"}
              />
              <BillingCard
                value={statistics?.expectedAmount?.toLocaleString()}
                name={`expected amount (${getDefaultCurrencyCode(company)})`}
              />
              <BillingCard
                value={`${statistics?.duration || ""} months`}
                name={"installment period"}
              />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row gap-4 mt-4 w-full">
            <div className="grid lg:hidden w-full xl:w-[35%] gap-4 2xl:grid grid-cols-2">
              <BillingCard
                value={statistics?.previousBillingDate?.slice(0, 10)}
                name={"previous payment date"}
              />
              <BillingCard
                value={statistics?.previoustBilling?.toLocaleString()}
                name={`previous billing amount (${getDefaultCurrencyCode(
                  company
                )})`}
              />
            </div>
            <div className="flex-1 grid grid-cols-2 gap-4">
              <BillingCard
                value={statistics?.nextBillingDate?.slice(0, 10) || "None"}
                name={"next billing due date"}
              />
              <BillingCard
                value={statistics?.nextBilling?.toLocaleString() || "None"}
                name={`next bill amount (${getDefaultCurrencyCode(company)})`}
              />
            </div>
            <div className="w-full xl:w-1/2 2xl:w-[35%] grid grid-cols-2 gap-4">
              <BillingCard
                value={statistics?.totalAmountPaid}
                name={`paid amount (${getDefaultCurrencyCode(company)})`}
              />
              <BillingCard
                value={statistics?.remainAmount}
                name={`Remain amount (${getDefaultCurrencyCode(company)})`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default HeadersComponent;
