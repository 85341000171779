import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "antd";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
  handleSetSubscriptionDealSteps,
} from "../../../store/layout/actions";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { getAllSubscriptionOrdersAction } from "../../../store/subscription/order/actions";
import { searchValue } from "../../../utils/setColor";
import { myCustomerActions } from "../../../store/customer";
import { mySubscritionActions } from "../../../store/subscription/order";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const SalesPages = (props: any) => {
  const navigate = useNavigate();
  const { auth, orders } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const handleRefresh = () => {
    if (auth?.token) {
      getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=finance-review&status=pick-list&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const columns: ColumnsType<any> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150,
    },
    {
      title: "Order Number",
      dataIndex: "customerNumber",
      width: 200,
    },
    {
      title: "Number of Devices",
      dataIndex: "deviceNumber",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
    {
      title: "Created Time",
      dataIndex: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 h-[26px] py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];

  const dataToDisplay = orders?.all?.data?.map((el: any) => {
    return {
      dataIndex: el._id,
      customerName: el?.account?.customerDetails?.name,
      customerNumber: el?.account?.customerDetails?.name,
      deviceNumber: el?.list?.length,
      createdBy: el?.createdBy?.names,
      date: el?.createdAt?.substring(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
    };
  });
  const [activeOrder, setActiveOrder] = useState("orders as grid");

  const handleIconClick = (iconType: string) => {
    setActiveOrder(iconType);
  };

  useEffect(() => {
    handleSetSubscriptionDealSteps(0)(dispatch);
    dispatch(myCustomerActions.setCustomerByPhonenNumber(null));
    handleFoundCustomerByPhone(null)(dispatch);
    handleNewCustomer(false)(dispatch);
    dispatch(mySubscritionActions.setNew(null));
  }, [dispatch]);

  return (
    <>
      <div className=" text-[#0F0F47] mt-6 flex items-center justify-between pl-4">
        <DetailsHeaderActionBar
          pageName="Sales"
          title="Quotation"
          isLoading={orders?.isFetching}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="flex flex-row items-center justify-between">
        {/* <SubscriptionOrdersFilter setSelectedStatus={setSelectedStatus} /> */}
      </div>
      <div className="text-black scrollbar-hide h-full w-full pb-2 ">
        {activeOrder === "orders as grid" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <div className={`flex flex-wrap gap-3  text-white`}>
              <>
                {orders?.isFetching ? (
                  <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
                    {Array.from({ length: 8 })?.map((el: any) => (
                      <CurstomeCardSkeleton />
                    ))}
                  </div>
                ) : (
                  <div
                    className={`w-full h-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
                  >
                    {orders?.all?.data?.map((item: any) => {
                      const qnty = item?.list?.reduce(
                        (curr: any, item: any) => curr + item?.quantity,
                        0
                      );
                      return (
                        <div
                          key={item?._id}
                          className={`${
                            item?._id === orders?.selected?._id
                              ? "bg-slate-200"
                              : "bg-white"
                          } rounded-lg  min-h-[156px] p-[16px] shadow relative`}
                          onClick={(event: any) => {
                            const isDisabledDiv = event.target.closest(
                              ".ant-dropdown-trigger"
                            );
                            const isDisabledlabel =
                              event.target.closest(".ant-dropdown-menu");
                            if (!isDisabledDiv && !isDisabledlabel) {
                              navigate(`/sales/quotion/${item?._id}`);
                            }
                          }}
                        >
                          <div className="flex flex-row  justify-between">
                            <div className="w-60 space-y-1">
                              {!item?.account?.customerDetails?.name ? (
                                <h2 className="text-lg leading-6  text-[#f21414] font-semibold font-poppins capitalize">
                                  Undefined
                                </h2>
                              ) : (
                                <h2 className="text-lg leading-6  text-[#0F0F47] font-semibold font-poppins capitalize">
                                  {item?.account?.customerDetails?.name ||
                                    "Undefined"}
                                </h2>
                              )}
                              <p className="text-[#605BFF] text-sm">
                                {item?.SOID}
                              </p>
                            </div>

                            <div className="absolute right-2 top-3 flex items-center">
                              <Tag
                                color={searchValue(item?.status)}
                                className="text-center px-4 py-0.5 capitalize"
                              >
                                {item?.status}
                              </Tag>
                            </div>
                          </div>
                          <p className="text-black pt-8">
                            Created By: {item?.createdBy?.names}
                          </p>
                          <div className=" mt-2 flex justify-between">
                            <div className="flex flex-col">
                              <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                                On: {item?.createdAt?.substring(0, 10)}
                                {" T "}
                                {item?.createdAt?.substring(11, 16)}
                              </p>
                            </div>
                            <div>
                              <p className="text-[#0F0F47] pl-3 pb-2 text-sm">
                                {`${item?.list?.length} Models / ${qnty} Qnty`}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            </div>
          </ScrollableFrame>
        )}
        {activeOrder === "orders as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <Table
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(`/sales/quotion/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={dataToDisplay}
              pagination={false}
              loading={orders?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>
    </>
  );
};

export default SalesPages;
