import React from "react";
import { Stack, Button, IconButton, Divider } from "@mui/material";
import { Modal, Collapse } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Delete from "@mui/icons-material/Delete";
import SettingButton from "../pages/dashboard/setting/SettingButton";
import { FaPlus } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
const { Panel } = Collapse;
const Component = (props: any) => {
  // const [isGridView, setIsGridView] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const route = location.pathname;
  const setting = route.includes("setting");
  const showModal = () => {
    !props.goForward && props?.setIsModalOpen(true);
    props.goForward && navigate(props.goForward);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  return (
    <>
      <div>
        <div className="flex items-center justify-between mt-6 sm:mt-0 w-full">
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={0}
          // divider={<Divider orientation="vertical" flexItem />}
          >
            <h1>{props?.title}</h1>
            {props?.filterCard}
          </Stack>

          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            divider={setting ? "" : <Divider orientation="vertical" flexItem />}
          >
            <div className="flex justify-center items-center ">
              {/* <IconButton
                  color="primary"
                  sx={{ p: 1 }}
                  aria-label="directions"
                  onClick={() => {
                    setIsGridView(!isGridView);
                    props.setIsGridView(!isGridView);
                  }}
                >
                  {isGridView ? <ViewKanbanIcon /> : <ViewKanbanOutlinedIcon />}
                </IconButton>
                <IconButton
                  color="primary"
                  sx={{ p: 1 }}
                  aria-label="directions"
                  onClick={() => {
                    setIsGridView(!isGridView);
                    props.setIsGridView(!isGridView);
                  }}
                >
                  {isGridView ? (
                    <ViewTimelineOutlinedIcon />
                  ) : (
                    <ViewTimelineIcon />
                  )}
                </IconButton> */}
              {props?.showDeleteButton && (
                <IconButton
                  color="primary"
                  sx={{ p: 1 }}
                  aria-label="directions"
                  onClick={props?.handleProductsToDelete}
                >
                  <Delete />
                </IconButton>
              )}
            </div>
            {!props?.isNotAddButton && (
              <>
                {setting ? <SettingButton
                  btnName="Add New user"
                  btnBgColor="[#605BFF]"
                  textColor="white"
                  icon={<FaPlus />}
                  onClick={showModal}

                /> : <Button
                  variant="contained"
                  startIcon={<HiPlus />}
                  onClick={showModal}
                  sx={{ minWidth: 250 }}
                >
                  Add New {props?.item}
                </Button>}

              </>
            )}
            {props?.isNotAddButton && props.OtherButton}
          </Stack>
        </div>
        {/* <Divider /> */}
        {/* {!props?.isNotCollapse && (
          <Collapse defaultActiveKey={["0"]} onChange={onChange}>
            <Panel header={props?.overview || "Summary Statistics"} key="1">
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"center"}
                spacing={4}
              >
                {props?.listSummary?.map((summary: any) => (
                  <Paper elevation={3}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <SummaryCard
                        title={summary.title}
                        value={summary.value}
                        bgColor={summary?.bgColor}
                      />
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Panel>
          </Collapse>
        )} */}
      </div>
      <Modal
        title={props?.modelTitle}
        open={props?.isModalOpen}
        onCancel={props?.handleCancel}
        footer={null}
        className="w-[100%] md:min-w-min"
      // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-full xl:w-[100vh]">{props?.ModelComponent}</div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
