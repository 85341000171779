import React, { useEffect } from "react";
import { Select, Table, Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import dayjs from "dayjs";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import SearchInput from "../../buttons/SearchButton";
import { getTransactionByCustomersReportAction } from "../../../store/report/actions";
import { myReportActions } from "../../../store/report";

const CustomerTransactionTable = (props: any) => {
  const { report, company, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const dataRow = report?.customerTransaction?.data?.map((el: any) => {
    return {
      amountPaid: el?.AmountPaid,
      amountToBePaid: el?.amountToBePaid,
      AmountRemain: el?.AmountRemain,
      customer: el?.customer?.name,
      duration: el?.duration,
      status: el?.status,
      dueDate: dayjs(el?.endingDate).format("Do MMM YYYY"),
      phone: el?.customer?.phone,
      date: dayjs(el?.createdDate).format("Do MMM YYYY"),
    };
  });

  const columns = [
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Issued At",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: `Amount to be paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amountToBePaid",
      key: "amountToBePaid",
    },
    {
      title: `Amount paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: `Amount remain (${getDefaultCurrencyCode(company)})`,
      dataIndex: "AmountRemain",
      key: "AmountRemain",
    },
    {
      title: "Duration (Months)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(text)}
          className="capitalize py-0.5 min-w-[60px] text-center"
        >
          {text}
        </Tag>
      ),
    },
  ];
  const onChange = (value: string) => {
    props?.setSelectedStatus(value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (auth?.token && [""]?.includes(report?.searchResult)) {
      getTransactionByCustomersReportAction(
        auth?.token,
        `?month1=${props?.startMonth}&month2=${props?.endMonth}${
          props?.status ? `&status=${props?.status}` : ""
        }&limit=${props?.limit}&page=0${
          props?.channelId ? `&channel=${props?.channelId}` : ""
        }`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.searchResult]);

  const handleSearch = () => {
    getTransactionByCustomersReportAction(
      auth?.token,
      `?month1=${props?.startMonth}&month2=${props?.endMonth}${
        props?.status ? `&status=${props?.status}` : ""
      }&limit=${props?.limit}&page=0${
        props?.channelId ? `&channel=${props?.channelId}` : ""
      }&sk=${report?.searchResult}`
    )(dispatch);
  };

  return (
    <div className="bg-white p-4 text-gray-900 xh-[67vh]">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4 pb-6 w-full">
          <h1 className="font-semibold">Payment History</h1>
          <Select
            showSearch
            placeholder="Filter by Status"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={filterOption}
            defaultValue={"All"}
            allowClear
            style={{ width: "10%" }}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "active",
                label: "Active",
              },
              {
                value: "closed",
                label: "Closed",
              },
            ]}
          />
        </div>
        <SearchInput
          placeholder={"By Customer's Names or Phone"}
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            dispatch(myReportActions.setSearchResult(e.target.value));
          }}
        />
      </div>
      <ScrollableFrame
        customSize={500}
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={report?.customerTransaction?.total}
        count={Math.ceil(report?.customerTransaction?.total / props?.limit)}
      >
        <Table
          dataSource={dataRow}
          columns={columns}
          loading={report?.transactionIsFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default CustomerTransactionTable;
