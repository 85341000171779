import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ProductFilter from "../../../../components/filters/ProductFiltering";
import HeaderComponent from "../../../../components/HeaderComponent";
import ProductGrid from "../../../../components/grids/warehouse/ProductGridStock";
import { Box, Skeleton } from "@mui/material";
import { getStockStatsAction } from "../../../../store/wareHouse/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { WarehouseHeaders } from "../../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { getStock_CSVAction } from "../../../../store/csvDownload/actions";
const WareHouseStock = () => {
  const { auth, wareHouse, layout, CSV, product } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
    const [type, setType] = useState("");
    const [brand, setBrand] = useState("");
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(15);

  // /warehouse/stockStats?type=Smartphone&brand=Apple&limit=100&page=0

  React.useEffect(() => {
    auth?.token &&
      getStockStatsAction(
        auth?.token,`?type=${type}&brand=${brand}&limit=${limit}&page=${page}`
      )(dispatch);
  }, [auth?.token, dispatch, type, brand,limit, page]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getStock_CSVAction(
          auth?.token,
          product?.query + `limit=${wareHouse?.allStockStats?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    model: record?.model || "N/A",
    brand: record?.brand || "N/A",
    type: record?.type || "N/A",
    inShop: record?.statuses
      .filter((status: any) => status.status === "out")
      .map((status: any) => status.count),
    inWarehouse: record?.statuses
      .filter((status: any) => status.status === "in")
      .map((status: any) => status.count),
    sold: record?.statuses
      .filter((status: any) => status.status === "sold")
      .map((status: any) => status.count),
  }));

  //TODO: Filter doen't work due to API, Work on it later

  return (
    <div className="text-[#030229] space-y-5">
      <div className="text-[#030229] pt-2 ">
        <DetailsHeaderActionBar pageName="Warehouse" title="Stock" />
      </div>
      <div className="flex items-center justify-between mr-24">
        <HeaderComponent
          isNotCollapse={true}
          isNotAddButton={true}
          filterCard={<ProductFilter setBrand={setBrand} setType={setType} type={type} brand={brand} />}
        />
        <div className="flex justify-end gap-4 p-4">
          <CSVExportButton
            csvHeaders={WarehouseHeaders}
            csvData={csvData ? csvData : ""}
            filename={`Warehouse/stock.csv`}
          />
        </div>
      </div>
      {wareHouse?.isFetching && (
        <div className="flex flex-wrap gap-4 justify-start items-start">
          {Array.from({ length: 9 }).map((d: any) => (
            <Box sx={{ width: 330 }}>
              <Skeleton animation="wave" />
              <Skeleton variant="rectangular" height={150} />
            </Box>
          ))}
        </div>
      )}
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={product?.all?.total}
        count={Math.ceil(product?.all?.total / limit)}
      >
        {!wareHouse?.isFetching && (
          <>
            <div className="font-400 text-[14px] text-[#03022960] mb-1">
              Available Products: {wareHouse?.allStockStats?.total}
            </div>
            <div className="flex flex-wrap gap-4 justify-start items-start min-h-[60vh]">
              {wareHouse?.allStockStats?.status === 200 && (
                <ProductGrid
                  // handleSelected={handleSelected}
                  data={wareHouse?.allStockStats?.data}
                />
              )}
            </div>
          </>
        )}
      </ScrollableFrame>
    </div>
  );
};
export default WareHouseStock;
