import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Tag, Drawer } from "antd";
import DetailsHeaderActionBar from "../../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getAllAccountInvoiceAction } from "../../../../../store/invoice/actions";
import Late from "../../../../../assets/images/subscription/late.png";
import Pending from "../../../../../assets/images/subscription/pending.png";
import Dollar from "../../../../../assets/images/subscription/dollar.png";
import Cards from "../../overview/Cards";
import { searchValue } from "../../../../../utils/setColor";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Invoice from "../../Accounts/Invoice";
import { mySubscritionActions } from "../../../../../store/subscription/deliverynote";
import { handleClearAllState } from "../../../../../utils/converter";
import CurstomeCardSkeleton from "../../../../../components/skeleton/CurstomeCardSkeleton";

const InvoiceOverview = (props: any) => {
  const [openInvoiceView, setOpenInvoiceView] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState("");
  const { auth, invoice } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const dispatch = useDispatch();
  const clearActions = [mySubscritionActions.setInvoiceResult];

  const pendingInvoices = invoice?.all?.data?.filter(
    (item: any) => item.status === "pending"
  );
  const paidInvoices = invoice?.all?.data?.filter(
    (item: any) => item.status === "paid"
  );
  const lateInvoices = invoice?.all?.data?.filter(
    (item: any) => item.status === "late"
  );
  const pendingCount = pendingInvoices?.length;
  const paidCount = paidInvoices?.length;
  const lateCount = lateInvoices?.length;

  const latestObjects = invoice?.all?.data.slice(0, 5);
  useEffect(() => {
    auth?.token &&
      getAllAccountInvoiceAction(auth?.token, `?status=pending`)(dispatch);
  }, [auth.token, dispatch]);

  const onOpenViewModel = (value: any) => {
    setDataToDisplay(value);
    setOpenInvoiceView(true);
  };
  const onCancelViewModel = () => {
    setDataToDisplay("");
    setOpenInvoiceView(false);
    handleClearAllState(dispatch, clearActions);
  };

  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  return (
    <>
      <div className=" text-[#0F0F47] my-4">
        <div className="flex flex-wrap pl-3 justify-between pt-4">
          <DetailsHeaderActionBar
            pageName="Subscription"
            title={`${"overview"}`}
          />
          <div className="flex flex-row items-center">
            <DatePicker className={"w-48"} />
          </div>
        </div>
      </div>
      <div className="text-black scrollbar-hide h-full w-full  pb-2 ">
        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4 text-black">
          <Cards cardIcon={Dollar} value={paidCount} status="Paid" />
          <Cards cardIcon={Pending} value={pendingCount} status="pending" />
          <Cards cardIcon={Late} value={lateCount} status="Late" />
        </div>
      </div>
      <div className="text-black scrollbar-hide h-full w-full  pb-2 ">
        <div className="text-[#0F0F47] text-lg font-medium font-poppins mt-5 mb-2">
          Recenty Invoices
        </div>
        {invoice?.isFetching ? (
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
            {Array.from({ length: 8 })?.map((el: any) => (
              <CurstomeCardSkeleton />
            ))}
          </div>
        ) : (
          <div
            className={`w-full h-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            {latestObjects?.map((item: any) => (
              <div
                key={item?._id}
                className="bg-white rounded-lg min-h-[200px] p-[14px] shadow-md border border-gray-300 relative"
              >
                <div className="flex flex-row justify-between">
                  <div>
                    <h1 className="text-[#0F0F47] font-poppins mb-2 text-lg">
                      {item?.account?.customerDetails?.name}
                    </h1>
                    <div className="text-[#0F0F47]">
                      {item?.account?.customerDetails?.tel}
                    </div>
                    <p className="text-[#0F0F47]">{item.transaction}</p>
                  </div>

                  <Tag
                    color={searchValue(item?.status)}
                    className="rounded-md text-white  h-[26px] w-18 py-1 capitalize"
                  >
                    {item?.status}
                  </Tag>
                </div>
                <div className="flex flex-row justify-between mt-2 mb-1">
                  <p className="text-sm text-[#0F0F47] font-poppins">
                    {dayjs(item.createdAt).format("YYYY-MM-DD ")}
                  </p>
                  <p className="text-sm text-[#0F0F47] font-poppins">
                    {item.qid}
                  </p>
                </div>
                <div className="absolute my-1">
                  <Link to={`/subscription/account/${item?.account?._id}`}>
                    <OpenInNewIcon fontSize="small" style={{ color: "gray" }} />
                  </Link>
                  <span
                    onClick={() => onOpenViewModel(item)}
                    className="cursor-pointer"
                  >
                    <RemoveRedEye
                      fontSize="medium"
                      className="h-2 ml-2"
                      style={{ color: "gray" }}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Drawer
        placement="right"
        width={800}
        onClose={onCancelViewModel}
        open={openInvoiceView}
      >
        <Invoice
          dataToDisplay={dataToDisplay}
          openInvoiceView={openInvoiceView}
          fromBilling={true}
        />
      </Drawer>
    </>
  );
};

export default InvoiceOverview;
