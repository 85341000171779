import { Badge, Divider, Space } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { searchValue } from "../../../utils/setColor";
import { useNavigate } from "react-router-dom";
import defaultPic from "../../../assets/nophoto.png";
import { ReactComponent as PeopleSvg } from "../../../assets/icons/people.svg";
import { ReactComponent as NoteSvg } from "../../../assets/icons/stickynote.svg";
import { ReactComponent as EditSvg } from "../../../assets/edit.svg";
import { ReactComponent as TrashSvg } from "../../../assets/trash.svg";
import { accountCard } from "../../../assets/data/types";

const AccountCard: React.FC<accountCard> = ({
  openDeleteModel,
  openUpdateModel,
}) => {
  const { auth, account } = useSelector((state: any) => state);
  const navigate = useNavigate();
  return (
    <div
      className={`w-full h-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
    >
      {account?.all?.data?.map((items: any) => (
        <Badge.Ribbon
          key={items?._id}
          text={
            <span className="text-[12px] xw-full flex justify-center items-center font-normal pt-[1.5px] capitalize">
              {items?.status}
            </span>
          }
          color={searchValue(items?.status)}
          style={{ padding: "4px 10px" }}
        >
          <div className="min-h-[195px] bg-white rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 relative">
            <div
              onClick={(event: any) => {
                const isDisabledDiv = event.target.closest(
                  ".ant-dropdown-trigger"
                );
                const isDisabledlabel =
                  event.target.closest(".ant-dropdown-menu");
                if (!isDisabledDiv && !isDisabledlabel) {
                  navigate(`/subscription/account/${items?._id}`);
                }
              }}
            >
              <div className="flex gap-3">
                <div>
                  {items?.productPlan ? (
                    <img
                      src={items?.productPlan?.pricelistItem?.channel?.logo}
                      className="w-16 h-14 rounded"
                      alt="Product Plan"
                    />
                  ) : (
                    <img
                      src={defaultPic}
                      className="w-16 h-14 rounded object-cover"
                      alt="Default"
                    />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className=" w-[14rem]">
                    {items?.customer?.name ||
                      items?.customerDetails?.name?.toUpperCase()}
                  </h1>
                  <p className="text-[#605BFF] text-[12px] font-normal">
                    {items?.accountID}
                  </p>
                </div>
              </div>

              <div className="flex justify-between my-3 mt-4 font-medium">
                <p className="text-[13px] font-normal">
                  {items?.createdAt?.slice(0, 10)}
                </p>
                <p className="text-[13px] font-normal">
                  {items?.deliveryNote?.list?.length || 0} Devices
                </p>
              </div>

              <Divider />
              <div className="flex justify-between items-center mt-5 font-medium">
                <div className="flex gap-3">
                  <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                    <Space>
                      <PeopleSvg fontSize={15} />
                      {items?.customer?.subscriptionInfo?.witnessInfo?.length ||
                        0}
                    </Space>
                  </div>
                  <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                    <Space>
                      <NoteSvg fontSize={15} />
                      {items?.notes?.length || 0}
                    </Space>
                  </div>
                  <div
                    className={`w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center uppercase ${
                      items?.type === "b2b"
                        ? "bg-blue-500 text-white"
                        : items?.type === "b2c"
                        ? "bg-green-500 text-white"
                        : "bg-gray-200 text-black"
                    }`}
                  >
                    {items?.type || 0}
                  </div>
                </div>
                {!["sales-supervisor", "sales-manager"]?.includes(
                  auth?.user?.role
                ) && (
                  <div className="flex gap-2">
                    <EditSvg onClick={() => openUpdateModel(items)} />
                    <TrashSvg onClick={() => openDeleteModel(items?._id)} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      ))}
    </div>
  );
};

export default AccountCard;
