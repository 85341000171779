import { useDispatch, useSelector } from "react-redux";
import { Table, TableProps, Tag, Tooltip } from "antd";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { searchValue } from "../../../../utils/setColor";
import { ReactComponent as TrashSvg } from "../../../../assets/trash.svg";
import { useState } from "react";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { deleteOneSubTransactionAction, getAllSubsTransactionAction } from "../../../../store/invoice/actions";
import { useParams } from "react-router-dom";

interface DataType {
  key: string;
  date: any;
  remarks: any;
  amount: number;
  referenceId: string;
  transactionId: string;
  paymentAtt: any;
  status: any;
}

const SubTransactionsTable = (props: any) => {
  const { auth, invoice, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>("");
  const { accountId } = useParams();

  const deleteTransaction = async () => {
    await deleteOneSubTransactionAction(auth?.token, deleteData, {
      isDeleted: true,
    })(dispatch);
    await getAllSubsTransactionAction(auth?.token, `?account=${accountId}`)(dispatch);
    onCancelModel();
  };

  const openModel = (value: any) => {
    setDeleteData(value);
    setIsModalOpen(true);
  };
  const onCancelModel = () => {
    setDeleteData("");
    setIsModalOpen(false);
  };
  const isEnabled = ["dev", 'finance-manager']?.includes(auth?.user?.role);
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Transaction Type",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: `Amount Paid (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text) => <p className="font-medium">{text?.toLocaleString()}</p>,
    },
    {
      title: "Transaction Id",
      dataIndex: "referenceId",
      key: "referenceId",
      render: (text, record) => {
        return (
          <p className="font-medium">
            {record?.paymentAtt?.length !== 0
              ? record?.transactionId
              : text}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <Tag
            color={searchValue(text)}
            className="capitalize min-w-[50px] text-center"
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "data",
      key: "data",
      render: (record: any) => {
        return (
          <Tooltip placement="bottom" title={'delete'}>
            <TrashSvg onClick={(e) => isEnabled && openModel(record?._id)} className={isEnabled ? "cursor-pointer" : "cursor-not-allowed opacity-50"} />
          </Tooltip>
        )

      }
    },
  ];

  const dataToDispaly: DataType[] = invoice?.transactions?.data?.map(
    (el: any) => {
      return {
        key: el?._id,
        remarks: el?.remarks,
        date: el?.createdAt?.slice(0, 10),
        amount: el?.amount,
        referenceId: el?.referenceId,
        transactionId: el?.transactionId,
        status: el?.status,
        paymentAtt: el?.paymentAttempts,
        data: el,
      };
    }
  );
  return (
    <div className="w-full h-full text-[#030229] bg-white mt-10">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={invoice?.transactions?.total}
        count={Math.ceil(invoice?.transactions?.total / props?.limit)}
      >
        <Table<DataType>
          columns={columns}
          dataSource={dataToDispaly}
          pagination={false}
        />
      </ScrollableFrame>
      <DeleteModal
        visible={isModalOpen}
        onOk={deleteTransaction}
        onCancel={onCancelModel}
        itemName={"Transaction"}
        isLoading={invoice?.isFetching}
      />
    </div>
  );
};
export default SubTransactionsTable;
