import { LoadingButton } from "@mui/lab";
import { DatePicker, DatePickerProps, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  approveInvoicePaymentAction,
  getAllAccountInvoiceAction,
  getAllSubsTransactionAction,
} from "../../store/invoice/actions";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";

const ConfirmSubInvoiceForm: React.FC<{
  itemId: string;
  onCancel: any;
  amount?: any;
  updateAmount?: boolean;
}> = ({ itemId, onCancel, amount, updateAmount }) => {
  const { auth, invoice, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const formattedDate = updateAmount
    ? amount?.payment?.date?.slice(0, 10)
    : new Date().toISOString().slice(0, 10);

  const [paidDate, setPaidDate] = useState(formattedDate);
  const dateFormat = "YYYY-MM-DD";
  const [form] = Form.useForm();

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setPaidDate(dateString);
  };

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      date: paidDate,
    };

    if (auth?.token && itemId) {
      await approveInvoicePaymentAction(itemId, auth?.token, payload)(dispatch);
      onCancel();
      await getAllAccountInvoiceAction(
        auth?.token,
        `?account=${accountId}`
      )(dispatch);
      await getAllSubsTransactionAction(
        auth?.token,
        `?account=${accountId}`
      )(dispatch);
    }
    form.resetFields();
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    form.setFieldsValue({
      amountPaid: amount?.payment?.amount,
      momoTransactionId: amount?.payment?.momoTransactionId,
      comments: amount?.comments,
    });
  }, [amount]);

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        layout="vertical"
      >
        <Form.Item
          name="amountPaid"
          label="Amount Paid"
          rules={[
            {
              required: updateAmount ? false : true,
              message: "Amount is required!",
            },
          ]}
        >
          <Input type="number" className="w-80" />
        </Form.Item>

        <Form.Item
          name="momoTransactionId"
          label="Transaction Id"
          rules={[
            {
              required: updateAmount ? false : true,
              message: "Transaction Id is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="w-80" />
        </Form.Item>
        <Form.Item name="paid date" label="Date">
          <DatePicker
            className="w-80"
            defaultValue={dayjs(formattedDate, dateFormat)}
            onChange={onChangeDate}
            allowClear={false}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item name="comments" label="Comments">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={account?.isFetching || invoice?.isFetching}
          >
            {updateAmount ? "Update" : "Submit"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ConfirmSubInvoiceForm;
