import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TbGridDots } from "react-icons/tb";
import { AiOutlineCloudUpload, AiOutlineUnorderedList } from "react-icons/ai";
import { mySubscritionActions } from "../../../../store/subscription/order";
import SubscriptionOrdersFilter from "../../../../components/filters/SubscriptionOrdersFilter";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CreateOrder from "./Createorder";
import { Table } from "antd";
import {
  createOrderAction,
  deleteSubsorderAction,
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import type { ColumnsType } from "antd/es/table";
import ThreeDotDropdown from "../Accounts/accountactions/threeDotDropdown";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
  handlerUpdateAccountAction,
  handleSetSubscriptionDealSteps,
} from "../../../../store/layout/actions";
import { myCustomerActions } from "../../../../store/customer";
import { LoadingButton } from "@mui/lab";
import GeneralCustomFilter from "../../../../components/filters/generalCustomFilter";

const OrdersCard = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState("");
  const { auth, orders } = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDrawer = async () => {
    await dispatch(mySubscritionActions.setSelected(null));
    handleFoundCustomerByPhone(null)(dispatch);
    handleNewCustomer(false)(dispatch);
    dispatch(myCustomerActions.setCustomerByPhonenNumber(null));
    const res = await createOrderAction(auth?.token, {})(dispatch);
    // setOpen(true);
    if (res?.type) {
      navigate(`/subscription/order/new/${res?.data?._id}`);
      await getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=created&status=pending&status=draft&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  const onClose = async () => {
    setOpen(false);
    setOrderId("");
    dispatch(mySubscritionActions.setSelected(null));
    handlerUpdateAccountAction(false)(dispatch);
    handleSetSubscriptionDealSteps(0)(dispatch);
  };

  const handleRefresh = () => {
    if (selectedStatus) {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=${selectedStatus}&limit=${limit}&page=${page - 1}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=finance-review&status=created&status=pending&status=draft&limit=${limit}&page=${
            page - 1
          }`
        )(dispatch);
    }
  };

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.token, dispatch, limit, page, selectedStatus]);

  useEffect(() => {
    orderId &&
      auth?.token &&
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
  }, [auth.token, dispatch, orderId]);

  const columns: ColumnsType<any> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150,
    },
    {
      title: "Order Number",
      dataIndex: "customerNumber",
      width: 200,
    },
    {
      title: "Number of Devices",
      dataIndex: "deviceNumber",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
    {
      title: "Created Time",
      dataIndex: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md w-18 h-[26px] py-[2px] px-6"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];

  const dataToDisplay = orders?.all?.data?.map((el: any) => {
    return {
      dataIndex: el._id,
      customerName: el?.account?.customerDetails?.name,
      customerNumber: el?.account?.customerDetails?.name,
      deviceNumber: el?.list?.length,
      createdBy: el?.createdBy?.names,
      date: el?.createdAt?.substring(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
    };
  });
  const [activeOrder, setActiveOrder] = useState("orders as grid");

  const handleIconClick = (iconType: string) => {
    setActiveOrder(iconType);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const handleUpdate = (value: any) => {
    navigate(`/subscription/order/new/${value}`);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  const deleteOrder = async () => {
    if (auth?.token) {
      await deleteSubsorderAction(auth?.token, deleteDataId)(dispatch);
      onCancelDeleteModel();
      await getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=created&status=pending&status=draft&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  useEffect(() => {
    handleSetSubscriptionDealSteps(0)(dispatch);
    dispatch(myCustomerActions.setCustomerByPhonenNumber(null));
    handleFoundCustomerByPhone(null)(dispatch);
    handleNewCustomer(false)(dispatch);
    dispatch(mySubscritionActions.setNew(null));
    dispatch(myCustomerActions.setNew(null));
  }, [dispatch]);

  const resetFields = () => {
    setSelectedStatus("");
  };

  return (
    <>
      <div className=" text-[#0F0F47] mt-6 flex md:flex-row flex-col md:items-center justify-between mr-4">
        <DetailsHeaderActionBar
          pageName="Subscription"
          title="Deals"
          isLoading={orders?.isFetching}
          handleRefresh={handleRefresh}
        />
      </div>
      <div className="flex md:flex-row flex-col-reverse md:items-center justify-between mt-5 pl-2">
        <GeneralCustomFilter
          handleResetFields={resetFields}
          component={
            <SubscriptionOrdersFilter setSelectedStatus={setSelectedStatus} />
          }
        />
        <div className="flex gap-x-5 justify-between">
          <div className="flex pb-4">
            <div className="flex flex-row items-center gap-4 text-black pb-2">
              <div className="hidden md:block">
                <LoadingButton
                  disabled
                  variant="outlined"
                  className="py-[8px] px-[16px] h-12"
                >
                  <AiOutlineCloudUpload size={25} />
                  <span className="font-semibold pl-1">upload</span>
                </LoadingButton>
              </div>
              <Button
                className="bg-[#2943D6]  text-white p-[23px] "
                onClick={showDrawer}
              >
                <PlusOutlined />
                <span className="font-semibold">Create Deal</span>
              </Button>
            </div>
          </div>
          <div className="flex  px-3 py-1.5 rounded-md gap-x-2">
            <AiOutlineUnorderedList
              size={33}
              onClick={() => handleIconClick("orders as table")}
              className={`cursor-pointer ${
                activeOrder === "orders as table"
                  ? "fill-white bg-blue-500 px-0.5 rounded"
                  : "fill-gray-500 px-0.5"
              }`}
            />
            <TbGridDots
              style={{
                color: activeOrder === "orders as grid" ? "#fff" : "gray",
                cursor: "pointer",
              }}
              size={30}
              onClick={() => handleIconClick("orders as grid")}
              className={`cursor-pointer ${
                activeOrder === "orders as grid"
                  ? "fill-white bg-blue-500 px-0.5 rounded"
                  : "fill-gray-500 px-0.5"
              }`}
            />
          </div>
        </div>
      </div>
      <div className="text-black scrollbar-hide h-full w-full pb-2 ">
        {activeOrder === "orders as grid" && (
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <div className={`flex flex-wrap gap-3  text-white`}>
              <>
                {orders?.isFetching ? (
                  <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
                    {Array.from({ length: 8 })?.map((el: any) => (
                      <CurstomeCardSkeleton />
                    ))}
                  </div>
                ) : (
                  <div
                    className={`w-full h-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
                  >
                    {orders?.all?.data?.map((item: any) => {
                      const Qnty = item?.list?.reduce(
                        (curr: any, item: any) => curr + item?.quantity,
                        0
                      );

                      return (
                        <div
                          key={item?._id}
                          className={`${
                            item?._id === orders?.selected?._id
                              ? "bg-slate-200"
                              : "bg-white"
                          } rounded-lg min-h-[156px] p-[16px] shadow relative`}
                          onClick={(event: any) => {
                            const isDisabledDiv = event.target.closest(
                              ".ant-dropdown-trigger"
                            );
                            const isDisabledlabel =
                              event.target.closest(".ant-dropdown-menu");
                            if (!isDisabledDiv && !isDisabledlabel) {
                              if (item?.status === "draft") {
                                navigate(
                                  `/subscription/order/new/${item?._id}`
                                );
                              } else {
                                navigate(`/subscription/order/${item?._id}`);
                              }
                            }
                          }}
                        >
                          <div className="flex flex-row  justify-between">
                            <div className="w-60 space-y-1">
                              {!item?.account?.customerDetails?.name ? (
                                <h1 className="leading-6  text-[#f21414] font-poppins capitalize">
                                  Undefined
                                </h1>
                              ) : (
                                <h1 className="leading-6  text-[#0F0F47]  font-poppins capitalize">
                                  {item?.account?.customerDetails?.name ||
                                    "Undefined"}
                                </h1>
                              )}
                              <p className="text-[#605BFF] text-sm">
                                {item?.SOID}
                              </p>
                            </div>

                            <div className="absolute right-2 top-3 flex items-center">
                              <Tag
                                color={searchValue(item?.status)}
                                className="text-center px-4 py-1 capitalize"
                              >
                                {item?.status}
                              </Tag>
                              <ThreeDotDropdown
                                onDelete={() => openDeleteModel(item?._id)}
                                onUpdate={() => handleUpdate(item?._id)}
                                status={item?.status}
                              />
                            </div>
                          </div>
                          <p className="text-black pt-8">
                            Created By: {item?.createdBy?.names}
                          </p>
                          <div className=" mt-2 flex justify-between">
                            <div className="flex flex-col">
                              <p className="text-sm text-[#0F0F47] font-poppins mb-2">
                                On: {item?.createdAt?.substring(0, 10)}
                                {" T "}
                                {item?.createdAt?.substring(11, 16)}
                              </p>
                            </div>
                            <div>
                              <p className="text-[#0F0F47] pl-3 pb-2 text-sm">
                                {`${item?.list?.length} Model(s) / ${Qnty} Qnty`}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            </div>
          </ScrollableFrame>
        )}
        {activeOrder === "orders as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={orders?.all?.total}
            count={Math.ceil(orders?.all?.total / limit)}
          >
            <Table
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (event) => {
                    record?.status === "draft"
                      ? navigate(`/subscription/order/new/${record?._id}`)
                      : navigate(`/subscription/order/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={dataToDisplay}
              pagination={false}
              loading={orders?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>
      <CreateOrder onClose={onClose} open={open} setOpen={setOpen} />
      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteOrder}
        onCancel={onCancelDeleteModel}
        itemName={"Order"}
        isLoading={orders?.isFetching}
      />
    </>
  );
};

export default OrdersCard;
