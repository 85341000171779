import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const { Option } = Select;

const AccountFilter = (props: any) => {
  const { channel } = useSelector((state: any) => state);
  const handleChannelChange = (value: string) => {
    if (!value) {
      props?.setSelectedChannel("");
    } else {
      props?.setSelectedChannel(value);
    }
  };
  return (
    <div className="flex flex-col sm:flex-row gap-2">
      <Select
        size="large"
        showSearch
        style={{ width: 200, fontSize: "4px" }}
        allowClear
        placeholder="Filter by status"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "")
            ?.toString()
            ?.toLowerCase()
            ?.includes(input.toLowerCase())
        }
        onClear={() => props?.setSelectedStatus("")}
        onChange={(value) => props?.setSelectedStatus(value || "")}
      >
        {["active", "closed", "inactive"].map((status) => (
          <Option key={status} value={status} label={status}>
            {status}
          </Option>
        ))}
      </Select>
      <Select
        size="large"
        showSearch
        style={{ width: 200, fontSize: "4px" }}
        allowClear
        placeholder="Filter by Channel"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "")
            ?.toString()
            ?.toLowerCase()
            ?.includes(input.toLowerCase())
        }
        loading={channel.isFetching}
        disabled={channel.isFetching}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "")?.toString()?.toLowerCase())
        }
        onChange={handleChannelChange}
      >
        {channel?.all?.data?.map((data: any) => (
          <Option key={data?._id} value={data?._id} label={data?.name}>
            {data?.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default AccountFilter;
