import React, { useState, useEffect } from "react";
import { Stack, Button, useMediaQuery } from "@mui/material";
import {
  Select,
  Table,
  Typography,
  InputNumber,
  Modal,
  Tag,
  Cascader,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import NewStockIn from "../../components/forms/NewStockIiTest";
import {
  updateOneDeliveryItemAction,
  updateOneDeliveryNoteAction,
} from "../../store/wareHouse/actions";
import { getAllEbmItemsAction } from "../../store/product/actions";
import Warehouse from "@mui/icons-material/Warehouse";
import { searchValue } from "../../utils/setColor";
// import { countries } from "countries-list";
import { countries } from "countries-list";

interface OptionCascade {
  value: string;
  label: string;
  children?: OptionCascade[];
}

const { Option } = Select;
function groupArrayByName(arr: any[]) {
  const groupedArray: any[] = [];

  arr?.forEach((obj) => {
    const existingGroup = groupedArray.find(
      (group) => group.value === obj.name
    );

    if (existingGroup) {
      // Add the country to the existing group's children
      existingGroup.children.push({ value: obj.itemCd, label: obj.country });
    } else {
      // Create a new group with the name, and add the country as a child
      groupedArray.push({
        value: obj.name,
        label: obj.name,
        children: [{ value: obj.itemCd, label: obj.country }],
      });
    }
  });

  return groupedArray;
}

// function groupArrayByName(arr: any[], specifiedValue: string) {
//   const groupedArray: any[] = [];
//   const includeAll = !specifiedValue;

//   arr.forEach((obj) => {
//     if (includeAll || obj.name === specifiedValue) {
//       const existingGroup = groupedArray.find(
//         (group) => group.value === obj.name
//       );

//       if (existingGroup) {
//         existingGroup.children.push({ value: obj.itemCd, label: obj.country });
//       } else {
//         groupedArray.push({
//           value: obj.name,
//           label: obj.name,
//           children: [{ value: obj.itemCd, label: obj.country }],
//         });
//       }
//     }
//   });

//   return groupedArray;
// }

function createName(priceListItem: any) {
  let name = `${priceListItem?.product?.brand} ${priceListItem?.product?.model}`;
  const capacity = priceListItem?.specification?.find((spec: any) =>
    ["capacity (rom)", "rom", "capacity"].includes(spec[0].toLowerCase())
  );
  const memory = priceListItem?.specification?.find((spec: any) =>
    ["memory (ram)", "ram", "memory"].includes(spec[0].toLowerCase())
  );

  console.log(capacity, memory);

  if (capacity && memory) {
    name += ` (${memory[1]}, ${capacity[1]})`;
  } else if (capacity) {
    name += ` (${capacity[1]})`;
  } else if (memory) {
    name += ` (${memory[1]})`;
  }

  console.log(name);
  return name;
}
function findGroupByValue(arr: any, targetValue: any) {
  const matchingGroup = arr?.find(
    (group: any) =>
      group.value.toLowerCase().trim() === targetValue.toLowerCase().trim()
  );
  return matchingGroup ? [matchingGroup] : arr;
}
type CountryCode = keyof typeof countries;
interface Country {
  code: CountryCode;
  name: string;
}
const DeliveryNoteTable = (props: any) => {
  const { wareHouse, auth, product } = useSelector((state: any) => state);
  const [value, setValue] = useState("");
  const [optionCascade, setOptionCascade] = useState<OptionCascade[]>();
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeliveryItem, setSelectedDeliveryItem] = useState<any>();
  const [files, setFiles] = useState<any>(null);
  const deliveryNote = wareHouse?.selectedDeliveryNote?.data;
  const isArrived = deliveryNote?.status === "Arrived";
  const isDeliveryNoteComplete = [
    "Verified-Complete",
    "Verified-Incomplete",
  ].includes(deliveryNote?.status);

  const handleCancel = () => {
    setIsModalOpen(false);
    setFiles(null);
  };

  const handleModalOpen = (el: any) => {
    setIsModalOpen(true);
    setValue(el);
  };

  const handleEbmItems = async (id: string) => {
    if (auth?.token) {
      await getAllEbmItemsAction(auth?.token, `?product=${id}`)(dispatch);
    }
  };
  useEffect(() => {
    product?.ebmitem?.length > 0 &&
      setOptionCascade(
        findGroupByValue(
          groupArrayByName(product?.ebmitem[0]?.ebmItemsCodesCountry),
          createName(selectedDeliveryItem)
        )
      );
  }, [product?.ebmitem]);

  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string, record: any) =>
        record.description.map(
          (item: any) =>
            item[1] && (
              <div className="flex items-center gap-2">
                <span className="font-bold text-xs">{item[0]}: </span>
                <span className=" text-sm">{item[1]}</span>
              </div>
            )
        ),
    },
    {
      title: "Validation Status",
      dataIndex: "itemStatus",
      key: "itemStatus",
      render: (text: string, record: any) => (
        <Tag color={searchValue(record.itemStatus)}>{record.itemStatus}</Tag>
      ),
    },
    {
      title: "Origin Country /EBM Item Code",
      key: "origin",
      dataIndex: "origin",
      render: (text: string, record: any) => {
        const onChange = async (value: any) => {
          // console.log(record, "++++++++++++++++++++++++++", value);
          setSelectedDeliveryItem(record?.data?.purchaseItem?.quotationItem);
          auth?.token &&
            (await updateOneDeliveryItemAction(
              auth?.token,
              record?._id,
              deliveryNote?._id,
              {
                ebmItemCode: value,
              }
            )(dispatch));
        };

        // Just show the latest item.
        const displayRender = (labels: string[]) => labels[labels.length - 1];

        return (
          <Stack direction={"column"} spacing={2}>
            {record?.data?.ebmItemCode && (
              <Tag color={"cyan"} className="text-center">
                {record?.data?.ebmItemCode}
              </Tag>
            )}
            {/* {!wareHouse?.selectedDeliveryNote?.data?.ebmRegistered && (
              <Cascader
                options={optionCascade}
                expandTrigger="hover"
                displayRender={displayRender}
                onChange={onChange}
                onClick={async () => {
                  setSelectedDeliveryItem(
                    record?.data?.purchaseItem?.quotationItem
                  );
                  await handleEbmItems(
                    record?.data?.purchaseItem?.quotationItem?.product?._id
                  );
                }}
              />
            )} */}
            <Select
              onChange={onChange}
              onClick={async () => {
                setSelectedDeliveryItem(
                  record?.data?.purchaseItem?.quotationItem
                );
                await handleEbmItems(
                  record?.data?.purchaseItem?.quotationItem?.product?._id
                );
              }}
            >
              {product?.ebmitem?.map((d: any) => (
                <Select.Option value={d?.itemCd}>{d?.country}</Select.Option>
              ))}
            </Select>
          </Stack>
        );
      },
    },
    {
      title: "QTY",
      key: "quantity",
      dataIndex: "quantity",
    },
    {
      title: "QTY Stored",
      key: "qtyStored",
      dataIndex: "qtyStored",
    },
    {
      title: "QTY Arrived",
      dataIndex: "qtyReceived",
      key: "qtyReceived",
      fixed: window.innerWidth < 1000 ? "right" : undefined,
      render: (text: string, record: any) => (
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {record.quantity !== record.qtyStored && (
            <InputNumber
              min={0}
              disabled={
                // !isArrived ||
                !record?.quantity ||
                wareHouse?.isFetching ||
                record?.itemStatus === "Complete"
              }
              defaultValue={parseInt(text)}
              onChange={(value: any) => {
                record.qtyReceived = value;
              }}
              onBlur={(value: any) => {
                const payload =
                  record?.qtyReceived === record?.quantity
                    ? {
                        qtyReceived: record?.qtyReceived,
                        status: "Complete",
                      }
                    : {
                        qtyReceived: record?.qtyReceived,
                        status: "Incomplete",
                      };
                auth?.token &&
                  updateOneDeliveryItemAction(
                    auth?.token,
                    record?._id,
                    deliveryNote?._id,
                    payload
                  )(dispatch);
              }}
            />
          )}
          {record.quantity === record.qtyStored && <p>{record.qtyReceived}</p>}
          {isDeliveryNoteComplete &&
            record?.qtyReceived === record?.quantity && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // console.log(record);
                  handleModalOpen(record);
                }}
                disabled={
                  record.quantity === record.qtyStored ||
                  record.quantity <= record.qtyStored
                }
              >
                Stock In
              </Button>
            )}
        </Stack>
      ),
    },
  ];

  const data: {
    _id: any;
    type: any;
    brand: any;
    model: any;
    description: any;
    quantity: any;
    qtyReceived: any;
    qtyStored: any;
    token: string;
    quotationItemId: any;
    deliveryNoteId: any;
    dispatch: any;
    status: any;
    itemStatus: any;
    data?: any;
  }[] = [];
  deliveryNote &&
    deliveryNote?.listOfItems?.map((el: any) => {
      data.push({
        _id: el?._id,
        status: deliveryNote?.status,
        itemStatus: el?.status,
        type: el?.purchaseItem?.quotationItem?.product?.type,
        brand: el?.purchaseItem?.quotationItem?.product?.brand,
        description: el?.purchaseItem?.quotationItem?.specification,
        model: el?.purchaseItem?.quotationItem?.product?.model,
        quantity: el?.qtySent,
        qtyReceived: el?.qtyReceived || 0,
        qtyStored: el?.qtyStored,
        token: auth?.token,
        quotationItemId: el?.purchaseItem?.quotationItem?._id,
        deliveryNoteId: deliveryNote?._id,
        data: el,
        dispatch: dispatch,
      });
    });

  useEffect(() => {
    !isModalOpen && setFiles(null);
  }, [isModalOpen]);

  return (
    <>
      <div>
        <Table
          columns={columns}
          dataSource={data}
          style={{ width: "100%" }}
          pagination={false}
          scroll={{ x: "max-content" }}

        />
      </div>
      <Modal
        title=""
        open={isModalOpen}
        maskClosable={false}
        footer={null}
        onCancel={handleCancel}
        width={700}
      >
        <NewStockIn
          onCancel={handleCancel}
          data={value}
          setFiles={setFiles}
          files={files}
        />
      </Modal>
    </>
  );
};

export default DeliveryNoteTable;
