import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Dropdown, Tag, Modal } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewAction from "./view";
import ConfirmSubInvoiceForm from "../../../../../components/forms/ConfirmSubInvoiceForm";
import MomoPaymentForm from "../../../../../components/forms/MomoPaymentForm";

import { myInvoices } from "../../../../../store/invoice";
import { useDispatch, useSelector } from "react-redux";

const ThreeInvoiceDotDropdown = (props: any) => {
  const { auth } = useSelector((state: any) => state);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);
const [openMomoConfirm, setOpenMomoConfirm] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState("");
  const invoice = useSelector((state: any) => state.invoice);
  const dispatch = useDispatch();

  const handleOpenMomoConfirmModel = () => {
    setOpenMomoConfirm(true);
  };

  const handleMomoCancel = () => {
    setOpenMomoConfirm(false);
  };

  const handleOpenConfirmModel = () => {
    if (props?.record?.status === "paid") {
      setUpdateAmount(true);
    } else {
      setUpdateAmount(false);
    }
    setOpenConfirm(true);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
    setUpdateAmount(false);
  };

  useEffect(() => {
    if (invoice?.paymentInitiationStatus) {
      setOpenMomoConfirm(false);
      invoice?.selectedForPayment &&
        dispatch(myInvoices.setIsPaymentModalOpen(true));
    } else {
      dispatch(myInvoices.setIsPaymentModalOpen(false));
    }
  }, [dispatch, invoice?.paymentInitiationStatus, invoice?.selectedForPayment]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <ViewAction onOpenViewModel={props?.onOpenViewModel} />,
    },
    {
      key: "2",
      label: (
        <>
          {props?.status !== "paid" && (
            <Tag
              color="#87d068"
              style={{
                fontSize: "14px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                width: "auto",
                minWidth: "100%",
                textAlign: "center",
                cursor: auth?.user?.role?.includes('sales-supervisor') ? 'not-allowed' : 'pointer',
                pointerEvents: auth?.user?.role?.includes('sales-supervisor') ? 'none' : 'auto',
              }}
              onClick={handleOpenConfirmModel}
            >
              {props?.record?.status === "paid" ? "Update" : "Confirm Payment"}
            </Tag>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          {props?.status !== "paid" && (
            <Tag
              color="#87d068"
              style={{
                fontSize: "14px",
                height: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                width: "auto",
                minWidth: "100%",
                textAlign: "center",
                cursor: auth?.user?.role?.includes('sales-supervisor') ? 'not-allowed' : 'pointer',
                pointerEvents: auth?.user?.role?.includes('sales-supervisor') ? 'none' : 'auto',
              }}
              onClick={async () => {
                if (props?.record?.status?.toLowerCase() !== "paid" &&
                  !auth?.user?.role?.includes('sales-supervisor')) {
                  setDataToDisplay(props?.record);
                  dispatch(myInvoices.setSelectedForPayment(props?.record));
                  handleOpenMomoConfirmModel();
                }
              }}
            >
              {"Momo Pay"}
            </Tag>
          )}
        </>
      ),
      disabled:
        props?.record?.status === "paid" ||
        auth?.user?.role?.includes('sales-supervisor'),
    },
  ];

  return (
    <>
      <>
        <Dropdown menu={{ items }} placement="bottom">
          <BsThreeDotsVertical size={20} />
        </Dropdown>
      </>
      <Modal
        title="Confirm Payment"
        centered
        open={openConfirm}
        onCancel={handleCancel}
        width={400}
        footer={null}
      >
        <ConfirmSubInvoiceForm
          onCancel={handleCancel}
          itemId={props?.record?.data?._id}
          amount={updateAmount ? props?.record?.data : null}
          updateAmount={updateAmount}
        />
      </Modal>

      <Modal
        // title={"Pay with MoMo"}
        open={openMomoConfirm}
        // onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className={`${"w-[50vh]"}`}>
            {
              <MomoPaymentForm
                // auth={auth}
                data={dataToDisplay}
                onCancel={handleMomoCancel}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ThreeInvoiceDotDropdown;
