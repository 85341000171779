import { notification } from "antd";
import { myInvoices } from ".";
import {
  getAccountIncoiceService,
  approvePaymentInvoice,
  getOneInvoiceService,
  payWithMomo,
  checkMomoPaymentStatusService,
  AdjustAccountBilling,
  getAllSubsTransactionService,
  deleteOneSubTransaction,
} from "./services";
import { myLayoutActions } from "../layout";

export const getAllAccountInvoiceAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await getAccountIncoiceService(token, query);
      if (res?.status === 200) {
        dispatch(myInvoices.setAll(res));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myInvoices.setIsFetching(false));
    }
  };
};

export const getAllSubsTransactionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await getAllSubsTransactionService(token, query);
      if (res?.status === 200) {
        dispatch(myInvoices.setTransactions(res));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myInvoices.setIsFetching(false));
    }
  };
};

export const approveInvoicePaymentAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await approvePaymentInvoice(itemId, token, data);
      if (res?.status === 200) {
        dispatch(myInvoices.setSelected(res?.data));

        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneSubsBillingsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await getOneInvoiceService(id, token);
      if (res?.status === 200) {
        dispatch(myInvoices.setSelected(res?.data));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const payWithMoMoAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await payWithMomo(token, data);
      if (res?.status === 200) {
        dispatch(myInvoices.setRepaymentReferenceId(res?.data?.referenceId));
        dispatch(myInvoices.setIsFetching(false));
        dispatch(myInvoices.setPaymentInitiationStatus(true));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetPaymentRelatedStates = () => {
  return async (dispatch: any) => {
    dispatch(myInvoices.resetPaymentRelatedStatesService());
  };
};

export const checkRepaymentStatusAction = (token: any, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      dispatch(myLayoutActions.setIsRequestDone(true));
      const res = await checkMomoPaymentStatusService(token, query);
      if ([200, 400]?.includes(res?.status)) {
        dispatch(myInvoices.setPaymentStatusResult(res));
        dispatch(myLayoutActions.setIsRequestDone(false));
      } else if (res?.status === 204) {
        dispatch(myInvoices.setPaymentStatusResult(res));
        dispatch(myLayoutActions.setIsRequestDone(false));
      } else if (res?.data?.status === "fail") {
        notification.error({ message: "Request has been cancelled" });
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message:
          "There was error while checking out the Invoice. Try again later",
      });
    }
  };
};

export const AdjustAccountBillingAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await AdjustAccountBilling(token, data);
      if (res?.status === 200) {
        dispatch(myInvoices.setAdjustBilling(res));
        dispatch(myInvoices.setIsFetching(false));
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteOneSubTransactionAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myInvoices.setIsFetching(true));
      const res = await deleteOneSubTransaction(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myInvoices.setDeleteSubTransaction(res?.data));
        dispatch(myInvoices.setIsFetching(false));
        notification.success({ message: "Transaction deleted succesfully" });
        return true;
      }
      dispatch(myInvoices.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
