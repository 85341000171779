import React from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from "@mui/material";
import { Typography } from "antd";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryNote from "./historynoteMenu";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import AddNotes from "../../../../components/Modals/AddNotes";

const App = (props: any) => {
  const { accountId } = useParams();
  return (
    <div className="bg-[#F4F6FA] text-[#030229]">
      <div className="pt-5 flex justify-end">
        <AddNotes sourceId={accountId ? accountId : ""} />
      </div>
      <ScrollableFrame hidePagination>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="w-38 shadow-none p-4"
          >
            <Typography className="text-lg font-medium px-3 opacity-90">History Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-2 ">
              <HistoryNote
                limit={props.limit}
                setPage={props.setPage}
                setLimit={props.setLimit}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </ScrollableFrame>
    </div>
  );
};
export default App;
