import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RecentSearchCard from "./recentSearchCard";
import ProductHistoryDetails from "./ProductHistoryDetails";
import InventoryItemSearch from "../POS/InventoryItemSearch";
import { productHistoryInformation } from "../../../store/layout/actions";
import { getWareHouseItemCartDataAction } from "../../../store/wareHouse/actions";
import { myCustomerActions } from "../../../store/customer";

const ProductHistory = (props: any) => {
  const { auth, inventory } = useSelector((state: any) => state);
  const [viewProductDetail, setProductDetails] = useState(false);
  const dispatch = useDispatch();

  const productId = inventory?.searchBySn?.data[0]?._id;
  useEffect(() => {
    auth?.token &&
      productId &&
      getWareHouseItemCartDataAction(
        auth?.token,
        `?warehouseProduct=${productId}`
      )(dispatch);
    dispatch(myCustomerActions.setSelected(null));
  }, [auth.token, dispatch]);

  const handleViewHistory = () => {
    if (inventory?.searchBySn?.data?.at(0)?.salesStatus !== "b2b") {
      auth?.token &&
        productId &&
        getWareHouseItemCartDataAction(
          auth?.token,
          `?warehouseProduct=${productId}`
        )(dispatch);
      productHistoryInformation(true, inventory?.searchBySn?.data)(dispatch);
      setProductDetails(true);
    }
  };

  return (
    <>
      {!viewProductDetail ? (
        <div className="mt-10 h-[8s8vh] text-black overflow-y-auto scrollbar-hide">
          <InventoryItemSearch />
          <div className="pl-8">
            {/* <h1 className="text=[#030229] font-medium text-base py-4">Recent Searches</h1> */}
            <div className="flex gap-5 mt-5 w-[100%] md:[500px]">
              {inventory?.searchBySn?.data?.map((d: any) => {
                return (
                  <RecentSearchCard onClick={handleViewHistory} data={d} />
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <ProductHistoryDetails
          viewProductDetail={viewProductDetail}
          setProductDetails={setProductDetails}
        />
      )}
    </>
  );
};

export default ProductHistory;
