import React, { useState } from "react";
import CustomerDevicesTable from "../../../../components/tables/CustomerDevicesTable";
import { Input } from "antd";
import { useSelector } from "react-redux";

const CustomerDevices = () => {
  const { customer } = useSelector((state: any) => state);
  const { Search } = Input;
  const onSearch = (value: any, _e: any, info: any) =>
    console.log(info?.source, value);

  return (
    <div>
      <div className="flex gap-5 h-8 float-right">
        <Search
          placeholder="Search Device By IMEI"
          onSearch={onSearch}
          style={{ width: "250px" }}
        />
      </div>
      <CustomerDevicesTable data={customer?.customerDevices?.data} />
    </div>
  );
};
export default CustomerDevices;
