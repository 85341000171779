import { useState } from "react";
import UploadComponent from "../../../../components/forms/Upload";
import { useSelector } from "react-redux";
import { Image } from "antd";
const File = () => {
  const [pictures, setPictures] = useState([]);
  const { auth, customer } = useSelector(
    (state: any) => state
  );

  return (
    <>
      <div className="bg-white p-5 py-10 w-full flex flex-wrap gap-9 justify-start">
        <div className="flex justify-around border-[1px] border-[#03022910] p-3 rounded-lg lg:w-[35%] w-full h-full">
          <div>
            <p className="mb-2 opacity-80 text-[14px]">National ID</p>
            <Image
              width={200}
              height={200}
              className="w-full h-full object-cover"
              src={
                customer?.selected?.subscriptionInfo?.nationalIDDoc ||
                customer?.selected?.subscriptionInfo?.nationalIDDoc
              }
            />
          </div>
          <div>
            <p className="mb-2 opacity-80 text-[14px]">Picture</p>
            <Image
              width={200}
              height={200}
              className="w-full h-full object-cover"
              src={
                customer?.selected?.subscriptionInfo
                  ?.photoCustomerHoldingID ||
                customer?.selected?.subscriptionInfo
                  ?.photoCustomerHoldingID
              }
            />
          </div>
        </div>
        <div className="w-1/4 m-asuto">
          <div className="ml-20 mb-5">

            <UploadComponent
              className="bg-blue-500"
              setPictures={setPictures}
              limit={1}
            />
          </div>
          <p className='text-[#030229] text-base'>Choose a file or drag & drop here</p>
          <span className="text-[#03022980] text-[12px]">JPEG,PNG,PDG, and MP4 formats up to 50MB</span>
        </div>
      </div>
    </>
  );
};
export default File;
