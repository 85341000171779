import React, { useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ForceLogo from "../../assets/icons/dashboard/Samphone_Logo.svg";
import { ReactComponent as SamphoneLogo } from "../../assets/icons/samphoneLogo.svg";
import { listManages } from "../../assets/data/pages";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import MuiAccordionSummary, {
  AccordionSummaryProps,
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import "./sideNav.css";
import {
  getActiveMenu,
  storeActiveMenu,
  storeSelectedCaption,
} from "../../utils/helper";
import * as faIcons from "react-icons/fa";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Tooltip } from "antd";

const SideNav: React.FC<{
  isOpen: boolean;
  selected?: number;
  toggleSideNav: () => void;
}> = ({ isOpen, toggleSideNav, selected }) => {
  const { auth } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideBar, SetSideBar] = useState(false);
  const showSideBar = () => {
    SetSideBar(!sideBar);
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveMenu());
  const [openDropDownIndex, setOpenDropDownIndex] = useState(getActiveMenu());
  const smallScreen = useMediaQuery("(max-width:900px)");
  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const listWithoutSettings = listManages.filter(
    (item) => item.title !== "Settings"
  );
  const settingsItem = listManages.find((item) => item.title === "Settings");
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.7rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    }
  }));
  return (
    <div>
      <div
        className={`nav-container fixed flex w-fit flex-col ${sideBar ? "min-h-[32]" : "min-h-screen"
          }   items-center z-50 bg-white`}
      >
        {smallScreen && (
          <faIcons.FaBars
            className="menu-bar mx-5 mt-5 text-[#030229]"
            onClick={() => {
              if (!isOpen) {
                toggleSideNav();
              }
              showSideBar();
            }}
            size={24}
          />
        )}
        <div
          className={`  ${sideBar && smallScreen ? "nav-menu" : "nav-menu active"
            } ${isOpen ? "w-52" : "w-20"
            } bg-[#FFFFFF] h-screen  pt-8 transition-all ease-in-out duration-700  shadow-[0px_4px_8px_0px_rgba(0,0,0,0.1)]`}
          onMouseEnter={() => !isOpen && toggleSideNav()}
          onMouseLeave={() => isOpen && toggleSideNav()}
        >
          <div className={`flex justify-center items-center -mt-[1.09rem] `}>
            {isOpen ? (
              <img
                src={ForceLogo}
                className="cursor-pointer w-32  mt-2"
                alt=""
              />
            ) : (
              <div className="w-full flex justify-center items-center">
                <SamphoneLogo className="w-1/2 h-auto" />
              </div>
            )}
          </div>
          <div className="flex flex-col h-full">
            <ul className="mxs-6 flex-1 px-6 mt-8 overflow-ys-auto hide-scrollbar w-full h-[85vh]">
              {listWithoutSettings.map((manage: any) => {
                return (
                  (manage?.disabled ||
                    manage?.roles?.includes(
                      auth?.user?.role?.toLowerCase()
                    )) && (
                    <li
                      key={manage.id}
                      onClick={() => {
                        setSelectedTabIndex(manage.id);
                        storeActiveMenu(manage.id);
                        if (!manage.dropDown) {
                          navigate(manage.path);
                          showSideBar();
                        }
                      }}
                      className={`flex text-[#0F0F47B2]  rounded-md cursor-pointer hover:bg-[#b2b1f5] hover:bg-opacity-5 text-sm items-center  
                ${!isOpen ? "ml-[-10px]" : "ml-[-1px]"}`}
                    >
                      <span>
                        {!manage.dropDown ? (
                          <span className="flex flex-row ml-4 my-3">
                            <span
                              className={`text-lg pt-1 pr-4  ${selectedTabIndex === manage.id
                                ? "activeIcon"
                                : "#0F0F47"
                                }`}
                            >
                              <Tooltip
                                title={!isOpen && manage.title}
                                placement="right"
                                color={"#cecece"}
                              >
                                {manage.icon}
                              </Tooltip>
                            </span>
                            <Typography
                              className={` typography ${!isOpen && "hidden"
                                } origin-left duration-200 text-sm  font-[400] ${selectedTabIndex === manage.id
                                  ? "text-[#605BFF]"
                                  : "text-[#030229]"
                                }`}
                            >
                              {" "}
                              {manage.title}
                            </Typography>
                          </span>
                        ) : (
                          <Accordion
                            sx={{
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              color: "[#030229]",
                              fontSize: "0.875rem",
                            }}
                            expanded={
                              openDropDownIndex === manage?.id &&
                              manage?.dropDown
                            }
                            onChange={() => {
                              storeActiveMenu(manage.id);
                              setOpenDropDownIndex(
                                openDropDownIndex === getActiveMenu()
                                  ? -1
                                  : getActiveMenu()
                              );
                            }}
                          >
                            <AccordionSummary
                              expandIcon={
                                manage.dropDown && isOpen ? (
                                  <ArrowForwardIosSharpIcon
                                    sx={{
                                      color: `${selectedTabIndex === manage.id
                                        ? "#605BFF"
                                        : ""
                                        } ${!manage.dropDown ? "" : ""}`,
                                      fontSize: '.7rem'
                                    }}
                                  />
                                ) : null
                              }
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              onClick={
                                !isOpen ? () => toggleSideNav() : undefined
                              }
                            >
                              <span
                                className={`text-lg pt-1 pr-4 ${selectedTabIndex === manage.id
                                  ? "activeIcon"
                                  : "#0F0F47"
                                  }`}
                              >
                                <Tooltip
                                  title={!isOpen && manage.title}
                                  placement="right"
                                  color={"#cecece"}
                                >
                                  {manage.icon}
                                </Tooltip>
                              </span>
                              <Typography
                                className={` typography ${!isOpen && "hidden"
                                  } origin-left duration-200 text-sm font-[400] ${selectedTabIndex === manage.id
                                    ? "text-[#605BFF]"
                                    : "null"
                                  }`}
                              >
                                {" "}
                                {manage.title}
                              </Typography>
                            </AccordionSummary>
                            {isOpen && manage.dropDown ? (
                              <>
                                <AccordionDetails>
                                  <Typography className="flex">
                                    <hr className="ml-[.3rem] mr-3 h-[auto] cursor-pointer  duration-500" />
                                    <ul>
                                      {manage?.dropDown?.map((drop: any) => (
                                        <>
                                          {!drop?.skip?.includes(
                                            auth?.user?.role?.toLowerCase()
                                          ) && (
                                              <li
                                                key={drop.index}
                                                className={`py-2 test-xs cursor-pointer ${localStorage.getItem(
                                                  "selectedCaption"
                                                ) === drop.index
                                                  ? "bg-[#605BFF] bg-opacity-5 rounded-md pl-1 pr-2"
                                                  : ""
                                                  }`}
                                                onClick={() => {
                                                  const selectedCaption =
                                                    drop.index;
                                                  storeSelectedCaption(
                                                    selectedCaption
                                                  );
                                                  navigate(drop.path);
                                                  showSideBar();
                                                }}
                                              >
                                                {drop.caption}
                                              </li>
                                            )}
                                        </>
                                      ))}
                                    </ul>
                                  </Typography>
                                </AccordionDetails>
                              </>
                            ) : (
                              ""
                            )}
                          </Accordion>
                        )}
                      </span>
                    </li>
                  )
                );
              })}
            </ul>
            <div className={`pb-8 text-gray-900 ${isOpen && ""}`}>
              <ul className="ml-10 mt-1">
                {settingsItem &&
                  settingsItem?.roles?.includes(
                    auth?.user?.role?.toLowerCase()
                  ) && (
                    <li
                      key={settingsItem.id}
                      onClick={() => {
                        setSelectedTabIndex(settingsItem.id);
                        storeActiveMenu(settingsItem.id);
                        if (!settingsItem.dropDown) {
                          navigate(settingsItem.path);
                          showSideBar();
                        }
                      }}
                      className={`flex text-[#0F0F47B2] rounded-md cursor-pointer hover:bg-[rgba(255,255,255,0.17)] text-sm items-center ${!isOpen ? "ml-[-10px]" : "ml-[-1px]"
                        }`}
                    >
                      <span className="flex flex-row my-3">
                        <span
                          className={`text-lg pt-1 pr-4  ${selectedTabIndex === settingsItem.id
                            ? "activeIcon"
                            : "#0F0F47"
                            }`}
                        >
                          <Tooltip
                            title={!isOpen && settingsItem.title}
                            placement="right"
                            color={"#cecece"}
                          >
                            {settingsItem.icon}
                          </Tooltip>
                        </span>
                        <Typography
                          className={` typography ${!isOpen && "hidden"
                            } origin-left duration-200 text-sm pt-[1px]  font-[400] ${selectedTabIndex === settingsItem.id
                              ? "text-[#2943D6]"
                              : "null"
                            }`}
                        >
                          {" "}
                          {settingsItem.title}
                        </Typography>
                      </span>
                    </li>
                  )}
              </ul>
            </div>
          </div>
          {["partner"]?.includes(auth?.user?.role) && (
            <div
              className={`absolute left-2 bottom-4 text-gray-900 ${isOpen && "pl-16"
                }`}
              onClick={() => dispatch(authActions.logout())}
            >
              <ListItemIcon
                className={`SideNav flex flex-col items-center text-xs `}
              >
                <PowerSettingsNewIcon className="SamIcon" />
                {"Logout"}
              </ListItemIcon>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default SideNav;
