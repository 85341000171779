import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCasesAction } from "../../../store/account/actions";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AppTable from "../../../components/tables/AppTable";
import { PiEyeThin } from "react-icons/pi";
import { Dropdown, MenuProps, Tag } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ReactComponent as DeleteSvg } from "../../../assets/icons/deleteicon.svg";
import { useNavigate } from "react-router-dom";

const SupportPage = () => {
  const { auth, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Updated On",
      dataIndex: "updated",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
    },
    {
      title: "Case Id",
      dataIndex: "caseId",
    },
    {
      title: "Case Category",
      dataIndex: "caseCategory",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      render: (_text: any, record: any) => {
        return (
          <span className="capitalize">
            <p className="text-[12px]">
              {record?.assignee?.map((el: any) => el?.names)?.join(" , ")}
            </p>
            <p className="text-[11px] italic text-[#605BFF]">
              {record?.assignee?.map((el: any) => el?.email)?.join(" , ")}
            </p>
          </span>
        );
      },
    },
    {
      title: "Reporter",
      dataIndex: "reporter",
      render: (text: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "status",
      dataIndex: "status",
      render: (text: any) => {
        const color =
          text === "In-Progress"
            ? "#3930AB"
            : text === "Cancelled"
              ? "#DE2C2F"
              : text === "Escalated"
                ? "#F19D00"
                : text === "Solved"
                  ? "#12B76A"
                  : "blue";

        return (
          <Tag
            className=" items-center rounded-[2rem] px-6 py-1 "
            color={color}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "data",
      render: (record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                // onClick={() => setViewOne(true)}
                className="flex gap-3 text-[.83rem]"
              >
                <PiEyeThin size={20} /> View
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                // onClick={(e) => !isDisabled && openDeleteModel(record?._id)}
                className="flex gap-3 text-[.83rem]"
              >
                <DeleteSvg /> Delete
              </label>
            ),
          },
        ];
        return (
          <div>
            <Dropdown menu={{ items }} placement="bottom">
              <BsThreeDotsVertical size={20} />
            </Dropdown>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    auth?.token && getAllCasesAction(auth?.token, "")(dispatch);
  }, [dispatch, auth?.token]);
  const casesData = account?.cases?.data?.map((el: any) => {
    return {
      key: el?._id,
      assignee: el?.assignedTo?.user?.slice(0, 2)?.map((user: any) => user),
      updated: el?.updatedAt?.slice(0, 10),
      createdAt: el?.createdAt?.slice(0, 10),
      caseCategory: el?.categories,
      department: el?.department,
      status: el?.status,
      reporter: el?.createdBy?.names,
      caseId: el?.ticketID,
      data: el,
    };
  });

  return (
    <div className="text-[#030229] pt-4 w-full min-h-screen">
      <DetailsHeaderActionBar pageName="Support" title=" " />
      <div className="w-full overflow-x-auto my-8">
        <h1 className="mx-2">ALL CASES</h1>
        <AppTable
          onRow={(record: any, _rowIndex: any) => {
            return {
              onClick: (event: any) => {
                const isDisabledColumn = event.target.closest(
                  ".disable-navigation"
                );
                const isDisabledlabel =
                  event.target.closest(".ant-dropdown-menu");
                if (!isDisabledColumn && !isDisabledlabel) {
                  navigate(`/support/case/${record?.key}`);
                }
              },
            };
          }}
          columns={columns}
          dataSource={casesData}
          loading={account?.isFetching}
        />
      </div>
    </div>
  );
};
export default SupportPage;
