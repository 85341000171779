import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExportCSVFile from "./ExportCSVFile";

const CsvDownloadButton: React.FC<{
  csvHeaders: any;
  csvData: any;
  filename: any;
}> = ({ csvHeaders, csvData, filename }) => {
  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <ExportCSVFile
  //         csvHeaders={csvHeaders}
  //         csvData={csvData || []}
  //         filename={filename}
  //       />
  //     ),
  //   },
  // ];

  return (
    <div className="cursor-pointer" onClick={() => {}}>
      <ExportCSVFile csvHeaders={csvHeaders} csvData={csvData || []} filename={filename} />
    </div>
  );
};
export default CsvDownloadButton;
