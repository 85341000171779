import React from "react";
import { Table, TableProps } from "antd";

interface ReusableTableProps<T> extends TableProps<T> {
  columns: TableProps<T>["columns"];
  dataSource: T[];
}

const AppTable = <T extends object>({
  columns,
  dataSource,
  ...restProps
}: ReusableTableProps<T>) => {
  return <Table<T> columns={columns} dataSource={dataSource} {...restProps} />;
};

export default AppTable;
