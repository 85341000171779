import { Form, Input } from "antd";
import React from "react";

interface inputTextProps {
  name: string;
  label?: any;
  width?: any;
  height?: any;
  required?: boolean;
  requiredMessage?: string;
  textColor?: string;
  textSize?: string;
  placeholder?: string;
  style?: any;
}
const InputText = ({
  name,
  label,
  width,
  height,
  required = false,
  requiredMessage,
  textColor = "[#030229CC]",
  textSize = "sm",
  placeholder,
  style,
}: inputTextProps) => {
  return (
    <div>
      <Form.Item
        name={name}
        label={<p className={`text-${textSize} text-${textColor}`}>{label}</p>}
        rules={[
          {
            required: required,
            message: requiredMessage,
            whitespace: true,
          },
        ]}
      >
        <Input
          className={`w-${width} h-${height}`}
          placeholder={placeholder}
          style={style}
        />
      </Form.Item>
    </div>
  );
};
export default InputText;
