import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import store from "store";
import { useSelector } from "react-redux";

export const supportDropDownRoutes = [
  {
    caption: "Overview",
    index: "supportOverview",
    path: "/support/overview",
    onClick: () => {
      console.log("Overview:00");
    },
  },
  {
    caption: "Case",
    index: "case",
    path: "/support/case",
  },
];

const SupportRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");
  let authent = { token: true };

  const allowedRoles = [
    "admin",
    "technician",
    "dev",
    "sales-manager",
    "sales-supervisor",
    "b2b-relation",
    "finance",
    "finance-manager",
    "inventory",
  ];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/support/overview",
      onClick: () => {
        console.log("Overview:00");
      },
    },
    {
      caption: "Case",
      path: "/support/case",
      onClick: () => {
        console.log("sale:00");
      },
    },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Support".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        selectedNav={5}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default SupportRoutes;
