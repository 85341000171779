import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllSuppliersByBrandAction } from "../../store/supplier/actions";
import CsvDownloadButton from "../buttons/CsvDownloadButton";
import { ProductInfoSuppliersHeaders } from "../csvHeaders/Headers";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const SupplierByBrandTable = (props: any) => {
  const { auth, product, supplier } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const { productId } = useParams();
  const [formData, setformData] = React.useState(dataMapper);
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token &&
      getAllSuppliersByBrandAction(
        auth?.token,
        `?brand=${product?.selected?.brand}`
      )(dispatch);
  }, [auth?.token, dispatch, productId]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
  ];

  const value = supplier?.suppliersByBrand?.data;
  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          type: el?.type,
          name: el?.name,
          telephone: el?.phone,
          email: el?.email,
          location: el?.address,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [supplier?.suppliersByBrand]);

  const csvData = formData?.map((record: any) => ({
    model: product?.selected?.model,
    supplierName: record?.name,
    type: record?.type,
    telephone: record?.telephone,
    email: record?.email,
    location: record?.location,
    tin: record?.data?.tin ?? "N/A",
  }));


 

  return (
    <>
      <div className="hidden md:block absolute right-2 -top-14">
        <CsvDownloadButton
          csvHeaders={ProductInfoSuppliersHeaders}
          csvData={csvData}
          filename={`${product?.selected?.model} Suppliers.csv`}
        />
      </div>
      <Table
        columns={columns}
        dataSource={formData}
        loading={supplier?.isFetching}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default SupplierByBrandTable;
