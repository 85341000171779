import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { useSelector } from "react-redux";
import store from "store";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const financeDropDownRoutes = [
  {
    caption: "Overview",
    index: "FinanceOverview",
    path: "/finance",
  },
  {
    caption: "Delivery Note",
    index: "Delivery Note",
    path: "/finance/delivery-note",
  },
  {
    caption: "Purchase Order",
    index: "Purchase Order",
    path: "/finance/po",
  },

  {
    caption: "Transactions",
    index: "Transactions",
    path: "/finance/transactions",
  },
  {
    caption: "No Sales",
    index: "No Sales",
    path: "/finance/no-sales",
  },
  {
    caption: "Purchased",
    index: "Purchased",
    path: "/finance/assets",
  },
  {
    caption: "Commission",
    index: "Commission",
    path: "/finance/commission",
  },
  {
    caption: "Discount Mangement",
    index: "Discount Mangement",
    path: "/finance/discount",
    skip: ["finance", "finance-manager"],
  },
];

const FinanceRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");

  const allowedRoles = ["admin", "finance-manager", "dev"];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/finance",
      onClick: () => {
        console.log("finance:overview");
      },
    },
    {
      caption: "Orders",
      onClick: () => {
        console.log("orders:00");
      },
      sub: [
        {
          caption: "Delivery Note",
          path: "/finance/delivery-note",
          onClick: () => {
            console.log("Overview:00");
          },
          bread: [
            {
              href: "/finance",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Orders</span>
                </>
              ),
            },
            { title: "RFQ" },
          ],
        },
        {
          caption: "Purchase Order",
          path: "/finance/po",
          onClick: () => {
            console.log("Overview:00");
          },
        },
      ],
    },
    {
      caption: "Transactions",
      path: "/finance/transactions",
    },
    {
      caption: "No Sales",
      path: "/finance/no-sales",
    },
    // {
    //   caption: "EBM",
    //   onClick: () => {
    //     console.log("Ebm:00");
    //   },
    //   sub: [
    //     {
    //       caption: "Manage Items",
    //       path: "/finance/ebm/items",
    //       onClick: () => {
    //         console.log("Overview:00");
    //       },
    //     },
    //     {
    //       caption: "Manage Purchases",
    //       path: "/finance/ebm/purchases",
    //       onClick: () => {
    //         console.log("Overview:00");
    //       },
    //     },
    //     {
    //       caption: "Manage Sales",
    //       // path: "/finance/ebm/sales",
    //       onClick: () => {
    //         console.log("Overview:00");
    //       },
    //     },
    //     {
    //       caption: "Manage Stock",
    //       // path: "/finance/ebm/stock",
    //       onClick: () => {
    //         console.log("Overview:00");
    //       },
    //     },
    //   ],
    // },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Finance".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        navSelected
        selectedNav={8}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default FinanceRoutes;
