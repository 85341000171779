import React from "react";
import type { MenuProps } from "antd";
import { Dropdown, Tag, Space } from "antd";
import { BsThreeDots } from "react-icons/bs";

import { SlEye } from "react-icons/sl";
import { useSelector } from "react-redux";

const ThreeSubDotDropdown = (props: any) => {
  const { auth } = useSelector((state: any) => state);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Tag
          style={{
            fontSize: "15px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            width: "auto",
            minWidth: "100%",
            textAlign: "center",
          }}
          onClick={() => props?.onOpenViewModel()}
          className=" p-2"
        >
          <Space>
            <SlEye size={16} color="#2943D6" spacing={5} />
            <p style={{ fontSize: "14px" }}>View</p>
          </Space>
        </Tag>
      ),
    },
    {
      key: "2",
      label: (
        <>
          {props?.data?.status === "accepted" &&
            props?.data?.subscriptionStatus === "ACCOUNT_REQUEST" &&
            props?.data?.isAcceptedContract &&
            ["dev"]?.includes(auth?.user?.role) && (
              <Tag
                color="#87d068"
                style={{
                  fontSize: "14px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  width: "auto",
                  minWidth: "100%",
                  textAlign: "center",
                }}
                onClick={() => props?.onOpenConfrimPaymentModel()}
              >
                Confirm Payment
              </Tag>
            )}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="rounded-md border flex items-center justify-center py-1 px-4">
        <Dropdown menu={{ items }} placement="bottom">
          <BsThreeDots size={20} />
        </Dropdown>
      </div>
    </>
  );
};
export default ThreeSubDotDropdown;
