import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack, Grid, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber, notification } from "antd";
import AddIcon from "@mui/icons-material/Add";
import PriceListFilter from "../../../../components/filters/PriceListFiltering";
import { getOneProductAction } from "../../../../store/product/actions";
import { myProductActions } from "../../../../store/product";
import {
  addPriceListItemAction,
  getPricelistItemAction,
} from "../../../../store/channel/actions";
import { getActivePrice } from "../../../../utils/converter";

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedModelId, setSelectedModelId] = useState("");

  const selectedItemToUpdate = channel?.priceListItemToUpdate?.data;
  const initialValues = {
    product: selectedItemToUpdate?._id,
  };

  const onFinish = async (values: any) => {
    const val = {
      ...values,
      prices: [{ value: values.prices }],
      channel: channel?.selected?._id,
      specification: [
        ["Type", product?.selected?.type],
        ["Brand", product?.selected?.brand],
        ...Object.entries(values.specification),
      ],
    };

    if (!selectedItemToUpdate) {
      if (auth?.token && channel?.priceList?.data[0]?._id) {
        await addPriceListItemAction(
          auth?.token,
          channel?.priceList?.data[0]?._id,
          val,
          `?channel=${channel?.selected?._id}`
        )(dispatch);
        props?.onCancel();
        await getPricelistItemAction(
          auth?.token,
          `?type=pos${
            channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
          }`
        )(dispatch);
      } else {
        notification.error({ message: "Failed To Add!" });
      }
    }
    form.resetFields();
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    if (auth?.token && selectedModelId) {
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
      getPricelistItemAction(
        auth?.token,
        `?product=${selectedModelId}${
          channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""
        }`
      )(dispatch);
    }
  }, [auth?.token, dispatch, selectedModelId]);

  return (
    <Stack spacing={2}>
      <PriceListFilter />
      <Paper elevation={0} sx={{ padding: 2 }}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={initialValues}
          style={{ width: "100%" }}
          scrollToFirstError
        >
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              <Form.Item
                name="product"
                label="Product Model"
                tooltip="Please select model of the product!"
                rules={[
                  {
                    required: true,
                    message: "Model is required!",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  style={{ width: "100%" }}
                  placeholder="Select By Model"
                  options={product?.all.data.map((pro: any) => ({
                    label: pro?.model,
                    value: pro._id,
                  }))}
                  onChange={(value: any) => {
                    !value && setSelectedModelId("");
                    value && setSelectedModelId(value);
                    value && props.setSelectedModelId(value);
                  }}
                />
              </Form.Item>

              {/* Specifications */}
              {product?.selected &&
                product?.selected?.specs.map(
                  (spec: any) =>
                    spec.value.length > 0 &&
                    !spec?.label?.toLowerCase().includes("color") && (
                      <Form.Item
                        key={spec?.label}
                        name={["specification", spec?.label]}
                      >
                        <Select
                          showSearch
                          allowClear
                          loading={product.isFetching}
                          disabled={product.isFetching}
                          style={{ width: "100%" }}
                          placeholder={`Select ${spec?.label}`}
                          options={spec?.value?.map((pro: any) => ({
                            label: pro,
                            value: pro,
                          }))}
                        />
                      </Form.Item>
                    )
                )}
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              <Form.Item
                name="prices"
                label="Price"
                tooltip="Please enter the price of the product you selected!"
                initialValue={getActivePrice(selectedItemToUpdate?.prices)}
                rules={[
                  {
                    required: true,
                    message: "Price is required!",
                  },
                ]}
              >
                <InputNumber min={1} style={{ width: "100%" }} />
              </Form.Item>

              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Stack>
  );
};

export default PageView;

