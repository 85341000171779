import * as React from "react";
import ProfileButton from "./ProfileButton";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import ButtonSearch from "../buttons/buttonSearch";
import * as aiIcons from "react-icons/ai";
import * as bsIcons from "react-icons/bs";
import DisabledTabs from "../../pages/dashboard/subscription/billings/components/FilterButton";
import { Input } from "antd";
import { Dropdown } from "antd";

const Component: React.FC<{
  nav: any;
  title: any;
  handleFullScreenClick: any;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}> = ({ nav, title, handleFullScreenClick, isOpen, setIsOpen }) => {
  const { layout } = useSelector((state: any) => state);
  const location = useLocation();
  const route = location.pathname;
  const text = route.includes("channel");

  const smallScreen = useMediaQuery("(max-width:1023px)");
  const verySmallScreen = useMediaQuery("(max-width:639px)");
  const extraSmallScreen = useMediaQuery("(max-width:330px)");
  const [searchBtn, setSearchBtn] = React.useState(false);
  const [profileComponent, setProfileComponent] = React.useState(false);
  const showSearchBtn = () => {
    setSearchBtn(!searchBtn);
  };
  const showProfileComponent = () => {
    if (verySmallScreen) {
      setProfileComponent(!profileComponent);
    } else {
      setProfileComponent(false);
    }
  };
  const { Search } = Input;
  const items = [
    {
      key: "1",
      label: <ProfileButton />,
    },
  ];

  return (
    <div className="fixed top-0 w-full z-10">
      <div
        className={`relative w-full px-8 flex items-center justify-center min-h-16 sm:h-13 gap-1 bg-[#FFFFFF] border-b-[.8px] border-[#E4E4E7] text-[#030229] ${layout.isSideNavOpen && !smallScreen ? "pl-[220px]" : "pl-[75px]"
          } ${verySmallScreen && "py-2"} ${!verySmallScreen && smallScreen && "py-1 "
          } }
        `}
      >
        <div
          className={`flex justify-between items-center space-x-0 z-50 flex-1 dpr-10 ${extraSmallScreen && " pr-0"
            }`}
        >
          {useMediaQuery("(max-width:500px)") && searchBtn ? (
            ""
          ) : (
            <p
              className={`text-[#09090B] font-normal text-[18px] capitalize pl-4 ${smallScreen && "pr-6 pl-2"
                } ${extraSmallScreen && "pl-0"} ${smallScreen &&
                layout.isSideNavOpen &&
                !verySmallScreen &&
                "pl-40"
                }`}
            >
              {route?.split("/")[1]}
            </p>
          )}

          {text ? (
            nav
          ) : route === "/subscription/invoice/" ||
            route === "/subscription/invoice/billings" ? (
            <DisabledTabs />
          ) : smallScreen ? (
            !searchBtn &&
            (extraSmallScreen ? (
              <aiIcons.AiOutlineSearch size={18} onClick={showSearchBtn} />
            ) : (
              <aiIcons.AiOutlineSearch size={24} onClick={showSearchBtn} />
            ))
          ) : (
            <ButtonSearch />
          )}
          {verySmallScreen && !searchBtn && !profileComponent ? (
            <>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
              >
                <bsIcons.BsThreeDots />
              </Dropdown>
            </>
          ) : (
            !searchBtn && (
              <>
                <ProfileButton handleFullScreenClick={handleFullScreenClick} />
                {verySmallScreen && (
                  <aiIcons.AiOutlineClose
                    onClick={showProfileComponent}
                    className="float-right"
                  />
                )}{" "}
              </>
            )
          )}
          {searchBtn && smallScreen && (
            <>
              <Search
                placeholder="input search text"
                enterButton
                className=""
              />{" "}
              <div className=" pl-4 ">
                <aiIcons.AiOutlineClose onClick={showSearchBtn} size={20} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Component;
