import * as React from "react";
import { Radio } from "antd";
import { myLayoutActions } from "../../../../../store/layout";
import { useDispatch, useSelector } from "react-redux";

export default function DisabledTabs() {
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  return (
    <Radio.Group
      defaultValue={layout?.accountFilterType}
      buttonStyle="solid"
      onChange={(e: any) =>
        dispatch(myLayoutActions.setAccountFilterType(e?.target?.value))
      }
      size="large"
    >
      <Radio.Button value={""}>All</Radio.Button>
      <Radio.Button value={"b2b"}>B2B Accounts</Radio.Button>
      <Radio.Button value={"b2c"}>B2C Accounts</Radio.Button>
    </Radio.Group>
  );
}
