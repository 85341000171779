import { Button, Tag } from "antd";
import React from "react";
import { searchValue } from "../../utils/setColor";
interface DataItem {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

interface DataTpe {
  key: number;
  date: string;
  model: string;
  specs: string;
  imei: string;
  week: number;
  amountPaid: string;
  balance: string;
}

export const columns = [
  {
    key: "type",
    title: "Type",
    dataIndex: "type",
  },
  {
    key: "brand",
    title: "Brand",
    dataIndex: "brand",
  },
  {
    key: "model",
    title: "Model",
    dataIndex: "model",
  },
  {
    key: "specs",
    title: "Specs",
    dataIndex: "specs",
  },
  {
    key: "quantity",
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    key: "qtyScanned",
    title: "QTYScanned",
    dataIndex: "qtyScanned",
    defaultVal: 0,
  },
  {
    key: "action",
    title: "Action",
    dataIndex: "action",
    render: (text: any, record: any) => (
      <Button className="text-[#1107601a] bg-[#F4F6FA]">Scan</Button>
    ),
  },
];

export const Data = [
  {
    key: "type",
    title: "Type",
    dataIndex: "type",
  },
  {
    key: "brand",
    title: "Brand",
    dataIndex: "brand",
  },
  {
    key: "model",
    title: "Model",
    dataIndex: "model",
  },
  {
    key: "phone",
    title: "Description",
    dataIndex: "phone",
  },
  {
    key: "website",
    title: "Price/product",
    dataIndex: "website",
  },
  {
    key: "quantity",
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    key: "action",
    title: "Action",
    dataIndex: "action",
  },
];

export const Mycolumns = [
  {
    title: "Model",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Specifications",
    dataIndex: "specs",
    key: "specs",
    witdth: "50px",
  },
  {
    title: `Amount (${"RWF"})`,
    dataIndex: "amountPaid",
    key: "amountPaid",
  },
];

export const Mydata: DataTpe[] = [
  {
    key: 1,
    date: "20-08-2023",
    model: "iPhone",
    specs: "ROM: 128RAM",
    imei: "3518910100",
    week: 3,
    amountPaid: "100,000",
    balance: "600,000",
  },
];

export const MyPaymentcolumns = [
  {
    title: "Payment Mode",
    dataIndex: "mode",
    key: "mode",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: any, _record: any) => (
      <Tag color={searchValue(text)} className="capitalize">
        {text}
      </Tag>
    ),
  },
];
