import {
  Stack,
  Box,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FaChevronDown } from "react-icons/fa";
import { Dropdown } from "antd";

const TopNav = (props: any) => {
  const navigate = useNavigate();
  const storedIndex = localStorage.getItem("channel_index");
  const channelTabCurrentIndex = storedIndex ? JSON.parse(storedIndex) : 0;

  const [menuList, setMenuList] = useState([""]);
  const [selectedNav, setSelectedNav] = useState(0);
  const smallScreen = useMediaQuery("(max-width:1023px)");
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [selectedCaption, setSelectedCaption] = useState("OverView");

  useEffect(() => {
    if (channelTabCurrentIndex) setSelectedNav(channelTabCurrentIndex);
  }, [channelTabCurrentIndex]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const Items = props?.pages.map((item: any, index: any) => {
    return (
      <div className="bg-white p-3 shadow-md">
        <p
          key={index}
          onClick={() => {
            localStorage.setItem("channel_index", JSON.stringify(index));
            setSelectedNav(index);
            setSelectedCaption(item?.caption);
            navigate(item?.path);
          }}
          className={`Capitalize text-base ${
            selectedNav === index ? "text-black" : "text-[#89899c]"
          }`}
        >
          {item.caption}
        </p>
      </div>
    );
  });

  return (
    <>
      <Box style={{ width: "75%" }}>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          {smallScreen && props?.pages?.length !== 0 ? (
            <Dropdown
              overlay={<div>{Items}</div>}
              className="flex gap-2 py-1 rounded-sm shadow-mda items-center content-center"
            >
              <span className=" flex justify-center items-center">
                <h1 className="text-[12px]">{selectedCaption}</h1>{" "}
                <FaChevronDown className="mt-1" size={12} />
              </span>
            </Dropdown>
          ) : (
            <Box sx={{ display: { md: "flex" } }} className="capitalize">
              {props?.pages.map((page: any, index: number) => {
                if (page?.skip) return null;
                return (
                  <Button
                    key={index}
                    sx={{
                      color: selectedNav === index ? "black" : "#89899c",
                      minWidth: 100,
                    }}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setSelectedNav(index);
                      localStorage.setItem(
                        "channel_index",
                        JSON.stringify(index)
                      );
                      navigate(page?.path);
                      page?.sub && handleToggle();
                      page?.sub && setMenuList(page?.sub);
                    }}
                    ref={page?.sub && anchorRef}
                    endIcon={page?.sub && <KeyboardArrowDownIcon />}
                  >
                    {page?.caption}
                  </Button>
                );
              })}
            </Box>
          )}
        </Stack>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            // style={{
            //   transformOrigin:
            //     placement === "bottom-start" ? "left top" : "left bottom",
            // }}
          >
            <Paper sx={{ zIndex: 90, background: "white" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuList.map((m: any) => {
                    if (m?.skip === true) return null;
                    return (
                      <MenuItem
                        onClick={(event: Event | React.SyntheticEvent) => {
                          // dispatch(authActions.setPathUrl(m.bread)); // @ben, what does this do? 🙄
                          handleClose(event);
                          navigate(m?.path);
                        }}
                      >
                        {m.caption}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TopNav;
