import React, { useEffect, useState } from "react";
import InputSelect from "../inputs/InputSelect";
import { Form, Select } from "antd";
import CustomButton from "../buttons/CustomButton";
import RichTextEditor from "../inputs/RichTextEditor";
import PickDate from "../inputs/PickDate";
import InputText from "../inputs/InputText";
import {
  createCaseAction,
  createNoteAction,
  getAllCasesAction,
} from "../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAction } from "../../store/setting/actions";
import { getAllCustomersAction } from "../../store/customer/actions";
import { customerIncludeItem } from "../../assets/data/includedItem";
import { myCustomerActions } from "../../store/customer";
import { useLocation } from "react-router-dom";

const CreateCaseForm = (props: any) => {
  const { auth, account, setting, customer, layout } = useSelector(
    (state: any) => state
  );
  const [textValue, setTextValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDueDate, setSelectedDueDate] = useState<string>("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const route = location.pathname;
  const path = route.includes("/support/case/");

  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        customer?.query || `?field=${customerIncludeItem}`
      )(dispatch);
    dispatch(myCustomerActions.setSelected(null));
  }, [auth?.token, customer.new, customer?.query, customer.updated, dispatch]);

  useEffect(() => {
    auth?.token && getAllUsersAction(auth?.token, "")(dispatch);
  }, [dispatch, auth?.token]);

  const usersOption = setting?.all?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.names,
  }));
  const customersOption = customer?.all?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
  }));

  const onFinish = async (values: any) => {
    const response = await createNoteAction(auth?.token, {
      ...values,
      description: textValue,
      modelId: props?.sourceId,
    })(dispatch);
    if (response?.type && !props?.dataToUpdate) {
      await createCaseAction(auth?.token, {
        ...values,
        noteId: response?.noteId,
        isReminderOn: true,
        reminder: [{ scheduledTime: selectedDate }],
        status: "In-Progress",
        assignedTo: {
          user: values.user,
        },
      })(dispatch);
      (await auth?.token) && getAllCasesAction(auth?.token, "")(dispatch);
    }
    form.resetFields();
    props?.closeCase();
  };

  useEffect(() => {
    form.setFieldsValue({
      user: props?.dataToUpdate?.assignedTo?.user?.map((el: any) => el?.names),
      categories: props?.dataToUpdate?.categories,
      subcategories: props?.dataToUpdate?.subcategories,
      priority: props?.dataToUpdate?.priority,
    });
  }, [
    form,
    props?.dataToUpdate?.assignedTo?.user,
    props?.dataToUpdate?.categories,
    props?.dataToUpdate?.priority,
    props?.dataToUpdate?.subcategories,
  ]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={props?.dataToUpdate || {}}
      style={{ maxWidth: "100%" }}
      layout="horizontal"
    >
      <div className="lg:flex gap-5 text-[#030229] min-h-[85vh]">
        {!path && (
          <div className="bg-white rounded-md px-10 py-8 w-full lg:w-[50%] 2xl:w-[68%] shadow-[4px_1px_52px_0px_rgba(15,15,71,0.1)] p-3">
            <h1 className="uppercase mb-8 text-[16px] opacity-90 font-medium">
              New Case
            </h1>
            <div className="w-[100%] mx-auto px-4">
              <div className="flex flex-col">
                <InputText
                  name="title"
                  label={
                    <p className="font-[450] text-sm text-[#030229] mr-[15px]">
                      Add title
                    </p>
                  }
                  width={`${layout?.isSideNavOpen ? "[75%]" : "[68%]"}`}
                  height={10}
                  required={true}
                  requiredMessage="Title is required"
                />
                <div className="flex flex-wrap gap-4">
                  <p className="font-[450] text-sm text-[#030229]">
                    Add Notes :
                  </p>
                  <RichTextEditor
                    label="Add Notes : "
                    value={textValue}
                    setValue={setTextValue}
                    style={{
                      height: "250px",
                      width: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </div>

                <div className="mt-8">
                  <InputSelect
                    style={{
                      height: 38,
                      width: layout?.isSideNavOpen ? "75%" : "68%",
                    }}
                    name="department"
                    selectOption={[
                      {
                        value: "Operations",
                        label: "Operations",
                      },
                      {
                        value: "Finance",
                        label: "Finance",
                      },
                      {
                        value: "Sales",
                        label: "Sales",
                      },
                      {
                        value: "Dev",
                        label: "Dev",
                      },
                    ]}
                    label={
                      <p className="font-[450] text-sm text-[#030229]">
                        Department
                      </p>
                    }
                    placeholder="Select department"
                  />
                </div>
              </div>
              <div className=" flex gap-5 w-[78%] flex-wrap justify-end mt-8">
                <Form.Item>
                  <CustomButton
                    btnName={
                      <h1 className="px-6 py-0.6 text-[#605BFF] font-normal text-[13px] uppercase">
                        Discard
                      </h1>
                    }
                    borderColor={"[#605BFF]"}
                  />
                </Form.Item>
                <Form.Item>
                  <CustomButton
                    btnName={
                      <h1 className="px-6 py-0.6 text-white font-normal text-[13px] uppercase">
                        Save Case
                      </h1>
                    }
                    loading={account?.isFetching}
                    btnBgColor={"[#605BFF]"}
                    loadingColor={"white"}
                    textColor={"white"}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        )}
        <div
          className={`bg-white rounded-md p-10 w-full  mt-5 lg:mt-0  ${
            !path ? "2xl:w-[35%] lg:w-[45%]" : "w-full"
          } shadow-[4px_1px_52px_0px_rgba(15,15,71,0.1)] p-3`}
        >
          <div className="mt-10 divide-y-[1px] px-6">
            <Form.Item
              name="user"
              label={
                <p className={`font-[450] text-sm text-[#030229]`}>Assignee</p>
              }
            >
              <Select
                mode="multiple"
                placeholder="select assignee"
                style={{ flex: 1 }}
                options={usersOption}
              />
            </Form.Item>
            <InputSelect
              className={"pt-3"}
              style={{ height: 35, fontSize: "10px" }}
              name="customer"
              selectOption={customersOption}
              label={
                <p className="font-[450] text-sm text-[#030229]">Customer</p>
              }
              placeholder="select customer"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="categories"
              selectOption={[
                { value: "Technical Issues", label: "Technical Issues" },
                {
                  value: " Financial Issues",
                  label: " Financial Issues",
                },
                {
                  value: "Product/Service Issues",
                  label: "Product/Service Issues",
                },
                {
                  value: "Customer Support Requests",
                  label: "Customer Support Requests",
                },
                {
                  value: "Legal & Compliance Issues",
                  label: "Legal & Compliance Issues",
                },
              ]}
              label={
                <p className="font-[450] text-sm text-[#030229]">Category</p>
              }
              placeholder="select category"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="subcategories"
              selectOption={[
                { value: "Software Issue", label: "Software Issue" },
                {
                  value: "Hardware Malfunction",
                  label: "Hardware Malfunction",
                },
                {
                  value: "Installation/Setup Problem",
                  label: "Installation/Setup Problem",
                },
                {
                  value: "Network/Connectivity Issue",
                  label: "Network/Connectivity Issue",
                },
                { value: "Performance Problem", label: "Performance Problem" },
                { value: "Payment Failure", label: "Payment Failure" },
                { value: "Refund Request", label: "Refund Request" },
                { value: "Subscription Issue", label: "Subscription Issue" },
                { value: "Defective Product", label: "Defective Product" },
                { value: "Quality Concern", label: "Quality Concern" },
                { value: "General Inquiry", label: "General Inquiry" },
                {
                  value: "Terms and Services Violation",
                  label: "Terms and Services Violation",
                },
              ]}
              label={
                <p className="font-[450] text-sm text-[#030229]">
                  Sub-Category
                </p>
              }
              placeholder="select subcategory"
            />
            <InputSelect
              className={"pt-5"}
              style={{ height: 35 }}
              name="priority"
              selectOption={[
                {
                  value: "High",
                  label: "high",
                },
                {
                  value: "Medium",
                  label: "medium",
                },
                {
                  value: "Low",
                  label: "low",
                },
              ]}
              label={
                <p className="font-[450] text-sm text-[#030229]">Priority</p>
              }
              placeholder="select priority"
            />
            <PickDate
              label={
                <p className="font-[450] text-sm text-[#030229]">
                  Set Reminder Date
                </p>
              }
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              className="pt-5"
              textSize="14px"
              style={{ width: "100%", height: 38 }}
            />
            <PickDate
              label={
                <p className="font-[450] text-sm text-[#030229]">
                  Set Due Date
                </p>
              }
              selectedDate={selectedDueDate}
              setSelectedDate={setSelectedDueDate}
              className="pt-5"
              textSize="16px"
              style={{ width: "100%", height: 38 }}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};
export default CreateCaseForm;
