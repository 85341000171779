import React from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import NotesCard from "./components/NotesCard";
import "../../../../components/layout/sideNav.css";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
const HistoryNote = (props: any) => {
  const { account } = useSelector((state: any) => state);

  return (
    <>
      {account?.note?.data?.length === 0 ? (
        <Typography>
          <p>currently there is no notes, use above field to add note</p>
        </Typography>
      ) : (
        <ScrollableFrame
          setPage={props.setPage} // Pass the setPage function to ScrollableFrame
          setLimit={props.setLimit} // Pass the setLimit function to ScrollableFrame
          limit={props.limit} // Pass the current limit
          total={account?.note?.total} // Pass the total number of notes
          count={Math.ceil(account?.note?.total / props.limit)} // Calculate the number of pages
        >
          <NotesCard />
        </ScrollableFrame>
      )}
    </>
  );
};
export default HistoryNote;
