import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Checkbox, Divider, Form, Input, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import {
  addNewDiscountAction,
  getAllModelsAction,
  updateDiscountAction,
} from "../../store/discount/actions";
import { searchFuction } from "../../utils/converter";

const { TextArea } = Input;
const { Option } = Select;
const shopIncludeItem = ["-channel", "name", "-createdBy"];

const DiscountForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth, discount } = useSelector((state: any) => state);
  const [isAcceptAll, setIsAcceptAll] = useState(true);
  const [shopFilter, setShopFilter] = useState([]);

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      getAllShopAction(
        auth?.token,
        `limit=1000&field=${shopIncludeItem}`
      )(dispatch);
    }
  }, [auth.token, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      getAllModelsAction(auth?.token, `?`)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const onFinish = async (values: any) => {
    const payload = {
      isAllowedToAll: isAcceptAll,
      ...values,
    };
    if (props?.dataToUpdate !== null) {
      const res = await updateDiscountAction(
        props?.dataToUpdate?.key,
        auth?.token,
        payload,
        `?`
      )(dispatch);
      if (res) {
        form.resetFields();
        props?.onCancel();
      }
    } else {
      const res = await addNewDiscountAction(
        auth?.token,
        payload,
        `?`
      )(dispatch);
      if (res) {
        form.resetFields();
        props?.onCancel();
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...props?.dataToUpdate,
      description: props?.dataToUpdate?.data?.description,
      productModel: props?.dataToUpdate?.data?.productModel,
      shop: props?.dataToUpdate?.data?.shop?.map((el: any) => el?._id),
    });
    setIsAcceptAll(props?.dataToUpdate?.data?.isAllowedToAll);
  }, [props?.dataToUpdate]);

  useEffect(() => {
    if (props?.dataToUpdate === null) {
      form.resetFields();
    }
  }, [props?.dataToUpdate]);

  const handleAcceptAllShop = (e: any) => {
    setIsAcceptAll(e.target?.checked);
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      style={{ width: '100%' }}
      scrollToFirstError
      layout="vertical"
    >
      <h1 className="font-semibold text-sm mb-5 space-x-2">
        <span>Applicable to all shops</span>
        <Checkbox onChange={handleAcceptAllShop} checked={isAcceptAll} />
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Name is required!", whitespace: true },
          ]}
        >
          <Input className="h-10 w-full" />
        </Form.Item>
        <Form.Item
          name="percentage"
          label="Percentage"
          rules={[
            {
              required: props?.dataToUpdate === null ? true : false,
              message: "Percentage is required!",
            },
          ]}
        >
          <Input type="number" className="h-10 w-full" />
        </Form.Item>
        {!isAcceptAll && (
          <Form.Item
            name="shop"
            label="Shop"
            rules={[
              {
                required: true,
                message: "Shop is required!",
              },
            ]}
          >
            <Select
              allowClear
              className="capitalize min-h-10"
              mode="multiple"
              size={"middle"}
              placeholder="Please select"
              style={{ width: "100%" }}
              onSearch={(value: any) =>
                searchFuction(
                  value,
                  channel?.allShop?.data,
                  (option: any, val) =>
                    option?.name?.toLowerCase().includes(val.toLowerCase()),
                  setShopFilter
                )
              }
              filterOption={false}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
            >
              {shopFilter.length > 0
                ? shopFilter?.map((el: any) => {
                  return (
                    <Option
                      key={el._id}
                      value={el?._id}
                      className="capitalize"
                    >
                      {el?.name}
                    </Option>
                  );
                })
                : channel?.allShop?.data?.map((el: any) => {
                  return (
                    <Option
                      key={el._id}
                      value={el?._id}
                      className="capitalize"
                    >
                      {el?.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="productModel" label="Models">
          <Select
            allowClear
            className="capitalize min-h-10"
            mode="multiple"
            size={"middle"}
            placeholder="Please select"
            style={{ width: "100%" }}
          >
            {/* <Option
                className="capitalize"
              >
                All Models
              </Option> */}
            {discount?.models?.data?.map((d: any, index: any) => (
              <Option
                value={d?.model?.toLowerCase()}
                key={index}
                className="capitalize"
              >
                {d?.model}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Divider />
      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Description is required!",
            whitespace: true,
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "20%" }}
          loading={discount?.isFetching}
        >
          {props?.data ? "Update" : "save"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default DiscountForm;
