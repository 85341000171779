import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction } from "../../../../store/channel/actions";
import { Select } from "antd";

const { Option } = Select;
const ChannelFilter = (props: any) => {
  const { dashboard, channel, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <>
      <Select
        size="large"
        style={{ width: 200 }}
        allowClear
        placeholder="Select Channel"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label ?? "").toString().includes(input)
        }
        loading={dashboard.isFetching}
        disabled={dashboard.isFetching}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")

            .toString()
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toString().toLowerCase())
        }
        onChange={(value: any) => {
          !value && props?.setSelectedChannel("");
          value && props?.setSelectedChannel(value);
        }}
      >
        {channel?.all?.data?.map((d: any, index: any) => (
          <Option Key={index} value={d?._id} className="capitalize">
            {d?.name}
          </Option>
        ))}
      </Select>
    </>
  );
};
export default ChannelFilter;
