import { Modal, Spin, Switch, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Card from "./card";
import totslIcon from "../../../assets/icons/dashboard/channel/box-tick.svg";
import profileIcon from "../../../assets/icons/dashboard/channel/profile-2user.svg";
import CustomChart from "./customChart";
import { MdOutlineFileDownload } from "react-icons/md";
import DateRangeSelector from "./dateRangePicke";
import { useDispatch, useSelector } from "react-redux";
import NewShopForm from "../../../components/forms/NewShopForm";
import {
  deleteCommussionProfileActions,
  deleteShopAction,
  getCommissionProfilelAction,
  getShopOverViewAction,
  updateShopAction,
} from "../../../store/channel/actions";
import CommussionForm from "../../../components/forms/commussionForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import DetailsModel from "../../../components/Modals/DetailsModel";
import PayCommissionForm from "../../../components/forms/PayCommissionForm";
import CustomSkeleton from "../../../components/skeleton/CustomSkeleton";
import { getDefaultCurrencyCode } from "../../../utils/helper";
const ShopProfile = (props: any) => {
  const { channel, auth, shop, layout, company } = useSelector(
    (state: any) => state
  );
  const anableCommussion = shop?.selected?.isCommissionEnabled;
  const [data, setData] = React.useState<any>("");
  const [deleteId, setDeleteId] = React.useState("false");
  const [deleteCommId, setDeleteCommId] = React.useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isCommissinModalOpen, setIsCommissionModalOpen] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const { shopId } = useParams();
  const [isCommissionAnabled, setIsCommussionEnabled] =
    useState(anableCommussion);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { Paragraph, Text } = Typography;
  const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] =
    useState(false);
  const styles = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
  };
  const style = {
    shadow: {
      boxShadow: "0px  0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0.1px solid rgba(15, 15, 71, 0.25)",
    },
  };
  const deleteModal = (itemId: any) => {
    setDeleteId(itemId);
    setVisible(true);
  };
  const deleteCommissionModal = (itemId: any) => {
    setDeleteCommId(itemId);
    setIsCommissionModalOpen(true);
  };
  const handleDelete = async () => {
    auth?.token &&
      (await deleteShopAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
    navigate(`/channel/${channel?.selected?.name}/pos/shops`);
  };

  const handleCancels = () => {
    setVisible(false);
  };
  const handleCommCancels = () => {
    setIsCommissionModalOpen(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showCommissionModal = () => {
    setIsProfileModalOpen(true);
  };
  const showUpdateCommissionModal = () => {
    setIsUpdateProfileModalOpen(true);
  };
  const handleCancelUpdateCommissionModal = () => {
    setIsUpdateProfileModalOpen(false);
  };
  const handleCloseUpdateCommissionModal = () => {
    setIsUpdateProfileModalOpen(false);
  };
  const handleCancelCommissionModal = () => {
    setIsProfileModalOpen(false);
  };
  const handleCloseCommissionModal = () => {
    setIsProfileModalOpen(false);
  };
  const handleOnClickView = () => {
    setIsViewOpen(true);
    // setCommisssionDataDetails(data)
  };
  const handleOnCancel = () => {
    setIsViewOpen(false);
  };
  const getCommission = async () => {
    auth?.token &&
      (await getCommissionProfilelAction(
        auth?.token,
        `shop=${shopId}`
      )(dispatch));
  };

  useEffect(() => {
    getCommission();
    setIsCommussionEnabled(anableCommussion);
  }, [auth?.token, shopId, anableCommussion, dispatch]);

  useEffect(() => {
    auth?.token &&
      getShopOverViewAction(auth?.token, shopId as string)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  const onChange = async (checked: boolean) => {
    setIsCommussionEnabled(checked);
    auth?.token &&
      (await updateShopAction(
        shopId as string,
        { isCommissionEnabled: checked },
        auth?.token
      )(dispatch));
    await getCommission();
    channel?.allCommissionProfile?.total === 0 &&
      !isCommissionAnabled &&
      showCommissionModal();
  };

  const updateModelOpen = (datas: any) => {
    setIsModalOpen(true);
    setData(datas);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCommok = async () => {
    if (auth?.token) {
      await deleteCommussionProfileActions(
        channel?.allCommissionProfile?.data[0]?._id,
        auth?.token,
        `shop=${shopId}`
      )(dispatch);
    }
    auth?.token &&
      (await updateShopAction(
        shopId as string,
        { isCommissionEnabled: false },
        auth?.token
      )(dispatch));
    setIsCommissionModalOpen(false);
    setIsCommussionEnabled(false);
  };
  return (
    <>
      <div className="flex flex-col md:flex-row gap-5 pl-4">
        <div
          className="py-5 w-[100%] md:w-[546px] bg-white mt-5 rounded-md "
          style={styles}
        >
          <div className="flex px-7 justify-between">
            <h1 className="text-[#030229] text-lg font-medium">Shop Profile</h1>
            {["admin", "dev", "finance", "finance-manager"]?.includes(
              auth?.user?.role
            ) && (
                <div className="mtx-3 flex gap-3">
                  <CiEdit
                    onClick={() => updateModelOpen(shop?.selected)}
                    className="float-right cursor-pointer"
                    size={23}
                    color="#A9A9A9"
                  />
                  <MdDelete
                    onClick={() => deleteModal(shop?.selected?._id)}
                    size={23}
                    fill="#A9A9A9"
                    className="cursor-pointer"
                  />
                </div>
              )}
          </div>
          <div className="divide-y">
            <div className="flex px-7 justify-between w-[100%] mt-5 ">
              <div>
                <div>
                  <p className="text-[#03022980] text-[12px]">Name:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {shop?.selected?.name}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">Type:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {shop?.selected?.type}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">MSISDN:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {shop?.selected?.msisdn}
                  </p>
                </div>
                <div className="mt-3">
                  <Paragraph
                    copyable={{
                      text: async () =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(
                              shop?.selected?.shopLocation?.coordinate?.lng
                            );
                          }, 500);
                        }),
                    }}
                  >
                    Longitude
                  </Paragraph>
                </div>
              </div>
              <div>
                <div>
                  <p className="text-[#03022980] text-[12px]">Momo Code:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {shop?.selected?.momocode}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">Address:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {shop?.selected?.address}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">Region:</p>
                  <p className="text-[#030229] pt-1 text-sm medium">
                    {shop?.selected?.region}
                  </p>
                </div>
                <div className="mt-4">
                  <Paragraph
                    copyable={{
                      text: async () =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(
                              shop?.selected?.shopLocation?.coordinate?.lat
                            );
                          }, 500);
                        }),
                    }}
                  >
                    Latitude
                  </Paragraph>
                </div>
              </div>
            </div>
            {["admin", "dev", "finance", "finance-manager"]?.includes(
              auth?.user?.role
            ) && (
                <div className="flex justify-between mt-5 pt-3 px-7">
                  <h1 className="pt-1 text-[#030229] text-base font-medium">
                    Commission
                  </h1>{" "}
                  <div
                    style={style.border}
                    className="rounded-[8px] py-[2px] px-2 text-[12px]"
                  >
                    {channel?.isFetching ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 20 }} spin />
                        }
                      />
                    ) : isCommissionAnabled ? (
                      <span className="pr-2">On</span>
                    ) : (
                      <span className="pr-2">Off</span>
                    )}
                    <Switch
                      defaultChecked={isCommissionAnabled}
                      checked={isCommissionAnabled}
                      onChange={onChange}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
        {channel?.allCommissionProfile?.total != 0 && isCommissionAnabled && (
          <div
            className="py-5 w-[100%] md:w-[546px] bg-white mt-5 rounded-md"
            style={styles}
          >
            <div className="flex justify-between px-7">
              {" "}
              <h1 className="text-[#030229] text-lg font-medium">
                Commission Details
              </h1>
              {isCommissionAnabled && (
                <div className="flex gap-5">
                  <button
                    className="text-[#605BFF] text-sm"
                    onClick={props?.handleShowCommissionDevices}
                  >
                    View Devices
                  </button>
                  {layout?.prevRoute === "/finance/commission" && (
                    <button
                      onClick={handleOnClickView}
                      className="border border-[#605BFF] rounded-md px-3 py-1 text-sm"
                    >
                      Pay Now
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="w-[100%] mt-5  divide-y-2">
              <div className="px-7">
                <div>
                  <p className="text-[#03022980] text-[12px]">Shop Owner:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {channel?.isFetching
                      ? "...."
                      : channel?.allCommissionProfile?.data[0]?.accountName}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">Account no:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {channel?.isFetching
                      ? "...."
                      : channel?.allCommissionProfile?.data[0]?.account}
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-[#03022980] text-[12px]">Address:</p>
                  <p className="text-[#030229] pt-1 text-sm font-medium">
                    {channel?.isFetching
                      ? "...."
                      : channel?.allCommissionProfile?.data[0]?.address}
                  </p>
                </div>
              </div>
              <div className="mt-5">
                {["admin", "dev", "finance", "finance-manager"]?.includes(
                  auth?.user?.role
                ) && (
                    <div className="flex gap-3 pt-5 float-right px-7">
                      <CiEdit
                        onClick={() => showUpdateCommissionModal()}
                        className="float-right cursor-pointer"
                        size={23}
                        color="#A9A9A9"
                      />
                      <MdDelete
                        onClick={() =>
                          deleteCommissionModal(
                            channel?.allCommissionProfile?.data[0]?._id
                          )
                        }
                        size={23}
                        fill="#A9A9A9"
                        className="cursor-pointer"
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
      <h1 className="text-[#030229] text-lg pt-5 ml-4">OverView</h1>
      <div className="flex gap-x-4 gap-y-4 flex-wrap mt-5">
        <Card
          cardIcon={totslIcon}
          title={"Total Devices"}
          value={shop?.shopOverview?.totalDevices ?? 0}
        />
        <Card
          cardIcon={totslIcon}
          title={"Total Sold Devices"}
          value={shop?.shopOverview?.totalDevicesSold ?? 0}
        />
        <Card
          cardIcon={totslIcon}
          title={"Total Stock Devices"}
          value={shop?.shopOverview?.totalCurrentDevices}
        />
        <Card
          cardIcon={profileIcon}
          title={"Number of Agent"}
          value={shop?.shopOverview?.agents}
        />
        <Card
          cardIcon={totslIcon}
          title={"Commission Amount"}
          value={shop?.shopOverview?.commissionAmount?.toLocaleString()}
        />
      </div>
      <div className="relative xl:flex xl:flex-row-reverse gap-5 w-[100%]">
        <div className="hidden md:block mt-5">
          <DateRangeSelector />
        </div>
        <div className="mt-5 bg-white p-5 rounded-md w-[100%]">
          <div className="flex justify-between">
            <div>
              <h1 className=" text-[#03022980] text-sm">Total Revenue</h1>
              {shop?.isFetching ? (
                <CustomSkeleton />
              ) : (
                <h1 className="py-2 text-[#030229B2] font-semibold text-[20px]">
                  {getDefaultCurrencyCode(company)}{" "}
                  {shop?.shopStat?.data?.revenue?.currentRevenue?.allTotalRevenue?.toLocaleString()}{" "}
                  <span className="text-sm font-medium text-[#030229]">
                    {" "}
                    /{" "}
                    {
                      shop?.shopStat?.data?.quantities?.currentQuantities
                        ?.allTotalQuantities
                    }{" "}
                    Pieces
                  </span>
                </h1>
              )}
            </div>
            <div className="flex gap-5">
              <div className="flex gap-2">
                {" "}
                <div className="w-3 h-3 rounded-sm bg-[#605BFF] mt-1 pt-[2px]"></div>
                <p>Revenue</p>
              </div>
            </div>
            <div className="bg-[#FEE6E980] rounded-md px-3 h-6 py-1 flex gap-1">
              <MdOutlineFileDownload color="red" className="mt-[2px]" />
              <p className="text-[#DE2C2F] font-medium"> 8.0%</p>
            </div>
          </div>
          <div className="mt-7">
            <CustomChart />
          </div>
        </div>

        <DeleteModal
          visible={visible}
          onOk={handleDelete}
          isLoading={channel.isFetching}
          onCancel={handleCancels}
          itemName="Shop"
        />
        <DeleteModal
          visible={isCommissinModalOpen}
          onOk={handleCommok}
          isLoading={channel.isFetching}
          onCancel={handleCommCancels}
          itemName="Commission Details"
        />
        <Modal
          title={""}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
        >
          <div>
            <NewShopForm data={data} onCancel={handleCancel} />
          </div>
        </Modal>
        <Modal
          title="Create Commission Details"
          width={600}
          open={isProfileModalOpen}
          footer={null}
          onOk={handleCloseCommissionModal}
          onCancel={handleCancelCommissionModal}
        >
          <CommussionForm
            data={props?.data}
            onCancel={handleCancelCommissionModal}
          />
        </Modal>
        <Modal
          title="Update Commission"
          width={600}
          open={isUpdateProfileModalOpen}
          footer={null}
          onOk={handleCloseUpdateCommissionModal}
          onCancel={handleCancelUpdateCommissionModal}
        >
          <CommussionForm
            data={channel?.allCommissionProfile?.data[0]}
            onCancel={handleCancelUpdateCommissionModal}
          />
        </Modal>
        <DetailsModel
          component={<PayCommissionForm />}
          modalTitle={
            <h1 className="text-[#030229] text-base font-medium">
              Pay Commission
            </h1>
          }
          isModalOpen={isViewOpen}
          handleOnClickViewIcon={handleOnClickView}
          handleOnCancel={handleOnCancel}
        />
      </div>
    </>
  );
};

export default ShopProfile;
