import { SmileOutlined } from "@ant-design/icons";
import React from "react";

const ContactInfoCard = (props: any) => {
  return (
    <div className="border border-[#03022926] bg-white py-3 px-5 rounded-md">
      {props?.data?.subscriptionInfo?.witnessInfo?.length > 0 ? (
        <>
          {props?.data?.subscriptionInfo?.witnessInfo
            ?.slice(0, 2)
            ?.map((el: any) => {
              return (
                <div>
                  <div className="flex w-full gap-10 mt-2">
                    <div>
                      <p className="text-[#03022980]  text-sm pt-1">Name</p>
                      <p className="text-[#03022980]  text-sm pt-1">
                        Phone Number
                      </p>
                      <p className="text-[#03022980]  text-sm pt-1">
                        Relationship
                      </p>
                    </div>
                    <div>
                      <p className="text-[#030229] text-sm pt-1 capitalize">
                        {el?.name}
                      </p>
                      <p className="text-[#030229] text-sm pt-1 capitalize">
                        {el?.phone}
                      </p>
                      <p className="text-[#030229] text-sm pt-1 capitalize">
                        {el?.relationship}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <SmileOutlined style={{ fontSize: 20 }} />
          <p className="text-[#03022980]  text-sm pt-2">No Other Contact</p>
        </div>
      )}
    </div>
  );
};
export default ContactInfoCard;
