import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";
import { useSelector } from "react-redux";

const SubscriptiondeliveryNoteFilter = (props: any) => {
  const { deliverynote } = useSelector((state: any) => state);
  const { Option } = Select;

  const uniqueStatuses = new Set(
    deliverynote?.all?.data.map((data: any) => data.status)
  );

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between items-center space-x-2">
        <div className="flex justify-between items-center space-x-2">
          <Select
            size="large"
            showSearch
            style={{ width: 200, fontSize: "5px" }}
            allowClear
            placeholder="Filter By Status"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toString().includes(input)
            }
            loading={deliverynote.isFetching}
            disabled={deliverynote.isFetching}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            onChange={(value: any) => {
              props?.setSelectedStatus(value);
            }}
          >
            {Array.from(uniqueStatuses).map((status: any) => (
              <Option value={status} label={status} key={status}>
                {status}
              </Option>
            ))}
          </Select>
        </div>

        <h1 className="text-gray-400 hidden xl:block">
          Result:{" "}
          {deliverynote?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            deliverynote?.all?.total
          )}
        </h1>
      </div>
    </div>
  );
};

export default SubscriptiondeliveryNoteFilter;
